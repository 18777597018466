import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { CalendarDate, DateFormatter, getLocalTimeZone } from '@internationalized/date';
import { Calendar, Content, Dialog, DialogTrigger, Text } from '@adobe/react-spectrum';
import { FieldButton } from '@react-spectrum/button';
import ChevronDownMedium from '@spectrum-icons/ui/ChevronDownMedium';
import { useValue } from '../utils/util';
import { getYearMonth, getYearWeek } from '@baeso-ui/baeso-core';
const DAY_FORMATTER = new DateFormatter('de', { day: '2-digit' });
const MONTH_FORMATTER = new DateFormatter('de', { month: 'long' });
const DATE_FORMATTER = new DateFormatter('de', { day: '2-digit', month: 'long', year: 'numeric' });
function isYearWeek(value) {
    return value.start !== undefined;
}
function isDate(value) {
    return value.calendar !== undefined;
}
function computeText(value) {
    if (isDate(value)) {
        return DATE_FORMATTER.format(value.toDate(getLocalTimeZone()));
    }
    else if (isYearWeek(value)) {
        const start = value.start.toDate(getLocalTimeZone());
        const end = value.end.toDate(getLocalTimeZone());
        const startDay = DAY_FORMATTER.format(start);
        const startMonth = MONTH_FORMATTER.format(start);
        const startYear = value.start.year;
        const endDay = DAY_FORMATTER.format(end);
        const endMonth = MONTH_FORMATTER.format(end);
        const endYear = value.end.year;
        if (startYear !== endYear) {
            return startDay + ' ' + startMonth + ', ' + startYear + ' - ' + endDay + ' ' + endMonth + ', ' + endYear;
        }
        else if (startMonth !== endMonth) {
            return startDay + ' ' + startMonth + ' - ' + endDay + ' ' + endMonth + ', ' + endYear;
        }
        return startDay + '-' + endDay + ' ' + endMonth + ', ' + endYear;
    }
    else {
        return MONTH_FORMATTER.format(extractDate(value).toDate(getLocalTimeZone())) + ' ' + value.year;
    }
}
function extractDate(value) {
    if (isDate(value)) {
        return value;
    }
    else if (isYearWeek(value)) {
        return value.start;
    }
    else {
        return new CalendarDate(value.year, value.month, 1);
    }
}
export function SpectrumDatePeriodPicker(props) {
    const [isOpen, setOpen] = useState(false);
    const [value, setValue] = useValue(props.value);
    const date = extractDate(value);
    const text = computeText(value);
    const handleDateChange = (v) => {
        setOpen(false);
        if (props.period === 'day') {
            setValue(v);
        }
        else if (props.period === 'week') {
            setValue(getYearWeek(v));
        }
        else {
            setValue(getYearMonth(v));
        }
    };
    return _jsxs(DialogTrigger, Object.assign({ type: "popover", placement: props.placement, hideArrow: true, isOpen: isOpen, onOpenChange: setOpen }, { children: [_jsxs(FieldButton, Object.assign({ isQuiet: true }, { children: [_jsx(Text, { children: text }), _jsx(ChevronDownMedium, { UNSAFE_style: { paddingLeft: 12 } })] })), _jsx(Dialog, Object.assign({ UNSAFE_style: { width: 'auto' } }, { children: _jsx(Content, { children: _jsx(Calendar, { value: date, onChange: handleDateChange }) }) }))] }));
}

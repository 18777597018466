import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { ActionButton, AlertDialog, Button, ButtonGroup, Content, Dialog, DialogContainer, DialogTrigger, Divider, Heading, Text, useMessageFormatter } from '@adobe/react-spectrum';
import Add from '@spectrum-icons/workflow/Add';
import Delete from '@spectrum-icons/workflow/Delete';
import Edit from '@spectrum-icons/workflow/Edit';
import { SpectrumListBox as ListBox } from '../list/SpectrumListBox';
import { SpectrumFlex } from '../flex/SpectrumFlex';
import { createValue } from '../utils/util';
import { messages } from './Messages';
export function SpectrumEditList(props) {
    const [value, , valueProp] = createValue('value', undefined);
    return (_jsxs(SpectrumFlex, Object.assign({ direction: "column", rowGap: "size-125" }, props, { children: [_jsx(ListBox, { items: props.listItems, optionToItem: props.optionToItem, optionToKey: props.optionToKey, value: valueProp, minHeight: 150 }), _jsxs(SpectrumFlex, Object.assign({ columnGap: 10 }, { children: [_jsx(AddButton, { header: props.dialogTitle, dialogWidth: props.dialogWidth, itemFactory: props.itemFactory, dialogContent: props.dialogContent, onCreate: props.onCreate }), _jsx(EditButton, { header: props.dialogTitle, dialogContent: props.dialogContent, item: value, onUpdate: props.onUpdate }), _jsx(RemoveButton, { item: value, onRemove: props.onRemove })] }))] })));
}
function AddButton(props) {
    const m = useMessageFormatter(messages);
    const { header, itemFactory, dialogContent, onCreate, } = props;
    const ref = useRef(null);
    const [item, setItem] = useState();
    return _jsxs(_Fragment, { children: [_jsxs(ActionButton, Object.assign({ onPress: () => setItem(itemFactory) }, { children: [_jsx(Add, {}), _jsx(Text, { children: m('addButton') })] })), _jsx(DialogContainer, Object.assign({ onDismiss: () => setItem(undefined) }, { children: item !== undefined &&
                    _jsxs(Dialog, Object.assign({ UNSAFE_style: { minWidth: props.dialogWidth } }, { children: [_jsx(Heading, { children: header }), _jsx(Divider, {}), _jsx(Content, { children: dialogContent(item, ref) }), _jsxs(ButtonGroup, { children: [_jsx(Button, Object.assign({ variant: "negative", onPress: () => setItem(undefined) }, { children: m('tasks.cancel') })), _jsx(Button, Object.assign({ variant: "cta", onPress: () => {
                                            const api = ref.current;
                                            if (api !== null) {
                                                api.save(item);
                                            }
                                            onCreate(item);
                                            setItem(undefined);
                                        } }, { children: m('tasks.saveAndExit') }))] })] })) }))] });
}
function EditButton(props) {
    const m = useMessageFormatter(messages);
    const { header, item, dialogContent, onUpdate } = props;
    const [open, setOpen] = useState(false);
    const ref = useRef(null);
    return _jsxs(_Fragment, { children: [_jsxs(ActionButton, Object.assign({ isDisabled: props.item === undefined, onPress: () => setOpen(true) }, { children: [_jsx(Edit, {}), _jsx(Text, { children: m('editButton') })] })), _jsx(DialogContainer, Object.assign({ onDismiss: () => setOpen(false) }, { children: open && item !== undefined &&
                    _jsxs(Dialog, { children: [_jsx(Heading, { children: header }), _jsx(Divider, {}), _jsx(Content, { children: dialogContent(item, ref) }), _jsxs(ButtonGroup, { children: [_jsx(Button, Object.assign({ variant: "negative", onPress: () => setOpen(false) }, { children: m('tasks.cancel') })), _jsx(Button, Object.assign({ variant: "cta", onPress: () => {
                                            const api = ref.current;
                                            if (api !== null) {
                                                api.save(item);
                                            }
                                            onUpdate(item);
                                            setOpen(false);
                                        } }, { children: m('tasks.saveAndExit') }))] })] }) }))] });
}
function RemoveButton(props) {
    const m = useMessageFormatter(messages);
    const item = props.item;
    const primaryActionHandler = () => {
        if (item) {
            props.onRemove(item);
        }
    };
    return (_jsxs(DialogTrigger, { children: [_jsxs(ActionButton, Object.assign({ isDisabled: item === undefined }, { children: [" ", _jsx(Delete, {}), _jsx(Text, { children: m('deleteButton') })] })), _jsxs(AlertDialog, Object.assign({ variant: "warning", title: m('deleteButton.title'), primaryActionLabel: m('deleteButton.confirm'), secondaryActionLabel: m('deleteButton.cancel'), onPrimaryAction: primaryActionHandler }, { children: [" ", m('deleteButton.warning')] }))] }));
}

import { BooleanProperty, isObservable, } from '@baeso-ui/baeso-observable';
import { FormControl } from '../BaseVM';
export class TextInputVM extends FormControl {
    constructor(init) {
        super(init);
        this.editable = createProperty(init === null || init === void 0 ? void 0 : init.editable, 'editable', true);
        this.required = createProperty(init === null || init === void 0 ? void 0 : init.required, 'required', false);
    }
}
function createProperty(value, name, defaultValue) {
    if (value !== undefined) {
        if (isObservable(value)) {
            return value;
        }
        else {
            return new BooleanProperty(name, value);
        }
    }
    return new BooleanProperty(name, defaultValue);
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ListBox, Section } from '@adobe/react-spectrum';
import { Label } from '@react-spectrum/label';
import { ListVM, MultiSelectListVM } from '@baeso-ui/baeso-vm';
import { SpectrumFlex } from '../flex/SpectrumFlex';
import { useValue } from '../utils/util';
function isReadonlyObservableList(observable) {
    return observable && observable.onListChange !== undefined;
}
function isMulti(props) {
    return props && props.list !== undefined;
}
function isMultiVM(props) {
    return props && props.vm !== undefined && props.vm instanceof MultiSelectListVM;
}
function isSingleVM(props) {
    return props && props.vm !== undefined && props.vm instanceof ListVM;
}
function getItems(observable, optionToKey) {
    if (isReadonlyObservableList(observable)) {
        // TODO Need to Optimize
        return observable.arr.map(e => { return { key: optionToKey(e), domain: e }; });
    }
    else {
        return observable.value.map(e => { return { key: optionToKey(e), domain: e }; });
    }
}
export function SpectrumListBox(props) {
    let list;
    if (isSingleVM(props)) {
        list = _jsx(SpectrumSingleListBox, Object.assign({}, props));
    }
    else if (isMultiVM(props)) {
        list = _jsx(SpectrumMultiListBox, Object.assign({}, props));
    }
    else {
        list = isMulti(props) ? _jsx(SpectrumMultiListBox, Object.assign({}, props)) : _jsx(SpectrumSingleListBox, Object.assign({}, props));
    }
    if (props.label) {
        return _jsxs(SpectrumFlex, Object.assign({ direction: "column", flexGrow: 1, overflow: "hidden" }, { children: [_jsx(Label, { children: props.label }), list] }));
    }
    return list;
}
function SpectrumMultiListBox(props) {
    const [isLoading] = useValue(props.isLoading ? props.isLoading : false);
    const [selectedItems, setSelectedItems] = useValue(isMultiVM(props) ? props.vm.value : props.list);
    const [sections, setSections] = useState([]);
    const itemsProperty = isMultiVM(props) ? props.vm.items : props.items;
    const [items, setItems] = useState(() => {
        return [];
    });
    useEffect(() => {
        setItems(getItems(itemsProperty, props.optionToKey));
        if (isReadonlyObservableList(itemsProperty)) {
            return itemsProperty.onListChange(() => {
                setItems(getItems(itemsProperty, props.optionToKey));
            });
        }
        else {
            const reg = itemsProperty.onValueChange(() => {
                const itms = getItems(itemsProperty, props.optionToKey);
                setItems(itms);
            });
            return () => {
                reg();
            };
        }
    }, [itemsProperty, props.optionToKey]);
    const computer = props.sectionComputer;
    useEffect(() => {
        if (computer) {
            const m = new Map();
            const sections = [];
            items.forEach(e => {
                var _a;
                const sectionKey = computer(e.domain);
                if (m.has(sectionKey)) {
                    (_a = m.get(sectionKey)) === null || _a === void 0 ? void 0 : _a.items.push(e);
                }
                else {
                    const section = {
                        key: sectionKey,
                        items: [e]
                    };
                    sections.push(section);
                    m.set(sectionKey, section);
                }
                setSections(sections);
            });
        }
        else {
            setSections([]);
        }
    }, [items, computer]);
    const selectedKeys = selectedItems === undefined ? [] : selectedItems.map(item => props.optionToKey(item));
    const onSelectionChange = (keys) => {
        if (keys === 'all') {
            setSelectedItems(items.map(internalItem => internalItem.domain));
        }
        else {
            const selected = Array.from(keys);
            if (selected.length == 0) {
                setSelectedItems([]);
            }
            else {
                const newSelectedItems = [];
                selected.forEach(k => {
                    var _a;
                    const e = (_a = items.find(internalItem => internalItem.key == k)) === null || _a === void 0 ? void 0 : _a.domain;
                    if (e) {
                        newSelectedItems.push(e);
                    }
                });
                setSelectedItems(newSelectedItems);
            }
        }
    };
    if (isLoading) {
        return _jsx(ListBox, Object.assign({ UNSAFE_style: { minWidth: 0 }, id: "empty" }, props.listBoxProps, { isLoading: true, items: [], selectionMode: "single", flexGrow: 1 }));
    }
    else if (props.placeholder !== undefined && items.length === 0) {
        return _jsx(SpectrumFlex, Object.assign({ minHeight: 100, flexGrow: 1, alignItems: "center", alignContent: "center", margin: "auto" }, { children: props.placeholder }));
    }
    else {
        if (props.sectionComputer) {
            return _jsx(ListBox, Object.assign({ UNSAFE_style: { minWidth: 0 }, id: "box" }, props.listBoxProps, { "aria-label": props.a11yLabel, minWidth: 100, minHeight: 100, selectedKeys: selectedKeys, items: sections, selectionMode: "multiple", onSelectionChange: onSelectionChange, flexGrow: 1 }, { children: (section) => (_jsx(Section, Object.assign({ title: section.key, items: section.items }, { children: (item) => props.optionToItem(item.domain) }), section.key)) }));
        }
        else {
            return _jsx(ListBox, Object.assign({ UNSAFE_style: { minWidth: 0 }, id: "box" }, props.listBoxProps, { "aria-label": props.a11yLabel, minWidth: 100, minHeight: 100, selectedKeys: selectedKeys, items: items, selectionMode: "multiple", onSelectionChange: onSelectionChange, flexGrow: 1 }, { children: (item) => props.optionToItem(item.domain) }));
        }
    }
}
function SpectrumSingleListBox(props) {
    const itemsProperty = isSingleVM(props) ? props.vm.items : props.items;
    const loadingProperty = props.isLoading;
    const valueProperty = isSingleVM(props) ? props.vm.value : props.value;
    const [items, setItems] = useState(() => {
        return [];
    });
    const [loading, setLoading] = useState(loadingProperty ? loadingProperty.value : false);
    const [value, setValue] = useState(() => valueProperty.value);
    const [sections, setSections] = useState([]);
    useEffect(() => {
        setItems(getItems(itemsProperty, props.optionToKey));
        if (isReadonlyObservableList(itemsProperty)) {
            return itemsProperty.onListChange(() => {
                setItems(getItems(itemsProperty, props.optionToKey));
            });
        }
        else {
            const reg = itemsProperty.onValueChange(() => {
                const itms = getItems(itemsProperty, props.optionToKey);
                setItems(itms);
            });
            return () => {
                reg();
            };
        }
    }, [itemsProperty, props.optionToKey]);
    useEffect(() => {
        setValue(valueProperty.value);
        return valueProperty.onValueChange(setValue);
    }, [valueProperty, valueProperty.value]);
    useEffect(() => {
        if (loadingProperty) {
            setLoading(loadingProperty.value);
            return loadingProperty.onValueChange(setLoading);
        }
        return;
    }, [loadingProperty]);
    const computer = props.sectionComputer;
    useEffect(() => {
        if (computer) {
            const m = new Map();
            const sections = [];
            items.forEach(e => {
                var _a;
                const sectionKey = computer(e.domain);
                if (m.has(sectionKey)) {
                    (_a = m.get(sectionKey)) === null || _a === void 0 ? void 0 : _a.items.push(e);
                }
                else {
                    const section = {
                        key: sectionKey,
                        items: [e]
                    };
                    sections.push(section);
                    m.set(sectionKey, section);
                }
                setSections(sections);
            });
        }
        else {
            setSections([]);
        }
    }, [items, computer]);
    const onSelectionChange = (keys) => {
        const selected = Array.from(keys);
        if (selected.length == 0) {
            valueProperty.value = undefined;
        }
        else {
            valueProperty.value = items.map(item => item.domain).filter(item => props.optionToKey(item) === selected[0])[0];
        }
    };
    const selectedKeys = value && items.find(e => value == e.domain) ? [props.optionToKey(value)] : undefined;
    if (loading) {
        return _jsx(ListBox, Object.assign({ UNSAFE_style: { minWidth: 0 } }, props.listBoxProps, { isLoading: true, items: [], selectionMode: "single", flexGrow: 1 }), "empty");
    }
    else if (props.placeholder !== undefined && items.length === 0) {
        return _jsx(SpectrumFlex, Object.assign({ minHeight: 100, flexGrow: 1, alignItems: "center", alignContent: "center", margin: "auto" }, { children: _jsx("div", { children: props.placeholder }) }));
    }
    else {
        if (props.sectionComputer) {
            return _jsx(ListBox, Object.assign({ UNSAFE_style: { minWidth: 0 } }, props.listBoxProps, { "aria-label": props.a11yLabel, minWidth: 100, minHeight: 100, selectedKeys: selectedKeys, disallowEmptySelection: true, items: sections, selectionMode: "single", onSelectionChange: onSelectionChange, flexGrow: 1 }, { children: (section) => (_jsx(Section, Object.assign({ "aria-label": section.key, title: section.key, items: section.items }, { children: (item) => props.optionToItem(item.domain) }), section.key)) }), "box");
        }
        else {
            return _jsx(ListBox, Object.assign({ UNSAFE_style: { minWidth: 0 } }, props.listBoxProps, { "aria-label": props.a11yLabel, minWidth: 100, minHeight: 100, selectedKeys: selectedKeys, disallowEmptySelection: true, items: items, selectionMode: "single", onSelectionChange: onSelectionChange, flexGrow: 1 }, { children: (item) => props.optionToItem(item.domain) }), "box");
        }
    }
}

import { ImageFormat } from './PDFServices.service';
import { INTERCEPTED_SERVICE_SUPPORT } from '@beso-rsd/beso-rsd-core';
import { stringify } from 'query-string';
export class FileServiceREST {
    constructor(baseUrl, client) {
        this.endpoint = baseUrl + '/fileservice';
        this.client = client;
    }
    uploadFile(file) {
        return INTERCEPTED_SERVICE_SUPPORT.post('uploadFile', this.client, `${this.endpoint}/uploadFile`, { file }, {});
    }
    loadFromURL(url, documentName = undefined) {
        return INTERCEPTED_SERVICE_SUPPORT.post('loadFromURL', this.client, `${this.endpoint}/loadFromURL`, { url, documentName }, {});
    }
}
export class PDFDocumentServiceREST {
    constructor(baseUrl, client) {
        this.endpoint = baseUrl + '/pdfdocumentservice';
        this.client = client;
    }
    get(id) {
        return INTERCEPTED_SERVICE_SUPPORT.get('get', this.client, `${this.endpoint}/${id}`, {}, {});
    }
    getRasteredPage(id, pageNumber, rasterWidth, format = ImageFormat.PNG, webpQuality = 100, webpLossless = true) {
        return INTERCEPTED_SERVICE_SUPPORT.get('getRasteredPage', this.client, `${this.endpoint}/${id}/${pageNumber}/rastered-image`, { rasterWidth, format, webpQuality, webpLossless }, {}, 'blob');
    }
    getRasteredPageURL(id, pageNumber, rasterWidth, format = ImageFormat.PNG, webpQuality = 100, webpLossless = true) {
        const queryString = stringify({
            rasterWidth, format, webpQuality, webpLossless
        });
        return `${this.endpoint}/${id}/${pageNumber}/rastered-image?${queryString}`;
    }
    getPageData(documentId, pageNumber) {
        return INTERCEPTED_SERVICE_SUPPORT.get('getPageData', this.client, `${this.endpoint}/${documentId}/${pageNumber}/page-data`, {}, {});
    }
    getPageObject(documentId, pageNumber) {
        return INTERCEPTED_SERVICE_SUPPORT.get('getPageObject', this.client, `${this.endpoint}/${documentId}/${pageNumber}/page-object`, {}, {});
    }
}

export var Position;
(function (Position) {
    Position[Position["NACH"] = 0] = "NACH";
    Position[Position["VOR"] = 1] = "VOR";
    Position[Position["UNTER"] = 2] = "UNTER";
    Position[Position["VERBINDEN"] = 3] = "VERBINDEN";
    Position[Position["UNTER_VERBINDEN"] = 4] = "UNTER_VERBINDEN";
})(Position || (Position = {}));
export var AngeklagterArt;
(function (AngeklagterArt) {
    AngeklagterArt[AngeklagterArt["NATUERLICH_PERSON"] = 0] = "NATUERLICH_PERSON";
    AngeklagterArt[AngeklagterArt["KANZLEI"] = 1] = "KANZLEI";
    AngeklagterArt[AngeklagterArt["ORGANISATION"] = 2] = "ORGANISATION";
})(AngeklagterArt || (AngeklagterArt = {}));
export function extractDokumentEditorConfig(obj) {
    if (obj.pdfViewer) {
        return obj.pdfViewer;
    }
    if (obj.onlyOfficeEditorConfig) {
        return obj.onlyOfficeEditorConfig;
    }
    throw new Error('Invalid state');
}
export function isPdfViewer(obj) {
    return obj['@type'] === 'PdfViewer';
}
export function isOnlyOfficeEditorConfig(obj) {
    return obj['@type'] === 'OnlyOfficeEditorConfig';
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { View } from '@adobe/react-spectrum';
import { useValue } from '../utils/util';
import './loading.css';
export function SpectrumImageElement(props) {
    const [thumbLoaded, setThumbLoaded] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [visible,] = useValue(props.visible || true);
    const [loadingTimeOut, setLoadingTimeOut] = useState();
    const [cleanupHolder] = useState({});
    const { height, width, url, fillColor = 'white', loadingDelay = 0, simulateNetworkDelay = 0 } = props;
    cleanupHolder.loadingTimeOut = loadingTimeOut;
    // Make sure this is the top first effect hence it will run BEFORE the next
    useEffect(() => {
        return () => {
            if (cleanupHolder.loadingTimeOut) {
                clearTimeout(cleanupHolder.loadingTimeOut);
            }
            if (cleanupHolder.simulationTimeOut) {
                clearTimeout(cleanupHolder.simulationTimeOut);
            }
        };
    }, []);
    useEffect(() => {
        if (imageUrl !== undefined || url === undefined) {
            return () => {
                if (loadingTimeOut) {
                    clearTimeout(loadingTimeOut);
                }
            };
        }
        if (loadingDelay === 0) {
            setImageUrl(url);
            return () => {
                if (loadingTimeOut) {
                    clearTimeout(loadingTimeOut);
                }
            };
        }
        else if (loadingTimeOut === undefined) {
            if (props.visible) {
                if (visible) {
                    const loadTimeOut = setTimeout(() => {
                        setImageUrl(url);
                        setLoadingTimeOut(undefined);
                    }, loadingDelay);
                    setLoadingTimeOut(loadTimeOut);
                }
            }
            else {
                const t = setTimeout(() => { setImageUrl(url); }, loadingDelay);
                return () => clearTimeout(t);
            }
        }
        else if (loadingTimeOut !== undefined) {
            // If we get hidden while we are waiting for the load cancel the timer, 
            // we'll get rescheduled if visibility changes
            if (!visible) {
                clearTimeout(loadingTimeOut);
                setLoadingTimeOut(undefined);
            }
        }
        return () => { };
    }, [url, loadingDelay, visible, loadingTimeOut, props.visible]);
    if (url === undefined) {
        return _jsx(View, { minWidth: width, minHeight: height, maxHeight: height, maxWidth: width });
    }
    let loadingNode = props.loadingNode || _jsxs("div", Object.assign({ className: "lds-ring" }, { children: [_jsx(View, { borderTopColor: "gray-400" }), _jsx(View, { borderTopColor: "gray-400" }), _jsx(View, { borderTopColor: "gray-400" }), _jsx(View, { borderTopColor: "gray-400" })] }), "default-loading-node");
    if (thumbLoaded) {
        loadingNode = _jsx("div", {});
    }
    else if (loadingTimeOut && props.delayNode) {
        loadingNode = props.delayNode;
    }
    // TODO Think about error handling (retry???)
    return _jsxs("div", Object.assign({ style: { minHeight: height, minWidth: width, maxHeight: height, maxWidth: width, height: height, width: width, overflow: 'clip' } }, { children: [visible && _jsx("div", Object.assign({ style: { minHeight: height, minWidth: width, maxHeight: height, maxWidth: width, alignContent: 'center', alignItems: 'center', justifyContent: 'center', display: !thumbLoaded ? 'flex' : 'none' } }, { children: loadingNode }), "loading-node-container"), imageUrl && _jsx("img", { style: { minHeight: height, minWidth: width, maxHeight: height, maxWidth: width, height: height, width: width, backgroundColor: fillColor, visibility: thumbLoaded ? 'visible' : 'hidden' }, src: imageUrl, onLoad: () => {
                    if (simulateNetworkDelay > 0) {
                        cleanupHolder.simulationTimeOut = setTimeout(() => setThumbLoaded(true), simulateNetworkDelay);
                    }
                    else {
                        setThumbLoaded(true);
                    }
                } }, "rasterd-image")] }));
}

const german = {
    'home.unreadMessages': 'Ungelesene Nachrichten',
    'home.messageStatistics': 'Nachrichtenstatistik',
    'home.recentActivities': 'Zuletzt bearbeitete Vorgänge',
    'home.upcomingTasks': 'Aktuelle Aufgaben/Erinnerungen',
    'home.appointments': 'Termine dieser Woche',
    //'home.newLawsuits': 'Neue Verfahren',
    'home.newLawsuits': 'Zuletzt bearbeitete Vorgänge',
    'home.lawsuitStatistics': 'Verfahrenstatistik',
    'home.trials': 'Verhandlungen',
    'home.todo': 'ungelesen',
    'home.done': 'bearbeitet',
    'home.appointment': 'Termin',
    'home.task': 'Aufgabe',
    'home.eventAt': 'am',
};
export const messages = {
    'en-US': german,
    'de-AT': german,
    'de-DE': german,
    'de-CH': german
};

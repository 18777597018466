var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { INTERCEPTED_SERVICE_SUPPORT } from '@beso-rsd/beso-rsd-core';
export class VerfahrenViewServiceREST {
    constructor(baseUrl, client) {
        this.baseUrl = baseUrl + '/verfahrenviewservice';
        this.client = client;
    }
    getVerfahrenDetails(verfahrenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getVerfahrenDetails', this.client, this.baseUrl + '/getVerfahrenDetails', { verfahrenId }, {});
            return result;
        });
    }
}
export class VerfahrenSucheViewServiceREST {
    constructor(baseUrl, client) {
        this.baseUrl = baseUrl + '/verfahrensucheviewservice';
        this.client = client;
    }
    search(searchText) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('search', this.client, this.baseUrl + '/search', { searchText }, {});
            return result;
        });
    }
}
export class VerfahrenDashboardViewServiceREST {
    constructor(baseUrl, client) {
        this.baseUrl = baseUrl + '/verfahrendashboardviewservice';
        this.client = client;
    }
    getUnreadMessages(verfahrenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getUnreadMessages', this.client, this.baseUrl + '/getUnreadMessages', { verfahrenId }, {});
            return result;
        });
    }
    getMessageStats(verfahrenId, lastDay) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getMessageStats', this.client, this.baseUrl + '/getMessageStats', { verfahrenId, lastDay }, {});
            return result;
        });
    }
    getTimeline(verfahrenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getTimeline', this.client, this.baseUrl + '/getTimeline', { verfahrenId }, {});
            return result;
        });
    }
    getRecentActivies(verfahrenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getRecentActivies', this.client, this.baseUrl + '/getRecentActivies', { verfahrenId }, {});
            return result;
        });
    }
    getUpcomingTasks(verfahrenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getUpcomingTasks', this.client, this.baseUrl + '/getUpcomingTasks', { verfahrenId }, {});
            return result;
        });
    }
    getAppointments(verfahrenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getAppointments', this.client, this.baseUrl + '/getAppointments', { verfahrenId }, {});
            return result;
        });
    }
    getResponsiblePersons(verfahrenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getResponsiblePersons', this.client, this.baseUrl + '/getResponsiblePersons', { verfahrenId }, {});
            return result;
        });
    }
    getViewingPersons(verfahrenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getViewingPersons', this.client, this.baseUrl + '/getViewingPersons', { verfahrenId }, {});
            return result;
        });
    }
    getUnreadBeteiligte(verfahrenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getUnreadBeteiligte', this.client, this.baseUrl + '/getUnreadBeteiligte', { verfahrenId }, {});
            return result;
        });
    }
}
export class VerfahrenAnlageViewServiceREST {
    constructor(baseUrl, client) {
        this.baseUrl = baseUrl + '/verfahrenanlageviewservice';
        this.client = client;
    }
    getListData(nachrichtenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getListData', this.client, this.baseUrl + '/getListData', { nachrichtenId }, {});
            return result;
        });
    }
    findZugehoerigeVerfahren(searchCriteria) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('findZugehoerigeVerfahren', this.client, this.baseUrl + '/findZugehoerigeVerfahren', { searchCriteria }, {});
            return result;
        });
    }
    create(verfahren, nachrichtenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('create', this.client, this.baseUrl + '/create', { verfahren, nachrichtenId }, {});
            return result + '';
        });
    }
}

import axios from 'axios';
import { stringify } from 'query-string';
export class AxiosRestClient {
    get(url, parameters, headers, responseType) {
        let paramUrl = url + '?';
        let flag = false;
        for (const k in parameters) {
            if (Object.prototype.hasOwnProperty.call(parameters, k)) {
                if (parameters[k] === undefined) {
                    continue;
                }
                if (flag) {
                    paramUrl += '&';
                }
                if (typeof parameters[k] === 'number' || typeof parameters[k] === 'boolean') {
                    paramUrl += k + '=' + parameters[k];
                }
                else if (typeof parameters[k] === 'string') {
                    const o = {};
                    o[k] = parameters[k];
                    paramUrl += stringify(o);
                }
                else if (Array.isArray(parameters[k])) {
                    paramUrl += parameters[k]
                        .map((e) => {
                        const o = {};
                        o[k] = JSON.stringify(e);
                        return stringify(o);
                    })
                        .join('&');
                }
                else {
                    const o = {};
                    o[k] = JSON.stringify(parameters[k]);
                    paramUrl += stringify(o);
                }
                flag = true;
            }
        }
        const transformedHeader = {};
        for (const k in headers) {
            if (Object.prototype.hasOwnProperty.call(headers, k)) {
                if (typeof headers[k] === 'number' || typeof headers[k] === 'boolean') {
                    transformedHeader[k] = headers[k] + '';
                }
                else if (typeof headers[k] === 'string') {
                    transformedHeader[k] = headers[k];
                }
                else {
                    transformedHeader[k] = JSON.stringify(headers[k]);
                }
            }
        }
        return axios.get(paramUrl, {
            headers: transformedHeader,
            withCredentials: true,
            responseType
        }).then((response) => response.data);
    }
    post(url, parameters, headers, responseType) {
        const [data, contentType] = this.prepareFormParameters(parameters);
        const transformedHeader = {};
        for (const k in headers) {
            if (Object.prototype.hasOwnProperty.call(headers, k)) {
                if (typeof headers[k] === 'number' || typeof headers[k] === 'boolean') {
                    transformedHeader[k] = headers[k] + '';
                }
                else if (typeof headers[k] === 'string') {
                    transformedHeader[k] = headers[k];
                }
                else {
                    transformedHeader[k] = JSON.stringify(headers[k]);
                }
            }
        }
        transformedHeader['Content-Type'] = contentType;
        return axios
            .post(url, data, {
            headers: transformedHeader,
            withCredentials: true,
            responseType
        })
            .then((response) => response.data);
    }
    put(url, parameters, headers, responseType) {
        const [data, contentType] = this.prepareFormParameters(parameters);
        const transformedHeader = {};
        for (const k in headers) {
            if (Object.prototype.hasOwnProperty.call(headers, k)) {
                if (typeof headers[k] === 'number' || typeof headers[k] === 'boolean') {
                    transformedHeader[k] = headers[k] + '';
                }
                else if (typeof headers[k] === 'string') {
                    transformedHeader[k] = headers[k];
                }
                else {
                    transformedHeader[k] = JSON.stringify(headers[k]);
                }
            }
        }
        transformedHeader['Content-Type'] = contentType;
        return axios
            .put(url, data, {
            headers: transformedHeader,
            withCredentials: true,
            responseType
        })
            .then((response) => response.data);
    }
    patch(url, parameters, headers, responseType) {
        const [data, contentType] = this.prepareFormParameters(parameters);
        const transformedHeader = {};
        for (const k in headers) {
            if (Object.prototype.hasOwnProperty.call(headers, k)) {
                if (typeof headers[k] === 'number' || typeof headers[k] === 'boolean') {
                    transformedHeader[k] = headers[k] + '';
                }
                else if (typeof headers[k] === 'string') {
                    transformedHeader[k] = headers[k];
                }
                else {
                    transformedHeader[k] = JSON.stringify(headers[k]);
                }
            }
        }
        transformedHeader['Content-Type'] = contentType;
        return axios
            .patch(url, data, {
            headers: transformedHeader,
            withCredentials: true,
            responseType
        })
            .then((response) => response.data);
    }
    delete(url, parameters, headers, responseType) {
        let paramUrl = url + '?';
        let flag = false;
        for (const k in parameters) {
            if (Object.prototype.hasOwnProperty.call(parameters, k)) {
                if (flag) {
                    paramUrl += '&';
                }
                if (typeof parameters[k] === 'number' || typeof parameters[k] === 'boolean') {
                    paramUrl += k + '=' + parameters[k];
                }
                else if (typeof parameters[k] === 'string') {
                    const o = {};
                    o[k] = parameters[k];
                    paramUrl += stringify(o);
                }
                else if (Array.isArray(parameters[k])) {
                    paramUrl += parameters[k]
                        .map((e) => {
                        const o = {};
                        o[k] = JSON.stringify(e);
                        return stringify(o);
                    })
                        .join('&');
                }
                else {
                    const o = {};
                    o[k] = JSON.stringify(parameters[k]);
                    paramUrl += stringify(o);
                }
                flag = true;
            }
        }
        const transformedHeader = {};
        for (const k in headers) {
            if (Object.prototype.hasOwnProperty.call(headers, k)) {
                if (typeof headers[k] === 'number' || typeof headers[k] === 'boolean') {
                    transformedHeader[k] = headers[k] + '';
                }
                else if (typeof headers[k] === 'string') {
                    transformedHeader[k] = headers[k];
                }
                else {
                    transformedHeader[k] = JSON.stringify(headers[k]);
                }
            }
        }
        return axios.delete(paramUrl, {
            headers: transformedHeader,
            withCredentials: true,
            responseType
        }).then((response) => response.data);
    }
    prepareFormParameters(parameters) {
        const data = new FormData();
        let dataContainsFile = false;
        for (const k in parameters) {
            if (Object.prototype.hasOwnProperty.call(parameters, k)) {
                const v = parameters[k];
                if (v === undefined) {
                    continue;
                }
                if (typeof v === 'string' ||
                    typeof v === 'number' ||
                    typeof v === 'boolean') {
                    data.set(k, v + '');
                }
                else if (v instanceof File) {
                    data.set(k, v, v.name);
                    dataContainsFile = true;
                }
                else if (Array.isArray(v)) {
                    data.set(k, JSON.stringify(v.map(e => JSON.stringify(e))));
                }
                else {
                    data.set(k, JSON.stringify(v));
                }
            }
        }
        if (dataContainsFile) {
            return [data, 'multipart/form-data'];
        }
        else {
            const objectData = Object.fromEntries(data);
            return [stringify(objectData), 'application/x-www-form-urlencoded'];
        }
    }
}

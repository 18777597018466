import { createContext, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useStyleProps, viewStyleProps } from '@react-spectrum/utils';
import { useLocale } from '@react-aria/i18n';
import { useMessageFormatter } from '@adobe/react-spectrum';
import { useResizeObserver } from '@react-aria/utils';
import { DateFormats, ServiceRegistry } from '@baeso-ui/baeso-core';
import { Property, isObservable } from '@baeso-ui/baeso-observable';
import { messages } from '../Messages';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const iconRegistry = {};
export const ELEVATION_LEVELS = {
    ELEVATION_1: '0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)',
    ELEVATION_2: '0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)',
    ELEVATION_3: '0 3px 3px -2px rgba(0,0,0,.2),0 3px 4px 0 rgba(0,0,0,.14),0 1px 8px 0 rgba(0,0,0,.12)',
    ELEVATION_4: '0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12)',
    ELEVATION_5: '0 3px 5px -1px rgba(0,0,0,.2),0 5px 8px 0 rgba(0,0,0,.14),0 1px 14px 0 rgba(0,0,0,.12)',
    ELEVATION_6: '0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12)',
    ELEVATION_7: '0 4px 5px -2px rgba(0,0,0,.2),0 7px 10px 1px rgba(0,0,0,.14),0 2px 16px 1px rgba(0,0,0,.12)',
    ELEVATION_8: '0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)',
    ELEVATION_9: '0 5px 6px -3px rgba(0,0,0,.2),0 9px 12px 1px rgba(0,0,0,.14),0 3px 16px 2px rgba(0,0,0,.12)',
    ELEVATION_10: '0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12)',
    ELEVATION_11: '0 6px 7px -4px rgba(0,0,0,.2),0 11px 15px 1px rgba(0,0,0,.14),0 4px 20px 3px rgba(0,0,0,.12)',
    ELEVATION_12: '0 7px 8px -4px rgba(0,0,0,.2),0 12px 17px 2px rgba(0,0,0,.14),0 5px 22px 4px rgba(0,0,0,.12)',
    ELEVATION_13: '0 7px 8px -4px rgba(0,0,0,.2),0 13px 19px 2px rgba(0,0,0,.14),0 5px 24px 4px rgba(0,0,0,.12)',
    ELEVATION_14: '0 7px 9px -4px rgba(0,0,0,.2),0 14px 21px 2px rgba(0,0,0,.14),0 5px 26px 4px rgba(0,0,0,.12)',
    ELEVATION_15: '0 8px 9px -5px rgba(0,0,0,.2),0 15px 22px 2px rgba(0,0,0,.14),0 6px 28px 5px rgba(0,0,0,.12)',
    ELEVATION_16: '0 8px 10px -5px rgba(0,0,0,.2),0 16px 24px 2px rgba(0,0,0,.14),0 6px 30px 5px rgba(0,0,0,.12)',
    ELEVATION_17: '0 8px 11px -5px rgba(0,0,0,.2),0 17px 26px 2px rgba(0,0,0,.14),0 6px 32px 5px rgba(0,0,0,.12)',
    ELEVATION_18: '0 9px 11px -5px rgba(0,0,0,.2),0 18px 28px 2px rgba(0,0,0,.14),0 7px 34px 6px rgba(0,0,0,.12)',
    ELEVATION_19: '0 9px 12px -6px rgba(0,0,0,.2),0 19px 29px 2px rgba(0,0,0,.14),0 7px 36px 6px rgba(0,0,0,.12)',
    ELEVATION_20: '0 10px 13px -6px rgba(0,0,0,.2),0 20px 31px 3px rgba(0,0,0,.14),0 8px 38px 7px rgba(0,0,0,.12)',
    ELEVATION_21: '0 10px 13px -6px rgba(0,0,0,.2),0 21px 33px 3px rgba(0,0,0,.14),0 8px 40px 7px rgba(0,0,0,.12)',
    ELEVATION_22: '0 10px 14px -6px rgba(0,0,0,.2),0 22px 35px 3px rgba(0,0,0,.14),0 8px 42px 7px rgba(0,0,0,.12)',
    ELEVATION_23: '0 11px 14px -7px rgba(0,0,0,.2),0 23px 36px 3px rgba(0,0,0,.14),0 9px 44px 8px rgba(0,0,0,.12)',
    ELEVATION_24: '0 11px 15px -7px rgba(0,0,0,.2),0 24px 38px 3px rgba(0,0,0,.14),0 9px 46px 8px rgba(0,0,0,.12)'
};
export function isMobileDevice() {
    return window.matchMedia('(pointer:coarse)').matches;
}
export function registerIcon(key, element) {
    iconRegistry[key] = element;
}
export function findIcon(key, size, slot) {
    if (size === undefined) {
        size = 'M';
    }
    if (typeof iconRegistry[key] === 'function') {
        return iconRegistry[key](size, slot);
    }
    return iconRegistry[key];
}
export function itemToOption(item) {
    return Object.assign({}, item);
}
export function createValue(name, defaultValue) {
    const [prop] = useState(() => new Property(name, defaultValue));
    const [value, setValue] = useValue(prop);
    return [value, setValue, prop];
}
export function useValue(observable) {
    const [value, setValue] = useState(isObservable(observable) ? observable.value : observable);
    useEffect(() => {
        if (isObservable(observable)) {
            setValue(observable.value);
            return observable.onValueChange(setValue);
        }
        else {
            setValue(observable);
            return undefined;
        }
    }, [observable]);
    return [value, isObservable(observable) ? (v) => (observable.value = v) : setValue];
}
function inRange(width, range) {
    if (width >= range[0] && (range[1] === undefined || width <= range[1])) {
        return true;
    }
    return false;
}
const EMPTY = [];
export function useResponsiveClasses(domRef, classNameConfigs) {
    const [currentConfig, setCurrentConfig] = useState(EMPTY);
    const onResize = useCallback(() => {
        var _a;
        if (domRef.current === null) {
            return;
        }
        const width = domRef.current.clientWidth;
        setCurrentConfig(((_a = classNameConfigs.find(e => inRange(width, e.widthRange))) === null || _a === void 0 ? void 0 : _a.classNames) || EMPTY);
    }, [domRef, classNameConfigs]);
    useResizeObserver({
        ref: domRef,
        onResize
    });
    return currentConfig;
}
export function useTypedMessageFormatter(strings) {
    return useMessageFormatter(strings);
}
export const ServiceContext = createContext(new ServiceRegistry());
export function isActionItem(item) {
    return item.type === 'action';
}
export function isToggleItem(item) {
    return item.type === 'toggle';
}
export function isSeparatorItem(item) {
    return item.type === 'separator';
}
export function useDimensionValue(value) {
    var _a;
    return (_a = useStyleProps({ minWidth: value }, viewStyleProps).styleProps.style) === null || _a === void 0 ? void 0 : _a.minWidth;
}
export function isSpectrumGenericTableProperties(val) {
    return 'rowKeyComputer' in val;
}
function toMoment(d) {
    if (typeof d === 'string') {
        return moment(d);
    }
    return moment(d);
}
export function useDateFormatter() {
    const m = useTypedMessageFormatter(messages);
    const { locale } = useLocale();
    const fn = useCallback((date, format) => {
        switch (format) {
            case DateFormats.DATE_DAY_AND_MONTH: return toMoment(date).locale(locale).format(m('DATE_DAY_AND_MONTH'));
            case DateFormats.DATE_NORMAL: return toMoment(date).locale(locale).format(m('DATE_NORMAL'));
            case DateFormats.DATE_SHORT: return toMoment(date).locale(locale).format(m('DATE_SHORT'));
            case DateFormats.DATETIME_DAY_AND_MONTH: return toMoment(date).locale(locale).format(m('DATETIME_DAY_AND_MONTH'));
            case DateFormats.DATE_FULLMONTH_YEAR: return toMoment(date).locale(locale).format(m('DATE_FULLMONTH_YEAR'));
            case DateFormats.DATE_ISO: return toMoment(date).format('YYYY-MM-DD');
            default: return 'Unsupported format ' + format;
        }
    }, [m, locale]);
    return fn;
}
export function useDateParser() {
    const { locale } = useLocale();
    const fn = useCallback((date, format) => {
        let parsed = undefined;
        switch (format) {
            case DateFormats.DATE_NORMAL:
                parsed = moment(date, 'L', locale, true);
                break;
            case DateFormats.DATE_ISO:
                parsed = moment(date, moment.ISO_8601, true);
                break;
            default:
                console.warn('Unsupported format:', format);
                parsed = undefined;
                break;
        }
        return parsed !== undefined && parsed.isValid() ? parsed.format('YYYY-MM-DD') : undefined;
    }, [locale]);
    return fn;
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Binding, ObjectProperty, ObservableArray, Property, } from '@baeso-ui/baeso-observable';
import { DateFormats, formatDate } from '@baeso-ui/baeso-core';
export var ActivityType;
(function (ActivityType) {
    ActivityType[ActivityType["BETEILIGTE"] = 0] = "BETEILIGTE";
    ActivityType[ActivityType["VERFAHREN"] = 1] = "VERFAHREN";
    ActivityType[ActivityType["NACHRICHTEN"] = 2] = "NACHRICHTEN";
    ActivityType[ActivityType["TERMIN"] = 3] = "TERMIN";
})(ActivityType || (ActivityType = {}));
const activityItems = [
    {
        id: '1',
        beschreibung: 'Bearbeiterung der Nachricht \'Süß ./. Eberhardt Beweismittel\' von Kanzlei Just & Partner vom 28.1.2020 ',
        komponente: 'Nachrichten Eingang',
        titel: '101 C 100/20 - \'Süß ./. Eberhardt Beweismittel\' vom 28.1.2020\'',
        type: ActivityType.NACHRICHTEN,
    },
    {
        id: '2',
        beschreibung: 'Bearbeitung der Verfahrensbeteiligten',
        komponente: 'Verfahrensbeteiligte',
        titel: '100 C 62/2019 - \'Fuhr ./. Griese\'',
        type: ActivityType.BETEILIGTE,
    },
    {
        id: '3',
        beschreibung: 'Bearbeitung der Verfahrensbeteiligten',
        komponente: 'Verfahrensbeteiligte',
        titel: '100 F 2/2019 - \'Janssen ./. Wiedemann\'',
        type: ActivityType.BETEILIGTE,
    },
    {
        id: '4',
        beschreibung: 'Verknüpfung des Verfahrens mit Aktenzeichen',
        komponente: 'Verfahrensverwaltung',
        titel: '100 C 62/2019 - \'Fuhr ./. Griese\'',
        type: ActivityType.VERFAHREN,
    },
    {
        id: '5',
        beschreibung: 'Verlegung des Termins vom 23.3.2020 9:00 auf 24.3.2020 9:00',
        komponente: 'Aufgaben-/Terminverwaltung',
        titel: 'Terminverlegung - 201 F 19/2019 - \'Knoll ./. Janßen\'',
        type: ActivityType.TERMIN,
    }
];
export class HomeViewPanelVM {
    constructor(service) {
        this.unreadMessagesVM = new UnreadMessaesVM(service);
        this.messageStatsVM = new MessageStatsVM(service);
        this.recentActivitiesVM = new RecentActivitiesVM();
        this.upcomingTasksVM = new UpcomingTasksVM(service);
        this.appointmentsVM = new AppointsmentsVM();
        this.newLawsuitesVM = new NewLawsuitesVM(service);
        this.lawsuitStatsVM = new LawsuiteStatsVM();
    }
}
export class UnreadMessaesVM {
    constructor(service) {
        this.unreadMessages = new Property('unreadMessages', []);
        this.selectedMessage = new ObjectProperty('selectedMessage');
        this.service = service;
        this.loadData();
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.unreadMessages.value = yield this.service.getUngeleseneNachrichten();
            }
            catch (error) {
                console.log('Failed to load', error);
            }
        });
    }
}
export class MessageStatsVM {
    constructor(service) {
        this.dayMessageStats = new Property('unreadMessages', []);
        this.service = service;
        this.loadData();
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const stats = yield this.service.getNachrichtenStatistik(formatDate(new Date(), DateFormats.DATE_ISO));
                const msgStats = stats.tage.map(day => {
                    return {
                        tag: day.tag,
                        unbearbeitet: day.unbearbeitet,
                        ungelesen: day.ungelesen
                    };
                });
                this.dayMessageStats.value = msgStats;
            }
            catch (error) {
                console.log('Failed to load', error);
            }
        });
    }
}
export class RecentActivitiesVM {
    constructor() {
        this.recentActivities = new Property('unreadActivities', activityItems);
        this.selectedActivity = new ObjectProperty('selectedActivity');
    }
}
export class UpcomingTasksVM {
    constructor(service) {
        this.upcomingTasksList = new ObservableArray('upcomingTasks', []);
        this.upcomingTasks = this.upcomingTasksList;
        this.selectedTask = new ObjectProperty('selectedTask');
        this.service = service;
        this.loadData();
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.upcomingTasksList.setAll(yield this.service.getUpcomingTasks());
            }
            catch (error) {
                console.error('Failed to load', error);
            }
        });
    }
}
export class AppointsmentsVM {
    constructor() {
        this.termine = [
            { name: 'Mo', Verhandlungen: 3 },
            { name: 'Di', Verhandlungen: 15 },
            { name: 'Mi', Verhandlungen: 8 },
            { name: 'Do', Verhandlungen: 25 },
            { name: 'Fr', Verhandlungen: 3 }
        ];
    }
}
export class NewLawsuitesVM {
    constructor(service) {
        this.newLawsuits = new Property('newLawsuits', []);
        this.selectedLawsuite = new ObjectProperty('selectedLawsuite');
        this.service = service;
        this.openDisabled = new Binding('openDisabled', () => {
            return this.selectedLawsuite.value === undefined;
        }, this.selectedLawsuite);
        this.loadData();
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.newLawsuits.value = yield this.service.getNeueVerfahren();
            }
            catch (error) {
                console.log('Failed to load', error);
            }
        });
    }
}
export class LawsuiteStatsVM {
    constructor() {
        this.verfahren = [
            { name: 'Jan', offeneVerfahren: 112 },
            { name: 'Feb', offeneVerfahren: 87 },
            { name: 'Mär', offeneVerfahren: 56 },
            { name: 'Apr', offeneVerfahren: 23 },
            { name: 'Mai', offeneVerfahren: 84 },
            { name: 'Jun', offeneVerfahren: 96 },
            { name: 'Jul', offeneVerfahren: 65 },
            { name: 'Aug', offeneVerfahren: 66 },
            { name: 'Sep', offeneVerfahren: 38 },
            { name: 'Okt', offeneVerfahren: 52 },
            { name: 'Nov', offeneVerfahren: 30 },
            { name: 'Dez', offeneVerfahren: 33 },
        ];
    }
}

import { BooleanProperty, Property, isObservable } from '@baeso-ui/baeso-observable';
import { FormControl } from '../BaseVM';
function isArray(init) {
    return Array.isArray(init);
}
export class DropDownVM extends FormControl {
    constructor(init) {
        super(isArray(init) ? undefined : init);
        this.items = new Property('items', []);
        this.items.value = isArray(init) ? init : init.items;
        if (isArray(init)) {
            this.required = new BooleanProperty('required', false);
        }
        else {
            if (isObservable(init.required)) {
                this.required = init.required;
            }
            else {
                this.required = new BooleanProperty('required', init.required);
            }
        }
    }
}

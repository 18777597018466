import { nextItem, prevItem } from './array-utils';
/**
 * @returns true if we are running in electron
 */
export function isElectron() {
    // Renderer process
    if (typeof window !== 'undefined' &&
        typeof window.process === 'object' &&
        window.process.type === 'renderer' // eslint-disable-line
    ) {
        return true;
    }
    // Main process
    // eslint-disable-next-line
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return true;
    }
    // Detect the user agent when the `nodeIntegration` option is set to true
    if (typeof navigator === 'object' &&
        typeof navigator.userAgent === 'string' &&
        navigator.userAgent.indexOf('Electron') >= 0) {
        return true;
    }
    return false;
}
/**
 * Are we running on the local filesystem
 */
export function isLocal() {
    // We are testing running from the filesystem
    if (window.location.protocol === 'file:') {
        return true;
    }
    return false;
}
/**
 * Return a function who will call all provided functions once invoked
 * @param receivers the receivers the function calls
 */
export function multiDispatch(...receivers) {
    return (val) => receivers.forEach((r) => r(val));
}
/**
 * compare 2 numbers numerically
 * @param x the x value
 * @param y the y value
 * @returns 0 if x == y a value less than 0 if x < y and a value greater than 0 if x > y
 */
export function compare(x, y) {
    return (x < y) ? -1 : ((x == y) ? 0 : 1);
}
/**
 * Registry for services
 */
export class ServiceRegistry {
    constructor() {
        this.serviceMap = new Map(); // eslint-disable-line
    }
    /**
     * Register a service under a specific name it can be looked up later on
     * @param name the name
     * @param constructor the constructor to call
     */
    // eslint-disable-next-line
    register(name, constructor) {
        this.serviceMap.set(name, constructor);
    }
    /**
     * Create a new instance of the service with the provided name
     * @param name the name of the service
     * @throws an error if the provided service can not be found
     */
    create(name) {
        const c = this.serviceMap.get(name);
        if (c) {
            return c();
        }
        throw new Error('Unknown service type "' + name + '".');
    }
}
/**
 * Enumeration of states
 */
export var State;
(function (State) {
    /**
     * OK
     */
    State[State["OK"] = 0] = "OK";
    /**
     * INFO
     */
    State[State["INFO"] = 1] = "INFO";
    /**
     * Warning
     */
    State[State["WARNING"] = 2] = "WARNING";
    /**
     * Error
     */
    State[State["ERROR"] = 3] = "ERROR";
})(State || (State = {}));
/**
 * Ok message
 */
export const OK_MESSAGE = Object.freeze({
    state: State.OK,
    text: '',
});
/**
 * Check if any of the messages is an error message
 * @param messages the messages to check
 */
export function anyErrorMessage(...messages) {
    return messages.find((m) => m && m.state === State.ERROR) !== undefined;
}
/**
 * A tree node
 */
export class TreeNode {
    constructor(item, children) {
        this._item = item;
        this._children = [...children];
        this._children.forEach(c => c._parent = this);
    }
    get level() {
        if (this._parent) {
            return this._parent.level + 1;
        }
        return 1;
    }
    get item() {
        return this._item;
    }
    get parent() {
        return this._parent;
    }
    get children() {
        return this._children;
    }
    get prevSibling() {
        if (this._parent) {
            return prevItem(this._parent._children, this);
        }
        return undefined;
    }
    get nextSibling() {
        if (this._parent) {
            return nextItem(this._parent._children, this);
        }
        return undefined;
    }
}
/**
 * Convert a flat list to a tree structure
 * @param arr the array
 * @param childrenProvider compute the children
 * @returns list of root nodes
 */
export function treeStructure(arr, childrenProvider) {
    return treeStructRec(arr, childrenProvider);
}
function treeStructRec(arr, childrenProvider) {
    const nodes = [];
    for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        const children = treeStructRec(childrenProvider(item), childrenProvider);
        const node = new TreeNode(item, children);
        nodes.push(node);
    }
    return nodes;
}

import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { AlertDialog, DialogContainer } from '@adobe/react-spectrum';
import { State } from '@baeso-ui/baeso-core';
import { useValue } from '../utils/util';
export function SpectrumMessageDialog(props) {
    return _jsx(AlertDialog, Object.assign({ title: props.title, variant: toVariant(props.message.state), primaryActionLabel: "Ok" }, { children: props.message.text }));
}
export function SpectrumMessageDialogContainer(props) {
    const [open, setOpen] = useValue(false);
    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);
    return _jsx(DialogContainer, Object.assign({ onDismiss: props.onClose }, { children: open && _jsx(SpectrumMessageDialog, Object.assign({}, props)) }));
}
function toVariant(state) {
    switch (state) {
        case State.ERROR:
            return 'error';
        case State.INFO:
            return 'information';
        case State.WARNING:
            return 'warning';
        default:
            return 'information';
    }
}

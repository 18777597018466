export var SignaturStatus;
(function (SignaturStatus) {
    SignaturStatus[SignaturStatus["OK"] = 0] = "OK";
    SignaturStatus[SignaturStatus["FEHLER"] = 1] = "FEHLER";
    SignaturStatus[SignaturStatus["AUSSTEHEND"] = 2] = "AUSSTEHEND";
})(SignaturStatus || (SignaturStatus = {}));
export var AnhangTyp;
(function (AnhangTyp) {
    AnhangTyp[AnhangTyp["ORIGINAL"] = 0] = "ORIGINAL";
    AnhangTyp[AnhangTyp["TECHNISCH"] = 1] = "TECHNISCH";
})(AnhangTyp || (AnhangTyp = {}));
export var AnhangArt;
(function (AnhangArt) {
    AnhangArt[AnhangArt["GENERISCH"] = 0] = "GENERISCH";
    AnhangArt[AnhangArt["XJUSTIZ"] = 1] = "XJUSTIZ";
})(AnhangArt || (AnhangArt = {}));

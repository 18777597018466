import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { State } from '@baeso-ui/baeso-core';
import { SpectrumFlex } from '../flex/SpectrumFlex';
import { useValue } from '../utils/util';
export function SpectrumFieldMessage(props) {
    const { preallocateHeight = true } = props;
    const LINE_HEIGHT = 1.1;
    const lines = props.maxLines ? props.maxLines : 1;
    const [message,] = useValue(props.message);
    let color = 'inherit';
    if (message) {
        switch (message.state) {
            case State.ERROR:
                color = 'var(--spectrum-semantic-negative-color-text-small,var(--spectrum-global-color-red-600))';
                break;
            case State.INFO:
                color = 'var(--spectrum-semantic-informative-color-text-small,var(--spectrum-global-color-blue-400))';
                break;
            case State.OK:
                color = 'inherit';
                break;
            case State.WARNING:
                color = 'var(--spectrum-semantic-notice-color-text-small,var(--spectrum-global-color-orange-400))';
                break;
            default:
                color = 'inherit';
        }
    }
    const displayNoneError = (message === null || message === void 0 ? void 0 : message.state) !== State.ERROR && (preallocateHeight || (message === null || message === void 0 ? void 0 : message.text));
    const displayError = (message === null || message === void 0 ? void 0 : message.state) === State.ERROR && (preallocateHeight || (message === null || message === void 0 ? void 0 : message.text));
    return _jsxs(SpectrumFlex, Object.assign({ direction: "column", flexGrow: 1 }, { children: [props.children, displayNoneError && _jsx("div", Object.assign({ style: { marginTop: 5, display: '-webkit-box', WebkitLineClamp: lines, WebkitBoxOrient: 'vertical', height: (lines * LINE_HEIGHT) + 'em', lineHeight: LINE_HEIGHT, overflow: 'hidden', color } }, { children: message === null || message === void 0 ? void 0 : message.text })), displayError && _jsx("div", Object.assign({ role: "alert", title: message === null || message === void 0 ? void 0 : message.text, style: { marginTop: 5, display: '-webkit-box', WebkitLineClamp: lines, WebkitBoxOrient: 'vertical', height: (lines * LINE_HEIGHT) + 'em', lineHeight: LINE_HEIGHT, overflow: 'hidden', color } }, { children: message === null || message === void 0 ? void 0 : message.text }))] }));
}

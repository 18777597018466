var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { INTERCEPTED_SERVICE_SUPPORT } from '@beso-rsd/beso-rsd-core';
export class BeteiligtePersonenViewServicesREST {
    constructor(baseUrl, client) {
        this.baseUrl = baseUrl + '/beteiligtepersonenviewservices';
        this.client = client;
    }
    create(beteiligtePerson, verfahrenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('create', this.client, this.baseUrl + '/create', { beteiligtePerson, verfahrenId }, {});
            return result;
        });
    }
    get(id, verfahrensId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('get', this.client, this.baseUrl + '/get', { id, verfahrensId }, {});
            return result;
        });
    }
    list(verfahrensId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('list', this.client, this.baseUrl, { verfahrensId }, {});
            return result;
        });
    }
    delete(id, verfahrenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.delete('delete', this.client, this.baseUrl + '/' + id, { verfahrenId }, {});
            return result;
        });
    }
    getStaticData(verfahrensId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getStaticData', this.client, this.baseUrl + '/getStaticData', { verfahrensId }, {});
            return result;
        });
    }
    updateGelesen(verfahrenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('updateGelesen', this.client, this.baseUrl + '/updateGelesen', { verfahrenId }, {});
            return result;
        });
    }
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { useResizeObserver } from '@react-aria/utils';
import { ELEVATION_LEVELS, useValue } from '../utils/util';
import { SpectrumFlex } from '../flex/SpectrumFlex';
function computedFixedHeaderHeight(scrollY, sectionHeaderHeight, config) {
    if (config === undefined) {
        return 0;
    }
    if (scrollY + sectionHeaderHeight > config.defaultHeight) {
        return config.condensedHeight;
    }
    return config.defaultHeight;
}
export function SpectrumContentPane(props) {
    var _a, _b, _c;
    const [topIndex, setTopIndex] = useValue(-1);
    const [contentWidth, setContentWidth] = useValue(-1);
    const [fixedHeaderHeight, setFixedHeaderHeight] = useValue((_a = props.fixedHeaderConfig) === null || _a === void 0 ? void 0 : _a.defaultHeight);
    const scrollDiv = useRef(null);
    const HEADER_HEIGHT = 32;
    const PADDING_TOP = 20;
    const PADDING = 20;
    const ROW_GAP = 20;
    const recomputeHeader = () => {
        const div = scrollDiv.current;
        if (div) {
            const FIXED_HEADER_HEIGHT = computedFixedHeaderHeight(div.scrollTop, HEADER_HEIGHT, props.fixedHeaderConfig);
            const area = div.getBoundingClientRect();
            setContentWidth(div.clientWidth);
            setFixedHeaderHeight(FIXED_HEADER_HEIGHT);
            const innerContainer = div.children.item(0);
            if (innerContainer) {
                let idx = -1;
                for (let i = 0; i < innerContainer.children.length; i++) {
                    const item = innerContainer.children.item(i);
                    if (item) {
                        const itemArea = item.getBoundingClientRect();
                        const containerOffset = itemArea.y - area.y - (i == 0 ? 0 : HEADER_HEIGHT / 2) - FIXED_HEADER_HEIGHT;
                        if (containerOffset > 0) {
                            break;
                        }
                        idx = i;
                    }
                }
                setTopIndex(idx);
            }
        }
    };
    useResizeObserver({
        ref: scrollDiv,
        onResize: recomputeHeader
    });
    const contentPadding = PADDING_TOP + (((_b = props === null || props === void 0 ? void 0 : props.fixedHeaderConfig) === null || _b === void 0 ? void 0 : _b.defaultHeight) === undefined ? 0 : (_c = props === null || props === void 0 ? void 0 : props.fixedHeaderConfig) === null || _c === void 0 ? void 0 : _c.defaultHeight);
    const sectionHeader = _jsx(SpectrumFlex, Object.assign({ alignItems: "center", minHeight: HEADER_HEIGHT, boxShadow: ELEVATION_LEVELS.ELEVATION_4, backgroundColor: "gray-50" }, { children: _jsx("div", Object.assign({ style: { fontWeight: 'bold', cursor: 'default', flexGrow: 1, textAlign: 'left', fontVariantCaps: 'small-caps' } }, { children: topIndex >= 0 && props.sectionContent(topIndex) })) }));
    const contentArea = _jsx("div", Object.assign({ ref: scrollDiv, style: { flexGrow: 1, height: '100%', flexDirection: 'column', overflowX: 'hidden', overflowY: 'auto' }, onScroll: recomputeHeader }, { children: _jsx(SpectrumFlex, Object.assign({ direction: "column", padding: PADDING, paddingTop: contentPadding, rowGap: ROW_GAP }, { children: props.children })) }));
    const basicSecionStyleProps = {
        visibility: topIndex === -1 ? 'hidden' : 'visible',
        height: topIndex === -1 ? 0 : HEADER_HEIGHT,
        transition: 'height 0.4s, visibility 0.4s',
        overflow: 'hidden'
    };
    if (props.fixedHeaderConfig !== undefined) {
        const headerContainerProps = {
            position: 'absolute',
            left: 0,
            top: 0,
            width: contentWidth !== -1 ? contentWidth : '100%',
            boxShadow: ELEVATION_LEVELS.ELEVATION_4,
            zIndex: 1
        };
        const headerStyleProps = {
            height: fixedHeaderHeight,
            zIndex: 100,
            transition: 'height 0.4s',
            overflow: 'hidden',
        };
        return _jsxs("div", Object.assign({ style: { position: 'relative', flexGrow: 1, flexDirection: 'row', overflowX: 'hidden', overflowY: 'hidden' } }, { children: [_jsxs("div", Object.assign({ style: headerContainerProps }, { children: [_jsx("div", Object.assign({ style: headerStyleProps }, { children: _jsxs(SpectrumFlex, Object.assign({ borderBottomColor: topIndex === -1 ? 'gray-50' : 'gray-400', borderBottomWidth: 'thin', alignItems: "center", height: "100%", backgroundColor: "gray-50" }, { children: [fixedHeaderHeight === props.fixedHeaderConfig.defaultHeight && props.fixedHeaderConfig.defaultContent, fixedHeaderHeight !== props.fixedHeaderConfig.defaultHeight && props.fixedHeaderConfig.condensedContent] })) })), _jsx("div", Object.assign({ style: basicSecionStyleProps }, { children: sectionHeader }))] })), contentArea] }));
    }
    const styleProps = Object.assign(Object.assign({}, basicSecionStyleProps), { zIndex: 1, position: 'absolute', left: 0, top: 0, width: contentWidth !== -1 ? contentWidth : '100%', boxShadow: ELEVATION_LEVELS.ELEVATION_4 });
    return _jsxs("div", Object.assign({ style: { position: 'relative', flexGrow: 1, flexDirection: 'row', overflowX: 'hidden', overflowY: 'hidden' } }, { children: [_jsx("div", Object.assign({ style: styleProps }, { children: sectionHeader })), contentArea] }));
}

import { jsx as _jsx } from "react/jsx-runtime";
import { useStyleProps, viewStyleProps } from '@react-spectrum/utils';
import { filterDOMProps } from '@react-aria/utils';
export function SpectrumView(props) {
    const { elementType: ElementType = 'div' } = props;
    const { styleProps } = useStyleProps(props, viewStyleProps);
    let style = styleProps.style || {};
    style = adaptBorderRadius(style, props);
    style = adaptBorderSize(style, props);
    const domProps = filterDOMProps(props, { labelable: true });
    return _jsx(ElementType, Object.assign({}, domProps, { style: style }, { children: props.children }));
}
function adaptBorderRadius(style, props) {
    if (!isBorderRadiusValue(props.borderRadius)) {
        style.borderRadius = computeDimensionValue(props.borderRadius);
    }
    if (!isBorderRadiusValue(props.borderTopStartRadius)) {
        style.borderTopLeftRadius = computeDimensionValue(props.borderTopStartRadius);
    }
    if (!isBorderRadiusValue(props.borderTopEndRadius)) {
        style.borderRightWidth = computeDimensionValue(props.borderTopEndRadius);
    }
    if (!isBorderRadiusValue(props.borderBottomStartRadius)) {
        style.borderBottomLeftRadius = computeDimensionValue(props.borderBottomStartRadius);
    }
    if (!isBorderRadiusValue(props.borderBottomEndRadius)) {
        style.borderBottomRightRadius = computeDimensionValue(props.borderBottomEndRadius);
    }
    return style;
}
function adaptBorderSize(style, props) {
    if (!isBorderSizeValue(props.borderWidth)) {
        style.borderWidth = computeDimensionValue(props.borderWidth);
    }
    if (!isBorderSizeValue(props.borderXWidth)) {
        style.borderLeftWidth = style.borderRightWidth = computeDimensionValue(props.borderXWidth);
    }
    if (!isBorderSizeValue(props.borderYWidth)) {
        style.borderTopWidth = style.borderBottomWidth = computeDimensionValue(props.borderYWidth);
    }
    if (!isBorderSizeValue(props.borderStartWidth)) {
        style.borderLeftWidth = computeDimensionValue(props.borderStartWidth);
    }
    if (!isBorderSizeValue(props.borderEndWidth)) {
        style.borderRightWidth = computeDimensionValue(props.borderEndWidth);
    }
    if (!isBorderSizeValue(props.borderTopWidth)) {
        style.borderTopWidth = computeDimensionValue(props.borderTopWidth);
    }
    if (!isBorderSizeValue(props.borderBottomWidth)) {
        style.borderBottomWidth = computeDimensionValue(props.borderBottomWidth);
    }
    return style;
}
function isBorderSizeValue(value) {
    return value === undefined ||
        value === 'thin' ||
        value === 'thick' ||
        value === 'thicker' ||
        value === 'thickest' ||
        (typeof (value) !== 'number' && typeof (value) !== 'string'); // not a primitive -> ResponsiveProp part
}
function isBorderRadiusValue(value) {
    return value === undefined ||
        value === 'xsmall' ||
        value === 'small' ||
        value === 'regular' ||
        value === 'medium' ||
        value === 'large' ||
        (typeof (value) !== 'number' && typeof (value) !== 'string'); // not a primitive -> ResponsiveProp part
}
function computeDimensionValue(value) {
    var _a;
    return (_a = useStyleProps({ minWidth: value }, viewStyleProps).styleProps.style) === null || _a === void 0 ? void 0 : _a.minWidth;
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, ButtonGroup, Content, Dialog, DialogContainer, Divider, Header, Heading, ProgressCircle, Text } from '@adobe/react-spectrum';
import { OK_MESSAGE, State } from '@baeso-ui/baeso-core';
import { SpectrumMessageDialogContainer } from './SpectrumMessageDialog';
export function SpectrumRemoteActionDialogContainer(props) {
    const { open, closeHandler, remoteActionHandler, validationHandler, shouldRunRemoteActionHandler } = props;
    const [remoteActionRunning, setRemoteActionRunning] = useState(false);
    const [remoteError, setRemoteError] = useState();
    const handleClose = () => {
        if (!remoteActionRunning) {
            closeHandler();
        }
    };
    const handleOk = (data) => __awaiter(this, void 0, void 0, function* () {
        if (remoteActionRunning) {
            return;
        }
        if (shouldRunRemoteActionHandler && !shouldRunRemoteActionHandler(data)) {
            closeHandler();
            return;
        }
        if (validationHandler === undefined || validationHandler(data)) {
            try {
                setRemoteActionRunning(true);
                const result = yield remoteActionHandler(data);
                if (result && result.state !== State.OK) {
                    setRemoteError(result);
                }
                else {
                    closeHandler();
                }
            }
            catch (e) {
                if (e && e instanceof Error) {
                    if (e.message) {
                        setRemoteError({ state: State.ERROR, text: e.message });
                    }
                    else {
                        setRemoteError({ state: State.ERROR, text: e + '' });
                    }
                }
                else {
                    setRemoteError({ state: State.ERROR, text: 'Unbekannter Fehler' });
                }
            }
            finally {
                setRemoteActionRunning(false);
            }
        }
    });
    return _jsxs(_Fragment, { children: [_jsx(DialogContainer, Object.assign({ onDismiss: closeHandler, isKeyboardDismissDisabled: remoteActionRunning }, { children: open && _jsx(SpectrumRemoteActionDialog, Object.assign({}, props, { remoteActionRunning: remoteActionRunning, closeHandler: handleClose, okHandler: handleOk })) })), _jsx(SpectrumMessageDialogContainer, { open: remoteError !== undefined, onClose: () => setRemoteError(undefined), title: "Fehler beim Ausf\u00FChren der Aktion am Server", message: remoteError || OK_MESSAGE })] });
}
export function SpectrumRemoteActionDialog(props) {
    const { remoteActionRunning, closeHandler, okHandler, dataFactory, contentFactory, cancelLabel, actionLabel, actionProgressLabel, title, description } = props;
    const [data] = useState(() => dataFactory());
    return _jsxs(Dialog, Object.assign({}, props, { children: [_jsx(Heading, { children: title }), description && _jsx(Header, { children: description }), _jsx(Divider, {}), _jsx(Content, { children: contentFactory(data) }), _jsxs(ButtonGroup, { children: [_jsx(Button, Object.assign({ variant: "secondary", onPress: closeHandler }, { children: cancelLabel })), _jsxs(Button, Object.assign({ variant: "cta", onPress: () => okHandler(data) }, { children: [remoteActionRunning && _jsxs(_Fragment, { children: [_jsx(ProgressCircle, { size: "S", "aria-label": actionProgressLabel, isIndeterminate: true, marginEnd: 5 }), _jsx(Text, { children: actionProgressLabel })] }), !remoteActionRunning && _jsx(Text, { children: actionLabel })] }))] })] }));
}
SpectrumRemoteActionDialog.defaultProps = {
    cancelLabel: 'Abbrechen',
    actionLabel: 'Ok',
    actionProgressLabel: 'Speichern ...'
};
export function SpectrumRemoteActionAlertDialogContainer(props) {
    const { open, closeHandler, remoteActionHandler } = props;
    const [remoteActionRunning, setRemoteActionRunning] = useState(false);
    const [remoteError, setRemoteError] = useState();
    const handleClose = () => {
        if (!remoteActionRunning) {
            closeHandler();
        }
    };
    const handleOk = () => __awaiter(this, void 0, void 0, function* () {
        if (remoteActionRunning) {
            return;
        }
        try {
            setRemoteActionRunning(true);
            const result = yield remoteActionHandler();
            if (result && result.state !== State.OK) {
                setRemoteError(result);
            }
            else {
                closeHandler();
            }
        }
        catch (e) {
            if (e && e instanceof Error) {
                if (e.message) {
                    setRemoteError({ state: State.ERROR, text: e.message });
                }
                else {
                    setRemoteError({ state: State.ERROR, text: e + '' });
                }
            }
            else {
                setRemoteError({ state: State.ERROR, text: 'Unbekannter Fehler' });
            }
        }
        finally {
            setRemoteActionRunning(false);
        }
    });
    return _jsxs(_Fragment, { children: [_jsx(DialogContainer, Object.assign({ onDismiss: closeHandler, isKeyboardDismissDisabled: remoteActionRunning }, { children: open && _jsx(SpectrumRemoteActionAlertDialog, Object.assign({}, props, { remoteActionRunning: remoteActionRunning, closeHandler: handleClose, okHandler: handleOk })) })), _jsx(SpectrumMessageDialogContainer, { open: remoteError !== undefined, onClose: () => setRemoteError(undefined), title: "Fehler beim Ausf\u00FChren der Aktion am Server", message: remoteError || OK_MESSAGE })] });
}
export function SpectrumRemoteActionAlertDialog(props) {
    const { remoteActionRunning, closeHandler, okHandler, cancelLabel, actionLabel, actionProgressLabel, title, children, variant } = props;
    let buttonVariant = 'primary';
    if (variant) {
        if (variant === 'confirmation') {
            buttonVariant = 'cta';
        }
        else if (variant === 'destructive') {
            buttonVariant = 'negative';
        }
    }
    return _jsxs(Dialog, Object.assign({}, props, { children: [_jsx(Heading, { children: title }), _jsx(Divider, {}), _jsx(Content, { children: children }), _jsxs(ButtonGroup, { children: [_jsx(Button, Object.assign({ variant: "secondary", onPress: closeHandler }, { children: cancelLabel })), _jsxs(Button, Object.assign({ variant: buttonVariant, onPress: () => okHandler() }, { children: [remoteActionRunning && _jsxs(_Fragment, { children: [_jsx(ProgressCircle, { size: "S", "aria-label": actionProgressLabel, isIndeterminate: true, marginEnd: 5 }), _jsx(Text, { children: actionProgressLabel })] }), !remoteActionRunning && _jsx(Text, { children: actionLabel })] }))] })] }));
}
SpectrumRemoteActionAlertDialog.defaultProps = {
    cancelLabel: 'Abbrechen',
    okLabel: 'Ok',
    okProgressLabel: 'Speichern ...'
};

/**
 * Remove the element at the provided index and shrinks the array
 * @param arr the array to modify
 * @param idx the index
 * @return true if element at provided index was removed
 */
export function removeAt(arr, idx) {
    if (idx >= 0 && idx < arr.length) {
        arr.splice(idx, 1);
        return true;
    }
    return false;
}
/**
 * remove the first occurence of the provided element
 * @param arr the array to modify
 * @param e the element
 * @returns true if the provided element has been removed
 */
export function remove(arr, e) {
    return removeAt(arr, arr.indexOf(e));
}
/**
 * Wipe all elements passed from array
 * @param arr the array
 * @param e the list of elements
 * @returns true if at least one of the elements has been removed
 */
export function removeAll(arr, e) {
    let rv = false;
    e.forEach((v) => {
        let loop = true;
        while (loop) {
            loop = remove(arr, v);
            if (loop) {
                rv = true;
            }
        }
    });
    return rv;
}
/**
 * Insert the provided element in the list at provided index
 * @param arr the array to modify
 * @param index the index the element is added
 * @param e the element to add
 */
export function insert(arr, index, e) {
    arr.splice(index, 0, e);
}
/**
 * Remove all elements from the array who match the given criteria
 * @param arr the array
 * @param predicate the predicate to check
 */
export function removeIf(arr, predicate) {
    const toDelete = arr.filter(v => predicate(v));
    return removeAll(arr, toDelete);
}
/**
 * Fetch the last element in the array
 * @param arr the array
 * @returns the element or undefined
 */
export function last(arr) {
    return arr.length == 0 ? undefined : arr[arr.length - 1];
}
/**
 * Push the array in valueArr on the arr
 * @param arr the target array
 * @param valueArr the array to push
 */
export function pushAll(arr, valueArr) {
    Array.prototype.push.apply(arr, valueArr);
}
/**
 * Find the sibling before the provided element
 * @param arr the array
 * @param value the value
 */
export function prevItem(arr, value) {
    const idx = arr.indexOf(value);
    return idx > 0 ? arr[idx - 1] : undefined;
}
/**
 * Find the sibling after the provided element
 * @param arr the array
 * @param value the value
 */
export function nextItem(arr, value) {
    const idx = arr.indexOf(value);
    return idx + 1 < arr.length ? arr[idx + 1] : undefined;
}

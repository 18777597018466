var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { INTERCEPTED_SERVICE_SUPPORT } from '@beso-rsd/beso-rsd-core';
export class NachrichtenViewServiceREST {
    constructor(baseUrl, client) {
        this.endpoint = baseUrl + '/nachrichtenviewservice';
        this.client = client;
    }
    getPostfaecher() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getPostfaecher', this.client, `${this.endpoint}/postfaecher`, {}, {});
            return result;
        });
    }
    getEingangsBaseData(postfachId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getEingangsBaseData', this.client, `${this.endpoint}/postfaecher/${postfachId}/eingang/base-data`, {}, {});
            return result;
        });
    }
    getEingangsNachrichten(postfachId, bearbeitet, nurUngelesen, von, bis, filter, sortierung) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getEingangsNachrichten', this.client, `${this.endpoint}/postfaecher/${postfachId}/eingang`, { bearbeitet, nurUngelesen, von, bis, filter, sortierung }, {});
            return result;
        });
    }
    getEingangsNachrichtDetails(nachrichtenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getEingangsNachrichtDetails', this.client, `${this.endpoint}/eingang/${nachrichtenId}`, {}, {});
            return result;
        });
    }
    updateEingangsNachrichtDetailsGelesen(nachrichtenId, etag, value) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.put('updateEingangsNachrichtDetailsGelesen', this.client, `${this.endpoint}/eingang/${nachrichtenId}/gelesen`, { etag, value }, {});
            return result + '';
        });
    }
    updateEingangsNachrichtDetailsBearbeitet(nachrichtenId, etag, value) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.put('updateEingangsNachrichtDetailsBearbeitet', this.client, `${this.endpoint}/eingang/${nachrichtenId}/bearbeitet`, { etag, value }, {});
            return result + '';
        });
    }
    updateEingangsNachrichtDetails(detailsDelta) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.patch('updateEingangsNachrichtDetails', this.client, `${this.endpoint}/eingang`, { detailsDelta }, {});
            return result + '';
        });
    }
    getAusgangsNachrichten(postfachId, geloescht, von, bis, filter, sortierung) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getAusgangsNachrichten', this.client, `${this.endpoint}/postfaecher/${postfachId}/ausgang`, { geloescht, von, bis, filter, sortierung }, {});
            return result;
        });
    }
    getAusgangsNachrichtDetails(nachrichtenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getAusgangsNachrichtDetails', this.client, `${this.endpoint}/ausgang/${nachrichtenId}`, {}, {});
            return result;
        });
    }
    updateAusgangsNachrichtDetailsGeloescht(nachrichtenId, etag, value) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.put('updateAusgangsNachrichtDetailsGeloescht', this.client, `${this.endpoint}/ausgang/${nachrichtenId}/geloescht`, { etag, value }, {});
            return result + '';
        });
    }
    clearGeloeschteAusgangsNachrichten(postfachId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('clearGeloeschteAusgangsNachrichten', this.client, `${this.endpoint}/postfaecher/${postfachId}/ausgang/clear`, {}, {});
            return result;
        });
    }
    moveEingangsNachricht(nachrichtenId, etag, targetPostfachId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('moveEingangsNachricht', this.client, `${this.endpoint}/eingang/${nachrichtenId}/move`, { etag, targetPostfachId }, {});
            return result + '';
        });
    }
    findAktenzeichen(aktenzeichenTeil) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('findAktenzeichen', this.client, `${this.endpoint}/find-aktenzeichen`, { aktenzeichenTeil }, {});
            return result;
        });
    }
    getEingangsNachrichtVerfahren(nachrichtenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getEingangsNachrichtVerfahren', this.client, `${this.endpoint}/eingang/${nachrichtenId}/verfahren`, {}, {});
            return result;
        });
    }
    getAusgangsNachrichtVerfahren(nachrichtenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getAusgangsNachrichtVerfahren', this.client, `${this.endpoint}/ausgang/${nachrichtenId}/verfahren`, {}, {});
            return result;
        });
    }
    downloadEingangsAnhang(anhangId) {
        return INTERCEPTED_SERVICE_SUPPORT.get('downloadEingangsAnhang', this.client, `${this.endpoint}/eingang/anhang/${anhangId}`, {}, {}, 'blob');
    }
    downloadEingangsAnhangURL(anhangId) {
        return `${this.endpoint}/eingang/anhang/${anhangId}`;
    }
    downloadAusgangsAnhang(anhangId) {
        return INTERCEPTED_SERVICE_SUPPORT.get('downloadAusgangsAnhang', this.client, `${this.endpoint}/ausgang/anhang/${anhangId}`, {}, {}, 'blob');
    }
    downloadAusgangsAnhangURL(anhangId) {
        return `${this.endpoint}/ausgang/anhang/${anhangId}`;
    }
    getPDFHandle(anhangId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getPDFHandle', this.client, `${this.endpoint}/anhang/${anhangId}/pdfhandle`, {}, {});
            return result + '';
        });
    }
    getOnlyOfficeConfig(anhangId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getOnlyOfficeConfig', this.client, `${this.endpoint}/anhang/${anhangId}/onlyoffice-config`, {}, {});
            return result;
        });
    }
}

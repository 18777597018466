const german = {
    addButton: 'Hinzufügen',
    editButton: 'Bearbeiten',
    deleteButton: 'Löschen',
    'deleteButton.title': 'Eintrag entfernen?',
    'deleteButton.confirm': 'Entfernen',
    'deleteButton.cancel': 'Abbrechen',
    'deleteButton.warning': 'Sind Sie sich sicher, dass Sie den gewählten Eintrag entfernen wollen?',
    'tasks.cancel': 'Abbruch',
    'tasks.saveAndExit': 'Übernehmen',
};
export const messages = {
    'en-US': german,
    'de-AT': german,
    'de-DE': german,
    'de-CH': german,
};

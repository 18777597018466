import { ObjectProperty } from '@baeso-ui/baeso-observable';
function buildURL(verfahrensId, initialeKomponente) {
    return '/verfahren/' + verfahrensId + ((initialeKomponente) ? '/' + initialeKomponente : '');
}
export function openVerfahrenWindow(aktenzeichen, verfahrensId, initialeKomponente) {
    if (typeof verfahrensId === 'string') {
        window.open(buildURL(verfahrensId, initialeKomponente), 'verfahrens-fenster-' + aktenzeichen);
    }
    else {
        const newWindow = window.open(undefined, 'verfahrens-fenster-' + aktenzeichen);
        if (newWindow) {
            verfahrensId.then(id => {
                newWindow.location.href = buildURL(id, initialeKomponente);
            });
        }
    }
}
const localStorageThemePreferenceKey = 'onacta-preference-theme';
function getThemePreference() {
    const v = window.localStorage.getItem(localStorageThemePreferenceKey);
    if (v !== 'light'
        && v !== 'extra-light'
        && v !== 'dark'
        && v !== 'extra-dark') {
        return 'standard';
    }
    return v;
}
const localStorageScalePreferenceKey = 'onacta-preference-scale';
function getScalePreference() {
    const v = window.localStorage.getItem(localStorageScalePreferenceKey);
    if (v !== 'normal'
        && v !== 'large') {
        return 'standard';
    }
    return v;
}
const localStorageDarkModeDocumentPreferenceKey = 'onacta-preference-darkmode-document';
function getDarkModeDocument() {
    const v = window.localStorage.getItem(localStorageDarkModeDocumentPreferenceKey);
    return v === 'true';
}
const localStorageVisionDeficiencyEmulationPreferenceKey = 'onacta-preference-vision-deficiency-emulation';
function getVisionDeficiencyEmulation() {
    const v = window.localStorage.getItem(localStorageVisionDeficiencyEmulationPreferenceKey);
    if (v !== 'blurred-vision'
        && v !== 'protanopia'
        && v !== 'protanomaly'
        && v !== 'deuteranopia'
        && v !== 'deuteranomaly'
        && v !== 'tritanopia'
        && v !== 'tritanomaly'
        && v !== 'achromatopsia'
        && v !== 'achromatomaly') {
        return 'off';
    }
    return v;
}
export class Preferences {
    constructor() {
        this.theme = new ObjectProperty('theme', 'standard');
        this.scale = new ObjectProperty('scale', 'standard');
        this.darkModeDocument = new ObjectProperty('darkModeDocument', false);
        this.visionDeficiencyEmulation = new ObjectProperty('visionDeficiencyEmulation', 'off');
        this.updating = false;
        this.theme.value = getThemePreference();
        this.scale.value = getScalePreference();
        this.visionDeficiencyEmulation.value = getVisionDeficiencyEmulation();
        this.darkModeDocument.value = getDarkModeDocument();
        window.addEventListener('storage', () => {
            if (this.updating) {
                return;
            }
            this.updating = true;
            try {
                this.theme.value = getThemePreference();
                this.scale.value = getScalePreference();
                this.visionDeficiencyEmulation.value = getVisionDeficiencyEmulation();
                this.darkModeDocument.value = getDarkModeDocument();
            }
            finally {
                this.updating = false;
            }
        });
        const changeHandler = () => {
            var _a, _b, _c, _d;
            if (this.updating) {
                return;
            }
            this.updating = true;
            try {
                window.localStorage.setItem(localStorageThemePreferenceKey, (_a = this.theme.value) !== null && _a !== void 0 ? _a : 'standard');
                window.localStorage.setItem(localStorageScalePreferenceKey, (_b = this.scale.value) !== null && _b !== void 0 ? _b : 'standard');
                window.localStorage.setItem(localStorageVisionDeficiencyEmulationPreferenceKey, (_c = this.visionDeficiencyEmulation.value) !== null && _c !== void 0 ? _c : 'off');
                window.localStorage.setItem(localStorageDarkModeDocumentPreferenceKey, String((_d = this.darkModeDocument.value) !== null && _d !== void 0 ? _d : false));
            }
            finally {
                this.updating = false;
            }
        };
        this.theme.onChange(changeHandler);
        this.scale.onChange(changeHandler);
        this.darkModeDocument.onChange(changeHandler);
        this.visionDeficiencyEmulation.onChange(changeHandler);
    }
}
export const preferenceStore = new Preferences();

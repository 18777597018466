import { useCallback, useMemo } from 'react';
import { DateFormats } from '@baeso-ui/baeso-core';
import { useDateFormatter, useDateParser } from '../utils/util';
export function parserChain(...parsers) {
    return (val) => {
        for (let i = 0; i < parsers.length; i++) {
            const result = parsers[i](val);
            if (result !== undefined) {
                return result;
            }
        }
        return undefined;
    };
}
const CENTURY = Math.floor(new Date().getFullYear() / 100);
const DATE_MONTH = (val) => {
    const rs = /^(\d\d?)\.(\d\d?)\.?$/.exec(val);
    if (rs && rs.length === 3) {
        return new Date().getFullYear() + '-' + rs[2].padStart(2, '0') + '-' + rs[1].padStart(2, '0');
    }
    return undefined;
};
const DATE_MONTH_YEAR = (val) => {
    const rs = /^(\d\d?)\.(\d\d?)\.?(\d{1,4})$/.exec(val);
    if (rs && rs.length === 4) {
        let year = rs[3];
        if (year.length == 2) {
            year = new Date().getFullYear() % 100 < parseInt(year) ? CENTURY - 1 + year : CENTURY + year;
        }
        else {
            year = year.padStart(4, '0');
        }
        return year + '-' + rs[2].padStart(2, '0') + '-' + rs[1].padStart(2, '0');
    }
    return undefined;
};
const DATE_NOSEP = (val) => {
    if (/^(\d\d*)$/.test(val)) {
        if (val.length == 1 || val.length == 2) {
            const d = new Date();
            return d.getFullYear() + '-' + (d.getMonth() + '').padStart(2, '0') + '-' + val.padStart(2, '0');
        }
        else if (val.length == 3 || val.length == 4) {
            const d = new Date();
            return d.getFullYear() + '-' + val.substr(2).padStart(2, '0') + '-' + val.substr(0, 2);
        }
        else {
            return DATE_MONTH_YEAR(val.substr(0, 2) + '.' + val.substr(2, 2) + '.' + val.substr(4));
        }
    }
    return undefined;
};
export function useDefaultDateInputParser() {
    const dateParser = useDateParser();
    const localParser = useCallback((d) => dateParser(d, DateFormats.DATE_NORMAL), [dateParser]);
    const isoParser = useCallback((d) => dateParser(d, DateFormats.DATE_ISO), [dateParser]);
    return useMemo(() => parserChain(DATE_MONTH, DATE_MONTH_YEAR, DATE_NOSEP, isoParser, localParser), [isoParser, localParser]);
}
export function useDefaultDateFormatter() {
    const dateFormatter = useDateFormatter();
    return useCallback((date) => dateFormatter(date, DateFormats.DATE_NORMAL), [dateFormatter]);
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useRef } from 'react';
import './simple-variant.css';
import { useResponsiveClasses } from '../utils/util';
const CLASS_NAME_CONFIGS = [
    {
        classNames: ['timeline-split'],
        widthRange: [992, 1280]
    },
    {
        classNames: ['timeline-centered'],
        widthRange: [1280]
    }
];
function computeChildren(items, itemComputer, sectionComputer) {
    const children = [];
    if (sectionComputer === undefined) {
        items.forEach(item => children.push(itemComputer(item)));
    }
    else {
        const computer = sectionComputer;
        const m = new Set();
        items.forEach((item, index, array) => {
            const sectionKey = computer(item, index, array);
            if (!m.has(sectionKey)) {
                children.push(_jsx(TimeLineSectionItem, { title: sectionKey }, 'section_' + sectionKey));
                m.add(sectionKey);
            }
            children.push(itemComputer(item));
        });
    }
    return children;
}
export function SpectrumTimeLine(props) {
    const { items, itemComputer, sectionComputer } = props;
    const domRef = useRef(null);
    const children = useMemo(() => computeChildren(items, itemComputer, sectionComputer), [items, itemComputer, sectionComputer]);
    const classNames = useResponsiveClasses(domRef, CLASS_NAME_CONFIGS);
    const className = 'timeline ' + classNames.join(' ');
    return _jsx("ol", Object.assign({ ref: domRef, className: className }, { children: children }));
}
export function SpectrumTimeLineItem(props) {
    return _jsxs("li", Object.assign({ className: "timeline-item" }, { children: [_jsx("div", Object.assign({ className: "timeline-info" }, { children: _jsx("span", { children: props.subtitle }) })), _jsx("div", { className: "timeline-marker" }), _jsxs("div", Object.assign({ className: "timeline-content" }, { children: [_jsx("div", Object.assign({ className: "timeline-title" }, { children: props.title })), _jsx("p", { children: props.children })] }))] }));
}
function TimeLineSectionItem(props) {
    return _jsxs("li", Object.assign({ className: "timeline-item period" }, { children: [_jsx("div", { className: "timeline-info" }), _jsx("div", { className: "timeline-marker" }), _jsx("div", Object.assign({ className: "timeline-content" }, { children: _jsx("h2", Object.assign({ className: "timeline-title" }, { children: props.title })) }))] }));
}

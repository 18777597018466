import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Children as ReactChildren, forwardRef, useCallback, useRef, useState, } from 'react';
import { useDOMRef, useStyleProps, useUnwrapDOMRef, viewStyleProps } from '@react-spectrum/utils';
import { Flex } from '@adobe/react-spectrum';
import { useResizeObserver } from '@react-aria/utils';
export function SpectrumFlex(props) {
    const { styleProps } = useStyleProps(props, viewStyleProps);
    const finalStyles = Object.assign(Object.assign({ boxShadow: props.boxShadow }, styleProps.style), props.style);
    if (Object.keys(finalStyles).length !== 0 && (props.rowGap || props.columnGap || props.gap) && !isFlexGapSupported()) {
        finalStyles.display = props.isHidden ? 'none' : 'flex';
        finalStyles.flexDirection = 'row';
        if (props.isResponsive) {
            return _jsx(SafariResponsiveSpectrumFlex, Object.assign({}, props, { _wrapperStyles: finalStyles }), "safari-responsive");
        }
        return _jsx(SafariSpectrumFlex, Object.assign({}, props, { _wrapperStyles: finalStyles }), "safari-wrap");
    }
    if (props.isResponsive) {
        return _jsx(DefaultResponsiveSpectrumFlex, Object.assign({}, props, { _wrapperStyles: finalStyles }), "default-responsive");
    }
    return _jsx(DefaultSpectrumFlex, Object.assign({}, props, { _wrapperStyles: finalStyles }), "default-flex");
}
function _DefaultSpectrumFlex(props, ref) {
    return _jsx(Flex, Object.assign({ ref: ref, id: props.id, direction: props.direction, columnGap: props.columnGap, rowGap: props.rowGap, gap: props.gap, isHidden: props.isHidden, minWidth: props.fixedWidth, maxWidth: props.fixedWidth, justifyContent: props.justifyContent, alignItems: props.alignItems, alignContent: props.alignContent, UNSAFE_style: props._wrapperStyles, UNSAFE_className: props.UNSAFE_className }, { children: _jsx(_Fragment, { children: props.children }) }));
}
const DefaultSpectrumFlex = forwardRef(_DefaultSpectrumFlex);
function DefaultResponsiveSpectrumFlex(props) {
    const [filteredChildren, setFilteredChildren] = useState();
    const div = useRef(null);
    const unwrappedDiv = useUnwrapDOMRef(div);
    const onResize = useCallback(() => {
        if (unwrappedDiv.current === null) {
            return;
        }
        const width = unwrappedDiv.current.clientWidth;
        if (width > 0) {
            const newChildren = getResponsiveChildren(props.children, width);
            setFilteredChildren(newChildren);
        }
    }, [unwrappedDiv, props.children]);
    useResizeObserver({
        ref: unwrappedDiv,
        onResize
    });
    return _jsx(DefaultSpectrumFlex, Object.assign({ ref: div }, props, { children: filteredChildren }));
}
function getResponsiveChildren(children, width) {
    const list = [];
    ReactChildren.forEach(children, e => list.push(e));
    return list.filter(e => {
        if (e !== null && e.type._typeName === 'SpectrumResponsiveItem') {
            return inRange(width, e.props.widthRange);
        }
        return true;
    });
}
function inRange(width, range) {
    if (width >= range[0] && (range[1] === undefined || width <= range[1])) {
        return true;
    }
    return false;
}
function _SafariSpectrumFlex(props, ref) {
    const domRef = useDOMRef(ref);
    const propsCopy = Object.assign({}, props);
    return _jsx("div", Object.assign({ ref: domRef, style: props._wrapperStyles, id: "safari-wrapper" }, { children: _jsx(DefaultSpectrumFlex, Object.assign({}, propsCopy, { _wrapperStyles: { width: '100%' } })) }), "safari-wrapper");
}
const SafariSpectrumFlex = forwardRef(_SafariSpectrumFlex);
function SafariResponsiveSpectrumFlex(props) {
    const [filteredChildren, setFilteredChildren] = useState();
    const div = useRef(null);
    const unwrappedDiv = useUnwrapDOMRef(div);
    const onResize = useCallback(() => {
        if (unwrappedDiv.current === null) {
            return;
        }
        const width = unwrappedDiv.current.clientWidth;
        if (width > 0) {
            const newChildren = getResponsiveChildren(props.children, width);
            console.log('children', newChildren);
            setFilteredChildren(newChildren);
        }
    }, [unwrappedDiv, props.children]);
    useResizeObserver({
        ref: unwrappedDiv,
        onResize
    });
    return _jsx(SafariSpectrumFlex, Object.assign({ ref: div }, props, { children: filteredChildren }));
}
// Original licensing for the following method can be found in the
// NOTICE file in the root directory of this source tree.
// See https://github.com/Modernizr/Modernizr/blob/7efb9d0edd66815fb115fdce95fabaf019ce8db5/feature-detects/css/flexgap.js
let _isFlexGapSupported = null;
function isFlexGapSupported() {
    var _a;
    if (_isFlexGapSupported != null) {
        return _isFlexGapSupported;
    }
    if (typeof document === 'undefined') {
        return false;
    }
    // create flex container with row-gap set
    const flex = document.createElement('div');
    flex.style.display = 'flex';
    flex.style.flexDirection = 'column';
    flex.style.rowGap = '1px';
    // create two, elements inside it
    flex.appendChild(document.createElement('div'));
    flex.appendChild(document.createElement('div'));
    // append to the DOM (needed to obtain scrollHeight)
    document.body.appendChild(flex);
    _isFlexGapSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
    (_a = flex.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(flex);
    return _isFlexGapSupported;
}
export function SpectrumResponsiveItem(props) {
    return _jsx(_Fragment, { children: props.children });
}
SpectrumResponsiveItem._typeName = 'SpectrumResponsiveItem';

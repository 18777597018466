import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useValue } from '@baeso-ui/baeso-ui-spectrum';

import { LoginService } from '@onacta/onacta-base-services';
import { LoginView } from '@onacta/onacta-home-spectrum';

function isRecord(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null && value !== undefined;
}

export function RequireAuth(): JSX.Element {
  const [ loggedIn ] = useValue(LoginService.getInstance().isLoggedInProperty());
  const location = useLocation();

  if ( !loggedIn ) {
    // redirect to the login page, but save the location to redirect back after successful authentication
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  } else {
    return <Outlet />;
  }
}

export function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const previousLocation = isRecord(location.state) && typeof location.state.from === 'string' ? location.state.from : '/';

  return <LoginView success={() => navigate(previousLocation, { replace: true })} />;
}
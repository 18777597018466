import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DismissButton, useOverlayPosition } from '@react-aria/overlays';
import { Item, TextField } from '@adobe/react-spectrum';
import { ListBoxBase, useListBoxLayout } from '@react-spectrum/listbox';
import { useListState } from '@react-stately/list';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { FocusScope } from '@react-aria/focus';
import FolderSearch from '@spectrum-icons/workflow/FolderSearch';
import { Popover } from '@react-spectrum/overlays';
import { chain } from '@react-aria/utils';
import { unwrapDOMRef } from '@react-spectrum/utils';
import { useSelectableCollection } from '@react-aria/selection';
import { State } from '@baeso-ui/baeso-core';
import { isObservable } from '@baeso-ui/baeso-observable';
import { MessageDisplay } from '../form/SpectrumForm.types';
import { SpectrumFieldMessage } from '../fieldmessage/SpectrumFieldMessage';
import { useValue } from '../utils/util';
function isVM(props) {
    return props.vm !== undefined;
}
export function SpectrumTextInput(props) {
    const messageOrProperty = isVM(props) ? props.vm.message : props.message;
    const field = props.isProposalTrigger === undefined ? _jsx(SpectrumTextInputPlain, Object.assign({}, props)) : _jsx(SpectrumTextInputWithProposal, Object.assign({}, props));
    if (props.messageDisplay !== MessageDisplay.NONE) {
        return _jsx(SpectrumFieldMessage, Object.assign({ message: messageOrProperty, preallocateHeight: props.messageDisplay !== MessageDisplay.ON_DEMAND }, { children: field }));
    }
    return field;
}
function SpectrumTextInputPlain(props) {
    const [value, setValue] = useValue(isVM(props) ? props.vm.value : props.value);
    const [disabled,] = useValue(isVM(props) ? props.vm.disabled : props.disabled);
    const [editable,] = useValue(isVM(props) ? props.vm.editable : props.editable);
    const [required,] = useValue(isVM(props) ? props.vm.required : props.required);
    const [message,] = useValue(isVM(props) ? props.vm.message : props.message);
    const [text, setText] = useState(value);
    useEffect(() => {
        setText(value);
    }, [value, setText]);
    return _jsx(TextField, { id: props.id, label: props.label && _jsx("span", Object.assign({ style: { overflow: 'hidden', textOverflow: 'ellipsis' } }, { children: props.label })), autoFocus: props.autoFocus, isDisabled: disabled, placeholder: props.placeholder, value: text === undefined ? '' : text, isReadOnly: editable === undefined ? false : !editable, validationState: (message === null || message === void 0 ? void 0 : message.state) === State.ERROR ? 'invalid' : undefined, onChange: setText, onBlur: () => setValue(text), isRequired: required ? true : undefined, width: "100%" });
}
function SpectrumTextInputWithProposal(props) {
    const [value, setValue] = useValue(isVM(props) ? props.vm.value : props.value);
    const [disabled,] = useValue(isVM(props) ? props.vm.disabled : props.disabled);
    const [editable,] = useValue(isVM(props) ? props.vm.editable : props.editable);
    const [required,] = useValue(isVM(props) ? props.vm.required : props.required);
    const [allPropsals, setAllProposals] = useState([]);
    const [filteredProposal, setFilteredProposals] = useState([]);
    const [message,] = useValue(isVM(props) ? props.vm.message : props.message);
    const [isProposalOpen, setProposalOpen] = useState(false);
    const fieldRef = useRef(null);
    const listboxRef = useRef(null);
    const popoverRef = useRef(null);
    const overlayState = useOverlayTriggerState(props);
    useEffect(() => {
        if (isObservable(props.proposal)) {
            setAllProposals(props.proposal.value);
            return props.proposal.onValueChange(setAllProposals);
        }
        else if (Array.isArray(props.proposal)) {
            setAllProposals(props.proposal);
        }
        else if (props.proposal instanceof Function) {
            props.proposal().then(setAllProposals);
        }
        return () => { };
    }, [props.proposal]);
    useEffect(() => {
        setFilteredProposals(allPropsals.filter(p => value === undefined || p.value.startsWith(value)));
    }, [allPropsals, value]);
    const close = () => {
        var _a;
        overlayState.setOpen(false);
        setProposalOpen(false);
        (_a = fieldRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const onBlur = (e) => {
        var _a;
        const target = e.relatedTarget;
        if ((popoverRef === null || popoverRef === void 0 ? void 0 : popoverRef.current) && target !== null && ((_a = unwrapDOMRef(popoverRef).current) === null || _a === void 0 ? void 0 : _a.contains(target))) {
            return;
        }
        if (isProposalOpen) {
            close();
        }
    };
    const [menuWidth, setMenuWidth] = useState(100);
    const listProps = {
        items: filteredProposal,
        children: (item) => {
            return _jsx(Item, { children: item.label }, item.key);
        },
    };
    const state = useListState(listProps);
    const layout = useListBoxLayout(state);
    const select = () => {
        const p = filteredProposal.find(e => e.key === state.selectionManager.focusedKey);
        if (p !== undefined) {
            setValue(p.value);
        }
        close();
    };
    const { collectionProps } = useSelectableCollection({
        selectionManager: state.selectionManager,
        keyboardDelegate: layout,
        disallowTypeAhead: true,
        disallowEmptySelection: true,
        disallowSelectAll: true,
        ref: unwrapDOMRef(fieldRef)
    });
    useEffect(() => {
        if (filteredProposal.length > 0) {
            state.selectionManager.setFocusedKey(filteredProposal[0].key);
        }
    }, [filteredProposal]);
    useLayoutEffect(() => {
        var _a, _b;
        const w = (_b = (_a = fieldRef.current) === null || _a === void 0 ? void 0 : _a.UNSAFE_getDOMNode()) === null || _b === void 0 ? void 0 : _b.offsetWidth;
        setMenuWidth(w === undefined ? 100 : w);
    }, [fieldRef.current]);
    useLayoutEffect(() => {
        const cur = listboxRef.current;
        if (cur) {
            cur.ondblclick = () => select();
        }
    }, [listboxRef.current]);
    const { overlayProps } = useOverlayPosition({
        targetRef: unwrapDOMRef(fieldRef),
        overlayRef: unwrapDOMRef(popoverRef),
        scrollRef: listboxRef,
        placement: 'bottom start',
        shouldFlip: false,
        isOpen: isProposalOpen,
        onClose: close
    });
    const style = Object.assign(Object.assign({}, overlayProps.style), { width: menuWidth });
    const listbox = _jsxs(FocusScope, { children: [_jsx(ListBoxBase, { state: state, autoFocus: "first", shouldUseVirtualFocus: true, shouldSelectOnPressUp: true, focusOnPointerEnter: true, disallowEmptySelection: true, layout: layout, ref: listboxRef }), _jsx(DismissButton, { onDismiss: close })] });
    const overlay = _jsx(Popover, Object.assign({ state: overlayState, UNSAFE_style: style, ref: popoverRef, triggerRef: unwrapDOMRef(fieldRef), placement: 'bottom start', hideArrow: true }, { children: listbox }));
    const onKeyUp = (e) => {
        if (e.key === 'Enter') {
            select();
        }
        else if (e.key === 'Escape') {
            close();
            return;
        }
        if (props.isProposalTrigger && props.isProposalTrigger(e)) {
            setProposalOpen(true);
            overlayState.setOpen(true);
        }
    };
    const icon = _jsx(FolderSearch, {});
    return _jsxs("div", { children: [_jsx(TextField, { id: props.id, label: props.label, icon: icon, ref: fieldRef, autoFocus: props.autoFocus, isDisabled: disabled, placeholder: props.placeholder, value: value === undefined ? '' : value, isReadOnly: editable === undefined ? false : !editable, validationState: (message === null || message === void 0 ? void 0 : message.state) === State.ERROR ? 'invalid' : undefined, onChange: setValue, isRequired: required, onBlur: onBlur, onKeyDown: chain(isProposalOpen && collectionProps.onKeyDownCapture), onKeyUp: chain(isProposalOpen && collectionProps.onKeyUpCapture, onKeyUp), UNSAFE_style: { width: '100%' } }), overlayState.isOpen && overlay] });
}

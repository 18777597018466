import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { ActionButton, Flex, Item, Menu, MenuTrigger, Text, Tooltip, TooltipTrigger, } from '@adobe/react-spectrum';
import ShowMenu from '@spectrum-icons/workflow/ShowMenu';
import { ELEVATION_LEVELS, findIcon } from '../utils/util';
import { SpectrumFlex, SpectrumResponsiveItem } from '../flex/SpectrumFlex';
import './workspace.css';
function isContextAware(props) {
    return props.contextValue !== undefined || props.contextText !== undefined;
}
export function SpectrumWorkspace(props) {
    if (isContextAware(props)) {
        return _createElement(ContextAwareWorksace, Object.assign({}, props, { key: "contextAwwareWorkspace_key" }));
    }
    return _createElement(ContextLessWorkspace, Object.assign({}, props, { key: "contextLessWorkspace_key" }));
}
function ContextAwareWorksace(props) {
    const actionHandler = (v) => {
        const action = props.componentActions.find(a => a.key == v);
        if (action) {
            action.onAction();
        }
    };
    return _jsxs(SpectrumFlex, Object.assign({ backgroundColor: "gray-75", direction: "column", alignContent: "stretch", width: "100%" }, { children: [_jsxs(SpectrumFlex, Object.assign({ boxShadow: ELEVATION_LEVELS.ELEVATION_4, zIndex: 100, minHeight: "size-400", maxHeight: "size-400", backgroundColor: "gray-100", padding: "size-100", direction: "row", gap: "size-300", alignItems: "center", isResponsive: true }, { children: [_jsx(SpectrumResponsiveItem, Object.assign({ widthRange: [0, 1279] }, { children: _jsxs(MenuTrigger, { children: [_jsx(ActionButton, Object.assign({ "aria-label": "Komponenten" }, { children: _jsx(ShowMenu, {}) })), _jsx(Menu, Object.assign({ items: props.componentActions, onAction: actionHandler }, { children: (item) => (_jsxs(Item, { children: [findIcon(item.icon, 'S'), _jsx(Text, { children: item.label })] }, item.key)) }))] }) })), _jsx(SpectrumResponsiveItem, Object.assign({ widthRange: [1280] }, { children: _jsx("span", Object.assign({ style: { fontSize: '1.5em', fontWeight: 'bold' } }, { children: props.title }), "title") })), _jsx("span", Object.assign({ style: { fontSize: '1.5em', fontWeight: 'bold', flexGrow: 1, textAlign: 'center', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' } }, { children: props.contextText }), "context-text")] })), _jsxs(SpectrumFlex, Object.assign({ direction: "row", flexGrow: 1, alignContent: "stretch", UNSAFE_style: { minWidth: '0px', overflow: 'hidden' }, isResponsive: true }, { children: [_jsx(SpectrumResponsiveItem, Object.assign({ widthRange: [1280,] }, { children: _createElement(ComponentActionBar, Object.assign({}, props, { key: "componentActionBar_Key", title: undefined })) })), _jsx(ContentArea, Object.assign({}, props), "content-area")] }))] }));
}
function ContextLessWorkspace(props) {
    return _jsxs(SpectrumFlex, Object.assign({ backgroundColor: "gray-75", direction: "row", gap: "10px", flexGrow: 1, alignContent: "stretch", minWidth: 0 }, { children: [_createElement(ComponentActionBar, Object.assign({}, props, { key: "componentActionBar_key" })), _jsx(ContentArea, Object.assign({}, props))] }));
}
function ComponentActionBar(props) {
    return _jsxs(SpectrumFlex, Object.assign({ backgroundColor: "gray-100", minWidth: "size-800", direction: "column", boxShadow: ELEVATION_LEVELS.ELEVATION_4, padding: "size-100" }, { children: [props.title && _jsx("span", Object.assign({ style: { fontSize: '1.5em', fontWeight: 'bold', textAlign: 'center' } }, { children: props.title })), _jsx(SpectrumFlex, Object.assign({ direction: "column", flexGrow: 1, gap: "size-100", marginTop: "10px", alignItems: "center" }, { children: props.componentActions.map(a => _jsx(ComponentWorkbenchButton, { action: a }, a.key)) })), _jsx(Flex, Object.assign({ direction: "column", gap: "size-100", alignItems: "center" }, { children: props.actions.map(a => _jsx(ComponentWorkbenchButton, { action: a, isQuiet: true }, a.key)) }))] }));
}
function ComponentWorkbenchButton(props) {
    const a = props.action;
    return _jsxs(TooltipTrigger, Object.assign({ delay: 0, placement: "end" }, { children: [_jsx("div", Object.assign({ className: 'workspace-tool-button' + (props.isQuiet ? ' quiet' : '') }, { children: _jsx(ActionButton, Object.assign({ id: a.key, isQuiet: props.isQuiet, "aria-label": a.label, height: "size-600", width: "size-600", onPress: a.onAction }, { children: findIcon(a.icon) }), a.key) })), _jsx(Tooltip, { children: a.label })] }), "tooltipTrigger_key");
}
function ContentArea(props) {
    var _a;
    return _jsx(SpectrumFlex, Object.assign({ flexGrow: 1, minWidth: 0, overflow: `hidden ${(_a = props.scrollY) !== null && _a !== void 0 ? _a : 'hidden'}`, padding: "size-200", alignContent: "stretch" }, { children: _jsx(_Fragment, { children: props.children }) }));
}

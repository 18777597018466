import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import DateInput from '@spectrum-icons/workflow/DateInput';
import { TextField } from '@adobe/react-spectrum';
import { OK_MESSAGE, State } from '@baeso-ui/baeso-core';
import { useDefaultDateFormatter, useDefaultDateInputParser } from './SpectrumDateInput.types';
import { createValue, isMobileDevice, useValue } from '../utils/util';
import { MessageDisplay } from '../form/SpectrumForm.types';
import { SpectrumFieldMessage } from '../fieldmessage/SpectrumFieldMessage';
function isVM(props) {
    return props.vm !== undefined;
}
export function SpectrumDateInput(props) {
    if (isMobileDevice()) {
        return _jsx(MobileSpectrumDateInput, Object.assign({}, props));
    }
    return _jsx(DesktopSpectrumDateInput, Object.assign({}, props));
}
function MobileSpectrumDateInput(props) {
    const [value, setValue] = useValue(isVM(props) ? props.vm.value : props.value);
    const [text, setText] = useState(value);
    const [message,] = useValue(isVM(props) ? props.vm.message : props.message);
    const [disabled,] = useValue(isVM(props) ? props.vm.disabled : props.disabled);
    const [editable,] = useValue(isVM(props) ? props.vm.editable : props.editable);
    const [required,] = useValue(isVM(props) ? props.vm.required : props.required);
    useEffect(() => {
        setText(value);
    }, [value]);
    const onBlur = () => {
        setValue(text);
    };
    const field = _jsx(TextField, { label: props.label, id: props.id, autoFocus: props.autoFocus, type: "date", isDisabled: disabled, validationState: message && message.state === State.ERROR ? 'invalid' : undefined, value: text, isReadOnly: editable === undefined ? false : !editable, onChange: setValue, isRequired: required, onBlur: onBlur, UNSAFE_style: { width: '100%' } });
    if (props.messageDisplay !== MessageDisplay.NONE) {
        return _jsx(SpectrumFieldMessage, Object.assign({ message: message, preallocateHeight: props.messageDisplay !== MessageDisplay.ON_DEMAND }, { children: field }));
    }
    return field;
}
function DesktopSpectrumDateInput(props) {
    const [value, setValue] = useValue(isVM(props) ? props.vm.value : props.value);
    const [text, setText] = useState(value);
    const [message,] = useValue(isVM(props) ? props.vm.message : props.message);
    const [disabled,] = useValue(isVM(props) ? props.vm.disabled : props.disabled);
    const [editable,] = useValue(isVM(props) ? props.vm.editable : props.editable);
    const [required,] = useValue(isVM(props) ? props.vm.required : props.required);
    const [inputErrorMessage, setInputErrorMessage] = createValue('inputError', OK_MESSAGE);
    const computedMessage = useMemo(() => {
        if ((inputErrorMessage === null || inputErrorMessage === void 0 ? void 0 : inputErrorMessage.state) == State.ERROR) {
            return inputErrorMessage;
        }
        return message ? message : OK_MESSAGE;
    }, [message, inputErrorMessage]);
    const dateDefaultFormatter = useDefaultDateFormatter();
    const formatter = props.formatter ? props.formatter : dateDefaultFormatter;
    const defaultParser = useDefaultDateInputParser();
    const inputParser = props.inputParser ? props.inputParser : defaultParser;
    useEffect(() => {
        setText(value !== undefined ? formatter(value) : undefined);
    }, [value, formatter]);
    const updateInputState = (m) => {
        if (inputErrorMessage != m) {
            setInputErrorMessage(m);
            if (props.onInputStateChange) {
                props.onInputStateChange(m);
            }
        }
    };
    const onBlur = () => {
        //console.debug('input text changed, updating value; current text:', text);
        const trimmedText = text !== undefined ? text.trim() : '';
        if (trimmedText === '') {
            updateInputState(OK_MESSAGE);
            setValue(undefined);
        }
        else {
            const parsedDate = inputParser(trimmedText);
            if (parsedDate !== undefined) {
                updateInputState(OK_MESSAGE);
                setValue(parsedDate);
            }
            else {
                updateInputState({ state: State.ERROR, text: 'Ungültiges Datum \'' + trimmedText + '\'' });
            }
        }
    };
    const icon = _jsx(DateInput, {});
    const field = _jsx(TextField, { label: props.label, id: props.id, autoFocus: props.autoFocus, icon: icon, isDisabled: disabled, validationState: computedMessage.state === State.ERROR ? 'invalid' : undefined, value: text === undefined ? '' : text, isReadOnly: editable === undefined ? false : !editable, onChange: setText, onBlur: onBlur, isRequired: required, UNSAFE_style: { width: '100%' } });
    if (props.messageDisplay !== MessageDisplay.NONE) {
        return _jsx(SpectrumFieldMessage, Object.assign({ message: computedMessage, preallocateHeight: props.messageDisplay !== MessageDisplay.ON_DEMAND }, { children: field }));
    }
    return field;
}

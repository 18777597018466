import { Property } from '@baeso-ui/baeso-observable';
import { FormControl } from '../BaseVM';
function isArray(init) {
    return Array.isArray(init);
}
function isArray2(init) {
    return Array.isArray(init);
}
export class ListVM extends FormControl {
    constructor(init) {
        super(isArray(init) ? undefined : init);
        this.items = new Property('items', []);
        this.items.value = isArray(init) ? init : init.items;
    }
}
export class MultiSelectListVM extends FormControl {
    constructor(init) {
        super(isArray2(init) ? undefined : init);
        this.items = new Property('items', []);
        this.items.value = isArray2(init) ? init : init.items;
    }
}

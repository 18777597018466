import { createRoot } from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

import Archive from '@spectrum-icons/workflow/Archive';
import Email from '@spectrum-icons/workflow/Email';
import FolderSearch from '@spectrum-icons/workflow/FolderSearch';
import Home from '@spectrum-icons/workflow/Home';
import Homepage from '@spectrum-icons/workflow/Homepage';
import InfoOutline from '@spectrum-icons/workflow/InfoOutline';
import Mailbox from '@spectrum-icons/workflow/Mailbox';
import RailLeft from '@spectrum-icons/workflow/RailLeft';
import Refresh from '@spectrum-icons/workflow/Refresh';
import Settings from '@spectrum-icons/workflow/Settings';
import TableAndChart from '@spectrum-icons/workflow/TableAndChart';
import TaskList from '@spectrum-icons/workflow/TaskList';
import UploadToCloudOutline from '@spectrum-icons/workflow/UploadToCloudOutline';
import User from '@spectrum-icons/workflow/User';


import {
  faBook,
  faCheckDouble,
  faFile,
  faFileAlt,
  faFileCirclePlus,
  faFileCsv,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  faPlus,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TracingInterceptor } from '@beso-rsd/beso-rsd-tracing';
import { isElectron } from '@baeso-ui/baeso-core';

import { SpectrumFontAwesomeIcon, registerIcon } from '@baeso-ui/baeso-ui-spectrum';

import './styles/fonts.css';
import './styles/theme.css';
import './index.css';

import App from './App';

import * as serviceWorker from './serviceWorker';

registerIcon('fa-home-lg-alt-solid', <Home size="M" />);
registerIcon('fa-file-search', <FolderSearch size="M" />);
registerIcon('fa-tasks', <TaskList size="M" />);
registerIcon('fa-mail-bulk', <Mailbox size="M" />);
registerIcon('fa-analytics', <TableAndChart size="M" />);
registerIcon('fa-archive', <Archive />);
registerIcon('fa-book', <FontAwesomeIcon icon={faBook} size="2x" style={{ verticalAlign: 0 }}></FontAwesomeIcon>);
registerIcon('about-icon', <InfoOutline size="L" />);
registerIcon('pref-icon', <Settings size="L" />);
registerIcon('fa-user', <User />);

registerIcon('home', (size, slot) => <Home size={size} slot={slot} />);
registerIcon('homepage', (size, slot) => <Homepage size={size} slot={slot} />);
registerIcon('archive', (size, slot) => <Archive size={size} slot={slot} />);
registerIcon('user', (size, slot) => <User size={size} slot={slot} />);
registerIcon('refresh', (size, slot) => <Refresh size={size} slot={slot} />);
registerIcon('double-check', (size, slot) => <SpectrumFontAwesomeIcon icon={faCheckDouble} size={size} slot={slot} />);
registerIcon('plus', (size, slot) => <SpectrumFontAwesomeIcon icon={faPlus} size={size} slot={slot} />);
registerIcon('trash', (size, slot) => <SpectrumFontAwesomeIcon icon={faTrash} size={size} slot={slot} />);
registerIcon('show-navigation-left', (size, slot) => <RailLeft size={size} slot={slot} />);
registerIcon('upload', (size, slot) => <UploadToCloudOutline size={size} slot={slot} />);
registerIcon('document-add', (size, slot) => <SpectrumFontAwesomeIcon icon={faFileCirclePlus} size={size} slot={slot} />);
registerIcon('settings', (size, slot) => <Settings size={size} slot={slot} />);

registerIcon('verfahren', (size, slot)  => <SpectrumFontAwesomeIcon icon={faBook} size={size} slot={slot} />);
registerIcon('person', (size, slot)  => <User size={size} slot={slot} />);
registerIcon('nachricht', (size, slot)  => <Email size={size} slot={slot} />);

registerIcon('document-generic', (size, slot) => <SpectrumFontAwesomeIcon icon={faFile} size={size} slot={slot} />);
registerIcon('document-word', (size, slot) => <SpectrumFontAwesomeIcon icon={faFileWord} size={size} slot={slot} />);
registerIcon('document-cell', (size, slot) => <SpectrumFontAwesomeIcon icon={faFileExcel} size={size} slot={slot} />);
registerIcon('document-slide', (size, slot) => <SpectrumFontAwesomeIcon icon={faFilePowerpoint} size={size} slot={slot} />);
registerIcon('document-pdf', (size, slot) => <SpectrumFontAwesomeIcon icon={faFilePdf} size={size} slot={slot} />);
registerIcon('document-text', (size, slot) => <SpectrumFontAwesomeIcon icon={faFileAlt} size={size} slot={slot} />);
registerIcon('document-csv', (size, slot) => <SpectrumFontAwesomeIcon icon={faFileCsv} size={size} slot={slot} />);

// load OnlyOffice API script from Document Server
// loading dynamically (instead of static reference in index.html) to be able to check for an Electron environment and 
// build the appropriate full URL if needed
(() => {
  const script = document.createElement('script');
  let docserverHost;
  if (process.env.REACT_APP_ONLYOFFICE_DOCUMENT_SERVER) {
    docserverHost = process.env.REACT_APP_ONLYOFFICE_DOCUMENT_SERVER;
  } else {
    const baseURL = new URL(isElectron() && process.env.REACT_APP_SERVICE_ELECTRON_BASEURL ? process.env.REACT_APP_SERVICE_ELECTRON_BASEURL : window.location.origin); 
    // append "-onlyoffice" to the subdomain
    const appHostnameParts = baseURL.hostname.split('.');
    appHostnameParts[0] += '-onlyoffice';
    baseURL.hostname = appHostnameParts.join('.');
    docserverHost = baseURL.toString();
  }
  script.src = [ docserverHost, '/web-apps/apps/api/documents/api.js' ].join('');
  script.async = true;
  document.body.appendChild(script);
})();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- 'root' element must exist in the (static) HTML template
createRoot(document.getElementById('root')!).render(<App />);

const trace = new TracingInterceptor( {
  serviceName: 'onacta',
  url: isElectron() ? process.env.REACT_APP_SERVICE_ELECTRON_BASEURL + '/trace/api/v2/spans' : '/trace/api/v2/spans',
});
trace.enable();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
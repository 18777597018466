import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { SpectrumFluidGrid, SpectrumFluidGridCell, SpectrumFluidGridRow } from './SpectrumFluidGrid';
function toFluidRows(children) {
    if (Array.isArray(children)) {
        return children.map((c, idx) => _jsx(SpectrumFluidGridRow, Object.assign({ isHidden: c.props.isHidden }, { children: getCell(c.props.children) }), 'row-' + idx));
    }
    else {
        return _jsx(SpectrumFluidGridRow, Object.assign({ isHidden: children.props.isHidden }, { children: getCell(children.props.children) }), 'row-0');
    }
}
function getCell(children) {
    if (Array.isArray(children)) {
        return children.map((c, idx) => mapRowChild(c, 'cell-' + idx));
    }
    else {
        return mapRowChild(children, 'cell-0');
    }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mapRowChild(e, key) {
    const xxs = e.props.xxs || 12;
    const xs = e.props.xs || xxs;
    const s = e.props.sm || xs;
    const m = e.props.md || s;
    const l = e.props.lg || m;
    const xl = e.props.xl || l;
    const alignItems = e.props.alignItems;
    const isHidden = e.props.isCellHidden;
    if (e.type._typeName === 'SpectrumContentGridCell') {
        return _jsx(SpectrumFluidGridCell, Object.assign({ alignItems: alignItems, isHidden: isHidden, cellConfig: { 'XXS': xxs, 'XS': xs, 'S': s, 'M': m, 'L': l, 'XL': xl } }, { children: e.props.children }), key);
    }
    return _jsx(SpectrumFluidGridCell, Object.assign({ alignItems: alignItems, isHidden: isHidden, cellConfig: { 'XXS': xxs, 'XS': xs, 'S': s, 'M': m, 'L': l, 'XL': xl } }, { children: e }), key);
}
export function SpectrumContentGrid(props) {
    return _jsx(SpectrumFluidGrid, Object.assign({}, props, { breakPoints: { 'XXS': 300, 'XS': 400, 'S': 560, 'M': 760, 'L': 990, 'XL': 1200 }, rowGap: "size-50", colGap: "size-100" }, { children: toFluidRows(props.children) }));
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function SpectrumContentGridRow(_props) {
    return _jsx(_Fragment, {});
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function SpectrumContentGridCell(_props) {
    return _jsx(_Fragment, {});
}
SpectrumContentGridCell._typeName = 'SpectrumContentGridCell';

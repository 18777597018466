import { insert, remove, removeAt } from '@baeso-ui/baeso-core';
import { AbstractObservable } from './Observable';
export class ObservableArray extends AbstractObservable {
    constructor(name, arr) {
        super(name);
        this.listChangeListener = [];
        this.valueListeners = [];
        this._arr = arr;
    }
    onListChange(listener) {
        this.listChangeListener.push(listener);
        return () => {
            remove(this.listChangeListener, listener);
        };
    }
    get value() {
        return this._arr;
    }
    onValueChange(listener) {
        this.valueListeners.push(listener);
        return () => {
            remove(this.valueListeners, listener);
        };
    }
    itemAt(idx) {
        return this._arr[idx];
    }
    createValueChangeCopy() {
        if (this.valueListeners.length > 0) {
            return [...this._arr];
        }
        return this._arr;
    }
    add(v, index) {
        const copy = this.createValueChangeCopy();
        if (index !== undefined) {
            if (index === 0) {
                this._arr.unshift(v);
            }
            else if (index === this._arr.length) {
                this._arr.push(v);
            }
            else {
                insert(this._arr, index, v);
            }
        }
        else {
            index = this._arr.length;
            this._arr.push(v);
        }
        this.fireListChange({ index, addedElements: [v], removedElements: [] });
        this.fireValueChange(copy);
    }
    remove(v) {
        const idx = this._arr.indexOf(v);
        if (idx >= 0) {
            return this.removeAt(idx);
        }
        return false;
    }
    removeAt(index) {
        const copy = this.createValueChangeCopy();
        const v = this._arr[index];
        const rv = removeAt(this._arr, index);
        if (rv) {
            this.fireListChange({ index, addedElements: [], removedElements: [v] });
            this.fireValueChange(copy);
        }
        return rv;
    }
    setAll(v) {
        this.setAll_([...v]);
    }
    push(...items) {
        const copy = this.createValueChangeCopy();
        const index = this._arr.length;
        const rv = this._arr.push(...items);
        this.fireListChange({ index, addedElements: items, removedElements: [] });
        this.fireValueChange(copy);
        return rv;
    }
    shift() {
        const copy = this.createValueChangeCopy();
        const rv = this._arr.shift();
        if (rv !== undefined) {
            this.fireListChange({ index: 0, addedElements: [], removedElements: [rv] });
            this.fireValueChange(copy);
        }
        return rv;
    }
    unshift(...items) {
        const copy = this.createValueChangeCopy();
        const rv = this._arr.unshift(...items);
        this.fireListChange({ index: 0, addedElements: items, removedElements: [] });
        this.fireValueChange(copy);
        return rv;
    }
    pop() {
        const copy = this.createValueChangeCopy();
        const rv = this._arr.pop();
        if (rv !== undefined) {
            this.fireListChange({ index: this._arr.length, addedElements: [], removedElements: [rv] });
            this.fireValueChange(copy);
        }
        return rv;
    }
    filter(callbackfn, thisArg) {
        return this._arr.filter(callbackfn, thisArg);
    }
    indexOf(searchElement, fromIndex) {
        return this._arr.indexOf(searchElement, fromIndex);
    }
    findIndex(predicate, thisArg) {
        return this._arr.findIndex(predicate, thisArg);
    }
    clear() {
        const old = this._arr;
        this._arr = [];
        if (old.length > 0) {
            this.fireListChange({ index: 0, addedElements: [], removedElements: old });
            this.fireValueChange(old);
        }
    }
    get arr() {
        return this._arr;
    }
    get length() {
        return this._arr.length;
    }
    get empty() {
        return this._arr.length === 0;
    }
    forEach(callbackfn, thisArg) {
        this._arr.forEach(callbackfn, thisArg);
    }
    map(callbackfn, thisArg) {
        return this._arr.map(callbackfn, thisArg);
    }
    join(separator) {
        return this._arr.join(separator);
    }
    lastIndexOf(searchElement, fromIndex) {
        return this._arr.lastIndexOf(searchElement, fromIndex);
    }
    setAll_(v) {
        const old = this._arr;
        this._arr = v;
        this.fireListChange({ index: 0, addedElements: this._arr, removedElements: old });
        this.fireValueChange(old);
    }
    get arr_() {
        return this._arr;
    }
    unsafeReadonly() {
        return this;
    }
    unsafe() {
        return this;
    }
    fireListChange(...changes) {
        this.fireChange();
        const listener = [...this.listChangeListener];
        listener.forEach(l => l(changes, this.name));
    }
    fireValueChange(oldVal) {
        const listeners = [...this.valueListeners];
        listeners.forEach(l => l(this._arr, oldVal, this.name));
    }
}

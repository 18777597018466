function remove(arr, e) {
    return removeAt(arr, arr.indexOf(e));
}
function removeAt(arr, idx) {
    if (idx >= 0 && idx < arr.length) {
        arr.splice(idx, 1);
        return true;
    }
    return false;
}
export class InterceptedServiceSupport {
    constructor() {
        this.interceptors = [];
    }
    registerCallInterceptor(interceptor) {
        this.interceptors.push(interceptor);
        return () => { remove(this.interceptors, interceptor); };
    }
    call(name, method, headers) {
        const callContext = new CallContext(name);
        this.interceptors.forEach(i => i.preinvokation(callContext));
        const modHeaders = Object.assign(Object.assign({}, headers), callContext.properties);
        return method(modHeaders)
            .then(val => {
            const resultContext = new ResultContext(name, callContext.state, val, undefined);
            this.interceptors.forEach(i => i.postinvokation(resultContext));
            return val;
        })
            .catch(error => {
            const resultContext = new ResultContext(name, callContext.state, undefined, error);
            this.interceptors.forEach(i => i.postinvokation(resultContext));
            throw error;
        });
    }
    get(name, client, url, parameters, headers, responseType) {
        return this.call(name, modHeaders => client.get(url, parameters, modHeaders, responseType), headers);
    }
    post(name, client, url, parameters, headers, responseType) {
        return this.call(name, modHeaders => client.post(url, parameters, modHeaders, responseType), headers);
    }
    put(name, client, url, parameters, headers, responseType) {
        return this.call(name, modHeaders => client.put(url, parameters, modHeaders, responseType), headers);
    }
    patch(name, client, url, parameters, headers, responseType) {
        return this.call(name, modHeaders => client.patch(url, parameters, modHeaders, responseType), headers);
    }
    delete(name, client, url, parameters, headers, responseType) {
        return this.call(name, modHeaders => client.delete(url, parameters, modHeaders, responseType), headers);
    }
}
class CallContext {
    constructor(methodName) {
        this.state = {};
        this.properties = {};
        this.methodName = methodName;
    }
    setState(name, value) {
        this.state[name] = value;
    }
    setProperty(name, value) {
        this.properties[name] = value;
    }
}
class ResultContext {
    constructor(methodName, state, rv, error) {
        this.methodName = methodName;
        this.state = state;
        this.rv = rv;
        this.error = error;
    }
    getState(name) {
        return this.state[name];
    }
    getError() {
        return this.error;
    }
    getResult() {
        return this.rv;
    }
}
function isObject(value) {
    return typeof value === 'object' && value != null && !Array.isArray(value);
}
export function isIRef(value) {
    return isObject(value)
        && 'idRef' in value
        && typeof value.idRef === 'string';
}
export const INTERCEPTED_SERVICE_SUPPORT = new InterceptedServiceSupport();

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Item, Picker, Section, Text } from '@adobe/react-spectrum';
import { MessageDisplay } from '../form/SpectrumForm.types';
import { SpectrumFieldMessage } from '../fieldmessage/SpectrumFieldMessage';
import { isObservable } from '@baeso-ui/baeso-observable';
import { messages } from '../Messages';
import { useTypedMessageFormatter } from '../utils/util';
import { useValue } from '../utils/util';
function isVM(props) {
    return props.vm !== undefined;
}
export function SpectrumDropDown(props) {
    const { itemToOption: itemsToOptionsProp, clearSelectionItem: clearSelectionItemProp, } = props;
    const m = useTypedMessageFormatter(messages);
    const defaultClearSelectionItem = useMemo(() => {
        return {
            key: 'selection-empty',
            text: m('SpectrumDropDown.clearSelectionItem.text'),
            description: m('SpectrumDropDown.clearSelectionItem.description'),
            data: undefined
        };
    }, [m]);
    const messageOrProperty = isVM(props) ? props.vm.message : props.message;
    const itemsProperty = isVM(props) ? props.vm.items : props.items;
    const valueProperty = isVM(props) ? props.vm.value : props.value;
    const [required,] = useValue(isVM(props) ? props.vm.required : props.required);
    const [disabled,] = useValue(isVM(props) ? props.vm.disabled : props.disabled);
    const [value, setValue] = useValue(valueProperty);
    const clearSelectionItem = useMemo(() => {
        if (!(required !== null && required !== void 0 ? required : false) && clearSelectionItemProp !== null) {
            return clearSelectionItemProp !== null && clearSelectionItemProp !== void 0 ? clearSelectionItemProp : defaultClearSelectionItem;
        }
        else {
            return undefined;
        }
    }, [required, clearSelectionItemProp, defaultClearSelectionItem]);
    const itemsToOptions = useCallback((items) => {
        const options = items.map(op => {
            const rv = itemsToOptionsProp(op);
            rv.data = op;
            return rv;
        });
        if (clearSelectionItem) {
            options.unshift(clearSelectionItem);
        }
        return options;
    }, [clearSelectionItem, itemsToOptionsProp]);
    const [options, setOptions] = useState(itemsToOptions(isObservable(itemsProperty) ? itemsProperty.value : itemsProperty));
    useEffect(() => {
        if (isObservable(itemsProperty)) {
            setOptions(itemsToOptions(itemsProperty.value));
            return itemsProperty.onValueChange(v => setOptions(itemsToOptions(v)));
        }
        else {
            setOptions(itemsToOptions(itemsProperty));
            return undefined;
        }
    }, [itemsProperty, itemsToOptions]);
    const computer = props.sectionComputer;
    const sections = useMemo(() => {
        if (computer) {
            const m = new Map();
            const rv = [];
            options.forEach(e => {
                var _a;
                const sectionKey = computer(e.data);
                if (m.has(sectionKey)) {
                    (_a = m.get(sectionKey)) === null || _a === void 0 ? void 0 : _a.items.push(e);
                }
                else {
                    const section = {
                        key: sectionKey,
                        items: [e]
                    };
                    rv.push(section);
                    m.set(sectionKey, section);
                }
            });
            return rv;
        }
        else {
            return [];
        }
    }, [computer, options]);
    const onChange = (val) => {
        const item = options.filter(op => op.key === val).map(op => op.data)[0];
        setValue(item);
    };
    const selectedKey = value ? props.itemToOption(value).key : clearSelectionItem === null || clearSelectionItem === void 0 ? void 0 : clearSelectionItem.key;
    const field = computer ?
        _jsx(Picker, Object.assign({ onSelectionChange: onChange, id: props.id, selectedKey: selectedKey, items: sections, placeholder: props.placeholder, label: props.label && _jsx("span", Object.assign({ style: { overflow: 'hidden', textOverflow: 'ellipsis' } }, { children: props.label })), isRequired: required ? true : undefined, isDisabled: disabled, width: "100%" }, { children: (section) => (_jsx(Section, Object.assign({ title: section.key, items: section.items }, { children: item => _jsxs(Item, Object.assign({ textValue: item.text }, { children: [item.icon, _jsx(Text, { children: item.text }), item.description && _jsx(Text, Object.assign({ slot: 'description' }, { children: item.description }))] }), item.key) }), section.key)) }))
        : _jsx(Picker, Object.assign({ onSelectionChange: onChange, id: props.id, selectedKey: selectedKey, items: options, placeholder: props.placeholder, label: props.label && _jsx("span", Object.assign({ style: { overflow: 'hidden', textOverflow: 'ellipsis' } }, { children: props.label })), isRequired: required ? true : undefined, isDisabled: disabled, width: "100%" }, { children: item => _jsxs(Item, Object.assign({ textValue: item.text }, { children: [item.icon, _jsx(Text, { children: item.text }), item.description && _jsx(Text, Object.assign({ slot: 'description' }, { children: item.description }))] }), item.key) }));
    if (props.messageDisplay !== MessageDisplay.NONE) {
        return _jsx(SpectrumFieldMessage, Object.assign({ message: messageOrProperty, preallocateHeight: props.messageDisplay !== MessageDisplay.ON_DEMAND }, { children: field }));
    }
    return field;
}

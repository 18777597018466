import { Suspense, lazy, useCallback, useEffect, useState } from 'react';

import { Route, Routes, useNavigate, useParams } from 'react-router-dom';

import data from '../../package.json';

import moment from 'moment';

import 'moment/locale/de';


import { AlertDialog, DialogContainer, Provider, darkTheme, defaultTheme, lightTheme, } from '@adobe/react-spectrum';
import { Theme } from '@react-types/provider';
import { useMessageFormatter } from '@react-aria/i18n';

import { faBalanceScale, faExclamationSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Action, SpectrumFlex, useDateFormatter, useValue } from '@baeso-ui/baeso-ui-spectrum';
import { ServiceRegistry, isElectron, setDateFormatter } from '@baeso-ui/baeso-core';
import { AxiosRestClient } from '@beso-rsd/beso-rsd-axios';
import { ServiceContext } from '@baeso-ui/baeso-ui-spectrum';
import { SpectrumWorkspace as Workspace } from '@baeso-ui/baeso-ui-spectrum';

import { ScaleType, ThemeType, VisionDeficiencyEmulation, preferenceStore } from '@onacta/onacta-base-ui';

import { PDFDocumentServiceREST } from '@baeso-pdfviewer/pdfviewer-services';

import { BeteiligtePersonenViewServicesREST, VerfahrenAnlageViewServiceREST, VerfahrenDashboardViewServiceREST, VerfahrenSucheViewServiceREST, VerfahrenViewService, VerfahrenViewServiceREST } from '@onacta/onacta-verfahren-services';
const VerfahrenAnlageView = lazy(() => import('./wrappers/VerfahrenAnlageView'));
const VerfahrenDashboardView = lazy(() => import('./wrappers/VerfahrenDashboardView'));
const VerfahrensBeteiligteView = lazy(() => import('./wrappers/VerfahrensBeteiligteView'));
const VerfahrenSuchView = lazy(() => import('./wrappers/VerfahrenSucheView'));

import { DashboardViewServiceREST } from '@onacta/onacta-home-services';
const EAktenView = lazy(() => import('./wrappers/EAktenView'));
const HomeView = lazy(() => import('./wrappers/HomeView'));
const NachrichtenView = lazy(() => import('./wrappers/NachrichtenView'));

import { EAktenViewService, EAktenViewServiceREST, VeraktenViewServiceREST } from '@onacta/onacta-eakten-services';
import { NachrichtenViewServiceREST } from '@onacta/onacta-nachrichten-services';

import { AufgabenViewServiceREST, SourceReferenceType } from '@onacta/onacta-aufgaben-services';
const AufgabenView = lazy(() => import('./wrappers/AufgabenView'));

import { AufgabenCreationComponentFactoryImpl } from './adapters/Aufgaben';

import { LoginPage, RequireAuth } from './Auth';
import { EinstellungsDialog } from './EinstellungsDialog';
import { messages } from '../Messages';

import logo from '../img/logo_128.png';

const BASE_URL = isElectron() ? process.env.REACT_APP_SERVICE_ELECTRON_BASEURL : '';
const s = new ServiceRegistry();

moment.locale('de');

if( process.env.REACT_APP_SERVICE_TYPE === 'mock' ) {
  console.log('We are in mock ...');
  /* eslint-disable @typescript-eslint/no-var-requires */
  const nachrichten = require('@onacta/onacta-nachrichten-services-mock');
  const dashboard = require('@onacta/onacta-home-services-mock');
  const verfahren = require('@onacta/onacta-verfahren-services-mock');
  const eakten = require('@onacta/onacta-eakten-services-mock');
  const aufgaben = require('@onacta/onacta-aufgaben-services-mock');
  /* eslint-enable @typescript-eslint/no-var-requires */

  s.register('@onacta/onacta-nachrichten-services/NachrichtenViewService', () => new nachrichten.MockNachrichtenViewService() );
  s.register('@onacta/onacta-home-services/DashboardViewService', () => new dashboard.MockDashboardViewService());
  s.register('@onacta/onacta-verfahren-services/VerfahrenDashboardViewService', () => new verfahren.MockVerfahrenDashboardViewService());
  s.register('@onacta/onacta-verfahren-services/VerfahrenAnlageViewService', () => new verfahren.MockVerfahrenAnlageService());
  s.register('@onacta/onacta-verfahren-services/VerfahrenSucheViewService', () => new verfahren.MockVerfahrenSucheService());
  s.register('@onacta/onacta-eakten-services/EAktenViewService', () => new eakten.MockEAktenViewService());
  s.register('@onacta/onacta-eakten-services/VeraktenViewService', () => new eakten.MockVeraktenViewService());
  s.register('@baeso-pdfviewer/pdfviewer-services/PDFDocumentService', () => new PDFDocumentServiceREST(BASE_URL + '/api/v1/pdf', new AxiosRestClient()));
  s.register('@onacta/onacta-verfahren-services/VerfahrenBeteiligteViewService', () => new verfahren.MockVerfahrenBeteiligteService());
  s.register('@onacta/onacta-aufgaben-services/AufgabenViewService', () => new aufgaben.MockAufgabenViewService());
} else {
  s.register('@onacta/onacta-nachrichten-services/NachrichtenViewService', () => new NachrichtenViewServiceREST(BASE_URL+'/api/v1/nachrichten', new AxiosRestClient()) );
  s.register('@onacta/onacta-home-services/DashboardViewService', () => new DashboardViewServiceREST(BASE_URL+'/api/v1/dashboard', new AxiosRestClient()) );
  s.register('@onacta/onacta-verfahren-services/VerfahrenViewService', () => new VerfahrenViewServiceREST(BASE_URL+'/api/v1/verfahren', new AxiosRestClient()) );
  s.register('@onacta/onacta-verfahren-services/VerfahrenDashboardViewService', () => new VerfahrenDashboardViewServiceREST(BASE_URL+'/api/v1/verfahren', new AxiosRestClient()) );
  s.register('@onacta/onacta-verfahren-services/VerfahrenAnlageViewService', () => new VerfahrenAnlageViewServiceREST(BASE_URL+'/api/v1/verfahren', new AxiosRestClient()) );
  s.register('@onacta/onacta-eakten-services/EAktenViewService', () => new EAktenViewServiceREST(BASE_URL+'/api/v1/eakten', new AxiosRestClient()));
  s.register('@onacta/onacta-eakten-services/VeraktenViewService', () => new VeraktenViewServiceREST(BASE_URL+'/api/v1/eakten', new AxiosRestClient()));
  s.register('@baeso-pdfviewer/pdfviewer-services/PDFDocumentService', () => new PDFDocumentServiceREST(BASE_URL + '/api/v1/pdf', new AxiosRestClient()));
  s.register('@onacta/onacta-verfahren-services/VerfahrenBeteiligteViewService', () => new BeteiligtePersonenViewServicesREST(BASE_URL+'/api/v1/verfahren', new AxiosRestClient()));
  s.register('@onacta/onacta-aufgaben-services/AufgabenViewService', () => new AufgabenViewServiceREST(BASE_URL+'/api/v1/aufgaben', new AxiosRestClient()));
  s.register('@onacta/onacta-verfahren-services/VerfahrenSucheViewService', () => new VerfahrenSucheViewServiceREST(BASE_URL+'/api/v1/verfahren', new AxiosRestClient()));
}

interface ColorTheme {
  key: ThemeType,
  theme: Theme,
  colorScheme: 'light' | 'dark' | undefined
}

interface Scale {
  key: ScaleType,
  scale: 'medium' | 'large' | undefined
}

export const COLOR_THEMES : ColorTheme[] = [
  { key: 'standard', theme: defaultTheme, colorScheme: undefined },
  { key: 'light', theme: defaultTheme, colorScheme: 'light' },
  { key: 'extra-light', theme: lightTheme, colorScheme: 'light' },
  { key: 'dark', theme: darkTheme, colorScheme: 'light' },
  { key: 'extra-dark', theme: darkTheme, colorScheme: 'dark' },
];

export const SCALES : Scale[] = [
  { key: 'standard', scale: undefined },
  { key: 'normal', scale: 'medium' },
  { key: 'large', scale: 'large' },
];

function toTheme(theme?: ThemeType) {
  return COLOR_THEMES.find( t => t.key == theme ) ?? COLOR_THEMES[0];
}

function toScale(scale?: ScaleType) {
  return SCALES.find( s => s.key == scale ) ?? SCALES[0];
}

export function OnActa() : JSX.Element {
  const [ theme ] = useValue(preferenceStore.theme);
  const [ scale ] = useValue(preferenceStore.scale);

  const spectrumTheme = toTheme(theme);
  const spectrumScale = toScale(scale);

  const dateFormatter = useDateFormatter();
  setDateFormatter( dateFormatter );

  return <Provider theme={spectrumTheme?.theme || defaultTheme} colorScheme={spectrumTheme?.colorScheme} scale={spectrumScale?.scale} flexGrow={1} UNSAFE_style={{ display: 'flex', minWidth: 0 }}>
    <ServiceContext.Provider value={s}>
      <VisionDeficiencyEmulation>
        <Routes>
          <Route path="/*" element={<RequireAuth />}>
            <Route path="*" element={<HauptFenster />} />
            <Route path="verfahren/:verfahrenId/*" element={<VerfahrenFenster />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </VisionDeficiencyEmulation>
    </ServiceContext.Provider>
  </Provider>;
}

function HauptFenster() {
  const m = useMessageFormatter(messages);
  const navigate = useNavigate();
  const [ isShowAbout, setShowAbout ] = useState(false);
  const [ isShowPrefs, setShowPrefs ] = useState(false);

  const componentActions : Action[] = [
    {
      key: 'main-page',
      icon: 'home',
      label: m('onacta-main.mainpage'),
      onAction: () => navigate('./'),
    },
    {
      key: 'search-page',
      icon: 'fa-file-search',
      label: m('onacta-main.search-page'),
      onAction: () => navigate('./suche')
    },
    {
      key: 'tasks-page',
      icon: 'fa-tasks',
      label: m('onacta-main.tasks'),
      onAction: () => navigate('./aufgaben'),
    },
    {
      key: 'mail-page',
      icon: 'fa-mail-bulk',
      label: m('onacta-main.mailbox'),
      onAction: () => navigate('./nachrichten'),
    },
    {
      key: ' analytics-page',
      icon: 'fa-analytics',
      label: m('onacta-main.analytics'),
      onAction: () => { console.log('Open Stats page'); }
    }
  ];

  const actions : Action[] = [
    {
      key: 'app-prefs',
      icon: 'pref-icon',
      label: m('onacta-main.gobal-preferences'),
      onAction: () => { setShowPrefs(true); }
    },
    {
      key: 'app-info',
      icon: 'about-icon',
      label: m('onacta-main.app-info'),
      onAction: () => { setShowAbout(true); }
    },
  ];

  return <>
    <Workspace actions={actions} componentActions={componentActions} title="OnActa">
      <Suspense fallback={<LoadingScreen message='Lade Komponente' />}>
        <Routes>
          <Route index element={<HomeView serviceRegistry={s} />} />
          <Route path="aufgaben/*" element={<AufgabenView serviceRegistry={s} />} />
          <Route path="suche/*" element={<VerfahrenSuchView serviceRegistry={s} />} />
          <Route path="nachrichten/*" element={<NachrichtenView serviceRegistry={s} newCaseDialogFactory={ (close, nachrichtenId) => <VerfahrenAnlageView serviceRegistry={s} closeHandler={close} nachrichtenId={nachrichtenId}/>} />} />
        </Routes>
      </Suspense>
    </Workspace>
    <DialogContainer onDismiss={ () => setShowAbout(false)}>
      { isShowAbout && <AlertDialog width={600} autoFocusButton="primary" variant="information" primaryActionLabel="OK" title="OnActa - Die Online Akte">
        <SpectrumFlex gap={20} marginStart={10}>
          <img alt="OnActa Logo" src={logo} width={128} height={128} style={{ alignSelf: 'start' }} />
          <div>
            <strong>Version:</strong><br /> {data.version}<br />
            <strong>Build:</strong><br /> {process.env.REACT_APP_BUILD_TIMESTAMP}<br />
            <strong>Hash:</strong><br /> {process.env.REACT_APP_BUILD_HASH}<br />
          </div>
        </SpectrumFlex>
      </AlertDialog> }
    </DialogContainer>
    <DialogContainer onDismiss={ () => setShowPrefs(false) }>
      { isShowPrefs && <EinstellungsDialog dismissHandler={ () => setShowPrefs(false) } /> }
    </DialogContainer>
  </>;
}

function VerfahrenFenster() {
  const m = useMessageFormatter(messages);
  const navigate = useNavigate();
  const { verfahrenId } = useParams();
  const [ contextTitle, setContextTitle ] = useState<string>();
  const [ contextValue, setContextValue ] = useState<string>();
  const [ errorText, setErrorText ] = useState<string>();
  const [ aktenId, setAktenId ] = useState<string>();

  if (verfahrenId === undefined) {
    throw new Error('verfahrenId is undefined');
  }

  const createEaktenView = useCallback((id?: string) => {
    if (id) {
      const aufgabenCreationComponentFactory = new AufgabenCreationComponentFactoryImpl(s, verfahrenId);
      return <EAktenView serviceRegistry={s} aktenId={id} aufgabenCreationComponentFactory={aufgabenCreationComponentFactory}></EAktenView>;
    } else {
      console.error('aktenId is undefined');
      return <></>;
    }
  }, [ verfahrenId ]);

  const actions : Action[] = [
    {
      key: 'lawsuite-page',
      icon: 'homepage',
      label: m('onacta-main.lawsuit'),
      onAction: () => navigate('./'),
    },
    {
      key: 'eakte-page',
      icon: 'archive',
      label: m('onacta-main.file'),
      onAction: () => navigate('./akte'),
    },
    {
      key: 'involved-parties-page',
      icon: 'user',
      label: m('onacta-main.involved-parties'),
      onAction: () => navigate('./beteiligte'),
    },
    {
      key: 'tasks-page',
      icon: 'fa-tasks',
      label: m('onacta-main.tasks'),
      onAction: () => navigate('./aufgaben'),
    },
  ];

  useEffect( () => {
    const eaktenViewService: EAktenViewService = s.create('@onacta/onacta-eakten-services/EAktenViewService');
    const verfahrenViewService: VerfahrenViewService = s.create('@onacta/onacta-verfahren-services/VerfahrenViewService');

    const verfahrenPromise = verfahrenViewService.getVerfahrenDetails(verfahrenId);
    const aktenIdPromise = eaktenViewService.getAkteForVerfahren(verfahrenId);
    Promise.all([ verfahrenPromise, aktenIdPromise ])
      .then(([ verfahren, aktenId ]) => {
        setAktenId(aktenId);

        setContextTitle(verfahren.kurzrubrum || '');
        setContextValue(verfahren.aktenzeichen || '');
        setErrorText(undefined);
      }, (error) => {
        console.log(error);
        setErrorText(error.message);
      });
  }, [ verfahrenId, createEaktenView ]);

  useEffect(() => {
    const previousTitle = window.document.title;
    if (contextValue && contextTitle) {
      window.document.title = `OnActa - Akte ${contextValue} - ${contextTitle}`;
      return () => { window.document.title = previousTitle; };
    } else {
      return () => { /* no changes, thus no cleanup needed */ };
    }
  }, [ contextValue, contextTitle ]);

  return <>
    { contextValue !== undefined &&
      <Workspace actions={[]} componentActions={actions} title="OnActa" contextText={contextValue + ' - ' + contextTitle} contextValue={contextValue}>
        <Suspense fallback={<LoadingScreen message='Lade Komponente' />}>
          <Routes>
            <Route index element={<VerfahrenDashboardView serviceRegistry={s} verfahrenId={verfahrenId} />} />
            <Route path="akte/*" element={createEaktenView(aktenId)} />
            <Route path="aufgaben/*" element={<AufgabenView serviceRegistry={s} restrictToSource={{ refType: SourceReferenceType.VERFAHREN, refId: verfahrenId }} />} />
            <Route path="beteiligte/*" element={<VerfahrensBeteiligteView serviceRegistry={s} verfahrenId={verfahrenId} />} />
          </Routes>
        </Suspense>
      </Workspace> }
    { contextValue === undefined && errorText === undefined &&
      <LoadingScreen message='Initialisierung des Verfahrens' />
    }
    { errorText &&
      <SpectrumFlex direction="row" columnGap={20} alignItems="center" justifyContent="center" width="100%">
        <FontAwesomeIcon icon={faExclamationSquare} size="6x" color="var(--spectrum-global-color-static-red-600)" />
        <h1 style={{ color: 'var(--spectrum-global-color-static-red-600)' }}>{errorText}</h1>
      </SpectrumFlex>
    }
  </>;
}

function LoadingScreen(props: { message: string }): JSX.Element {
  return <SpectrumFlex direction="column" alignItems="center" justifyContent="center" width="100%" style={{ opacity: 0.5, animation: 'loading 2s infinite' }}>
    <FontAwesomeIcon icon={faBalanceScale} size="6x" />
    <h1>{ props.message } ...</h1>
  </SpectrumFlex>;
}

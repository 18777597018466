var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ListView } from '@adobe/react-spectrum';
import { useValue } from '../utils/util';
function isReadonlyObservableList(observable) {
    return 'onListChange' in observable;
}
function isVM(props) {
    return 'vm' in props;
}
function getItemWrappers(observable, optionToKey) {
    if (isReadonlyObservableList(observable)) {
        return observable.arr.map(e => { return { key: optionToKey(e), entity: e }; });
    }
    else {
        return observable.value.map(e => { return { key: optionToKey(e), entity: e }; });
    }
}
export function SpectrumListView(props) {
    return _jsx(SpectrumSingleListView, Object.assign({}, props));
}
function SpectrumSingleListView(props) {
    const _a = Object.assign(Object.assign({}, props), { items: isVM(props) ? props.vm.items : props.items, value: isVM(props) ? props.vm.value : ('value' in props ? props.value : undefined) }), { label, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- label currently is always hidden
    labelHidden, items: itemsProperty, value: valueProperty, isLoading: isLoadingProperty, optionToItem, optionToKey } = _a, forwardProps = __rest(_a, ["label", "labelHidden", "items", "value", "isLoading", "optionToItem", "optionToKey"]);
    const [isLoading] = useValue(isLoadingProperty);
    const [value, setValue] = useValue(valueProperty);
    const [items, setItems] = useState(() => getItemWrappers(itemsProperty, optionToKey));
    useEffect(() => {
        setItems(getItemWrappers(itemsProperty, optionToKey));
        if (isReadonlyObservableList(itemsProperty)) {
            return itemsProperty.onListChange(() => setItems(getItemWrappers(itemsProperty, optionToKey)));
        }
        else {
            return itemsProperty.onValueChange(() => setItems(getItemWrappers(itemsProperty, optionToKey)));
        }
    }, [itemsProperty, optionToKey]);
    const selectionChangeHandler = useCallback((keys) => {
        var _a;
        if (keys === 'all') {
            console.error('received unexpected "all" selection value on single select list');
        }
        else if (keys.size === 0) {
            setValue(undefined);
        }
        else {
            const selectedKey = keys.values().next().value;
            setValue((_a = items.find(i => i.key === selectedKey)) === null || _a === void 0 ? void 0 : _a.entity);
        }
    }, [items, setValue]);
    const selectedKeys = useMemo(() => value !== undefined ? [optionToKey(value)] : [], [value, optionToKey]);
    const itemRenderer = useCallback((item) => {
        return optionToItem(item.entity, value !== undefined ? (optionToKey(value) === item.key) : false);
    }, [optionToItem, optionToKey, value]);
    const renderedItems = useMemo(() => items.map(itemRenderer), [itemRenderer, items]);
    return _jsx(ListView, Object.assign({ loadingState: (isLoading !== null && isLoading !== void 0 ? isLoading : false) ? 'loading' : 'idle', items: items, selectedKeys: selectedKeys, onSelectionChange: valueProperty !== undefined ? selectionChangeHandler : undefined, selectionMode: valueProperty !== undefined ? 'single' : 'none', selectionStyle: 'highlight', disallowEmptySelection: true, minWidth: 'size-300', maxWidth: 'size-6000', "aria-label": label }, forwardProps, { children: renderedItems }));
}

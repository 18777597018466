import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { ActionButton, View } from '@adobe/react-spectrum';
import { faChevronDown, faChevronUp, } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ELEVATION_LEVELS } from '../utils/util';
import { SpectrumFlex } from '../flex/SpectrumFlex';
import { SpectrumView } from '../view/SpectrumView';
let GROUP_PANEL_COUNTER = 0;
export function SpectrumGroupPane(props) {
    const { contentPadding, contentPaddingTop = 'size-125', contentPaddingBottom = 'size-125', contentPaddingStart = 'size-125', contentPaddingEnd = 'size-125' } = props;
    const { isCollapsible, title } = props;
    const [expanded, setExpanded] = useState(true);
    const [key] = useState(() => GROUP_PANEL_COUNTER++);
    const panelKey = 'group_panel_' + key;
    const contentKey = panelKey + '_content';
    const labelKey = panelKey + '_label';
    let header;
    // Revist:
    // * https://opensource.adobe.com/spectrum-css/accordion.html
    // * https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html
    if (isCollapsible) {
        header = _jsx(ActionButton, Object.assign({ id: labelKey, "aria-expanded": expanded, flexGrow: 1, "aria-controls": contentKey, isQuiet: true, onPress: () => setExpanded(!expanded) }, { children: _jsxs(SpectrumFlex, Object.assign({ flexGrow: 1, paddingStart: "size-125", paddingEnd: "size-125" }, { children: [_jsx("div", Object.assign({ style: { fontWeight: 'bold', cursor: 'default', flexGrow: 1, textAlign: 'left', fontVariantCaps: 'small-caps' } }, { children: title })), expanded && _jsx(FontAwesomeIcon, { icon: faChevronDown }), !expanded && _jsx(FontAwesomeIcon, { icon: faChevronUp })] })) }));
    }
    else {
        header = _jsx(View, Object.assign({ paddingStart: contentPaddingStart, paddingEnd: "size-125" }, { children: _jsx("span", Object.assign({ id: labelKey, style: { fontWeight: 'bold', cursor: 'default', flexGrow: 1, textAlign: 'left', fontVariantCaps: 'small-caps' } }, { children: title })) }));
    }
    return _jsx(SpectrumView, Object.assign({ elementType: "section" }, props, { "aria-labelledby": labelKey }, { children: _jsxs(SpectrumFlex, Object.assign({ direction: "column" }, { children: [_jsx(SpectrumFlex, Object.assign({ alignItems: "center", minHeight: 32, borderBottomWidth: expanded ? undefined : 'thin', borderBottomColor: "gray-300" }, { children: header })), _jsx(SpectrumFlex, Object.assign({ id: contentKey, isHidden: !expanded, backgroundColor: props.contentBackground ? props.contentBackground : 'gray-50', padding: contentPadding, paddingStart: contentPaddingStart, paddingEnd: contentPaddingEnd, paddingTop: contentPaddingTop, paddingBottom: contentPaddingBottom, boxShadow: ELEVATION_LEVELS.ELEVATION_4, borderColor: 'gray-400', borderWidth: 'thin', borderRadius: 'regular', alignItems: "stretch", direction: "column" }, { children: props.children }))] })) }));
}
SpectrumGroupPane.defaultProps = { isCollapsible: true };

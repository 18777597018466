var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { INTERCEPTED_SERVICE_SUPPORT } from '@beso-rsd/beso-rsd-core';
export class EAktenViewServiceREST {
    constructor(baseUrl, client) {
        this.endpoint = baseUrl + '/eaktenviewservice';
        this.client = client;
    }
    getAkteForVerfahren(verfahrenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getAkteForVerfahren', this.client, `${this.endpoint}/akte`, { verfahrenId }, {});
            return result + '';
        });
    }
    getAktenteil(aktenteilId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getAktenteil', this.client, `${this.endpoint}/aktenteil/${aktenteilId}`, {}, {});
            return result;
        });
    }
    getAktenteile(aktenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getAktenteile', this.client, `${this.endpoint}/akte/${aktenId}/aktenteile`, {}, {});
            return result;
        });
    }
    createAktenteil(aktenId, name, paginiert) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('createAktenteil', this.client, `${this.endpoint}/aktenteil`, { aktenId, name, paginiert }, {});
            return result;
        });
    }
    deleteAktenteil(aktenteilId, etag) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.delete('deleteAktenteil', this.client, `${this.endpoint}/aktenteil/${aktenteilId}`, { etag }, {});
            return result;
        });
    }
    renameAktenteil(aktenteilId, etag, name) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.patch('renameAktenteil', this.client, `${this.endpoint}/aktenteil/${aktenteilId}`, { etag, name }, {});
            return result;
        });
    }
    uploadDokument(aktenId, aktenteilId, dokument) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('uploadDokument', this.client, `${this.endpoint}/akte/${aktenId}/${aktenteilId}/upload`, { dokument }, {});
            return result;
        });
    }
    getNeueDokumenteCount(aktenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getNeueDokumenteCount', this.client, `${this.endpoint}/akte/${aktenId}/neueDokumente`, {}, {});
            return result;
        });
    }
    getDokumentEditor(dokumentId, editable = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getDokumentEditor', this.client, `${this.endpoint}/dokument/${dokumentId}/editor`, { editable }, {});
            return result;
        });
    }
    deleteDokument(dokumentId, etag) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.delete('deleteDokument', this.client, `${this.endpoint}/dokument/${dokumentId}`, { etag }, {});
            return result;
        });
    }
}
export class VeraktenViewServiceREST {
    constructor(baseUrl, client) {
        this.endpoint = baseUrl + '/veraktenviewservice';
        this.client = client;
    }
    getBaseData(aktenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getBaseData', this.client, `${this.endpoint}/${aktenId}/base`, {}, {});
            return result;
        });
    }
    getVerakteteDokumente(aktenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getVerakteteDokumente', this.client, `${this.endpoint}/${aktenId}/verakteteDokumente`, {}, {});
            return result;
        });
    }
    getNeueDokumente(aktenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getNeueDokumente', this.client, `${this.endpoint}/${aktenId}/neueDokumente`, {}, {});
            return result;
        });
    }
    uploadNeuesDokument(aktenId, dokument) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('uploadNeuesDokument', this.client, `${this.endpoint}/${aktenId}/upload`, { dokument }, {});
            return result;
        });
    }
    copyAsNeuesDokument(aktenId, dokumentId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('copyAsNeuesDokument', this.client, `${this.endpoint}/${aktenId}/uploadFromCopy`, { dokumentId }, {});
            return result;
        });
    }
    verakten(daten) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('verakten', this.client, `${this.endpoint}/verakten`, { daten }, {});
            return result + '';
        });
    }
}

import { AbstractObservableValue } from './Observable';
export class Property extends AbstractObservableValue {
    constructor(name, value) {
        super(name, value);
    }
    set value(val) {
        if (this.property) {
            throw new Error('Unable to set bound property');
        }
        this._setValue(val);
    }
    get value() {
        return this._value;
    }
    bind(property) {
        if (this.property) {
            throw new Error('Property is already bound');
        }
        this.property = property;
        const reg = this.property.onValueChange((val) => this._setValue(val));
        return () => {
            reg();
            this.property = undefined;
        };
    }
}
export class ObjectProperty extends Property {
    constructor(name, value) {
        super(name, value);
    }
}
export class ObjectPropertyN extends Property {
    constructor(name, value) {
        super(name, value);
    }
}
export class BooleanProperty extends Property {
    constructor(name, value) {
        super(name, value ? true : false);
        this.toggle = () => {
            return (this.value = !this.value);
        };
    }
}
export class StringProperty extends Property {
    constructor(name, value) {
        super(name, value);
    }
}
export class StringPropertyN extends Property {
    constructor(name, value) {
        super(name, value);
    }
}

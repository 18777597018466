import { jsx as _jsx } from "react/jsx-runtime";
import { Checkbox } from '@adobe/react-spectrum';
import { useValue } from '../utils/util';
function isVM(props) {
    return props.vm !== undefined;
}
export function SpectrumCheckBox(props) {
    const [value, setValue] = useValue(isVM(props) ? props.vm.value : props.value);
    const [disabled,] = useValue(isVM(props) ? props.vm.disabled : props.disabled);
    return _jsx(Checkbox, Object.assign({ isSelected: value, onChange: setValue, isDisabled: disabled }, { children: props.label }));
}

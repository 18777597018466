var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import { BooleanProperty } from '@baeso-ui/baeso-observable';
import { isElectron } from '@baeso-ui/baeso-core';
import { AUTHENTICATION_ADDRESS, AUTHENTICATION_REALM, CLIENT_ID } from './settings';
const REFRESH_TOKEN_STORAGE_KEY = 'onacta-auth-refresh-token';
export class LoginService {
    static getInstance() {
        if (!LoginService.instance) {
            LoginService.instance = new LoginService(AUTHENTICATION_ADDRESS, AUTHENTICATION_REALM, CLIENT_ID);
        }
        return LoginService.instance;
    }
    constructor(url, realm, clientId) {
        this.url = url;
        this.realm = realm;
        this.clientId = clientId;
        this.addInterceptors();
        this.cookies = new Cookies();
        if (process.env.REACT_APP_SERVICE_TYPE === 'mock' || process.env.REACT_APP_NO_LOGIN === 'true') {
            this.isLoggedIn = new class extends BooleanProperty {
                set value(val) {
                    // ignore updates to login status in "dev-mode", always stay logged in
                    console.info('dev-mode enabled, ignoring update to set login status', val);
                }
                get value() {
                    return true;
                }
            }('isLoggedIn', true);
        }
        else {
            this.isLoggedIn = new BooleanProperty('isLoggedIn', false);
            this.checkLoggedIn();
        }
    }
    getAccessToken(minimumValiditySeconds = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            // access token always retrieved from cookie to ensure consistent state when using multiple windows
            // i.e. if the user logs out in a different window (and the cookie gets removed), the instance would
            // still incorrectly believe the user is logged in the current window when relying only on an internal variable
            const accessToken = isElectron() ? yield window.electronCookieGet(process.env.REACT_APP_SERVICE_ELECTRON_BASEURL, LoginService.accessTokenCookieName) : this.cookies.get(LoginService.accessTokenCookieName);
            if (!accessToken || LoginService.isTokenExpired(accessToken, minimumValiditySeconds)) {
                // no access token available or it's expired -> try to retrieve a new one and return the new token (if it exists)
                return this.refreshTokens();
            }
            else {
                return accessToken;
            }
        });
    }
    setAccessToken(token) {
        return __awaiter(this, void 0, void 0, function* () {
            const expires = LoginService.getTokenExpireTime(token);
            if (isElectron()) {
                return window.electronCookieSet(process.env.REACT_APP_SERVICE_ELECTRON_BASEURL, LoginService.accessTokenCookieName, token, expires);
            }
            else {
                this.cookies.set(LoginService.accessTokenCookieName, token, { path: '/', httpOnly: false, expires: expires, sameSite: 'lax' });
                return;
            }
        });
    }
    clearAccessToken() {
        return __awaiter(this, void 0, void 0, function* () {
            if (isElectron()) {
                return window.electronCookieRemove(process.env.REACT_APP_SERVICE_ELECTRON_BASEURL, LoginService.accessTokenCookieName);
            }
            else {
                this.cookies.remove(LoginService.accessTokenCookieName);
                return;
            }
        });
    }
    clearTokens() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.clearAccessToken();
            this.refreshToken = undefined;
        });
    }
    get refreshToken() {
        const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
        if (refreshToken && LoginService.isTokenExpired(refreshToken)) {
            // clear expired tokens from the storage
            window.localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
            return undefined;
        }
        else {
            return refreshToken !== null && refreshToken !== void 0 ? refreshToken : undefined;
        }
    }
    set refreshToken(token) {
        if (token) {
            window.localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, token);
        }
        else {
            window.localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
        }
    }
    static getTokenExpireTime(token) {
        const decodedToken = jwt_decode(token);
        if (decodedToken.exp !== undefined) {
            const date = new Date();
            date.setTime(decodedToken.exp * 1000);
            return date;
        }
        else {
            return undefined;
        }
    }
    static isTokenExpired(token, minimumValiditySeconds = 0) {
        const expire = LoginService.getTokenExpireTime(token);
        return expire !== undefined ? (expire.getTime() - (new Date()).getTime() <= minimumValiditySeconds * 1000) : true;
    }
    isLoggedInProperty() {
        return this.isLoggedIn;
    }
    checkLoggedIn() {
        return __awaiter(this, void 0, void 0, function* () {
            const accessToken = yield this.getAccessToken();
            const isLoggedIn = accessToken ? true : false;
            this.isLoggedIn.value = isLoggedIn;
            return isLoggedIn;
        });
    }
    login(username, password) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const params = new URLSearchParams();
                params.append('grant_type', 'password');
                params.append('client_id', this.clientId);
                params.append('username', username);
                params.append('password', password);
                params.append('scope', 'openid');
                const response = yield axios.post(this.getKeycloakOpenIdURL() + '/token', params);
                yield this.setAccessToken(response.data.access_token);
                this.refreshToken = response.data.refresh_token;
                this.isLoggedIn.value = true;
            }
            catch (error) {
                console.error('Failed to login', error);
                throw error;
            }
        });
    }
    refreshTokens() {
        return __awaiter(this, void 0, void 0, function* () {
            const refreshToken = this.refreshToken;
            if (!refreshToken) {
                return undefined;
            }
            try {
                const params = new URLSearchParams();
                params.append('grant_type', 'refresh_token');
                params.append('client_id', this.clientId);
                params.append('refresh_token', refreshToken);
                params.append('scope', 'openid');
                const response = yield axios.post(this.getKeycloakOpenIdURL() + '/token', params);
                const accessToken = response.data.access_token;
                yield this.setAccessToken(accessToken);
                this.refreshToken = response.data.refresh_token;
                return accessToken;
            }
            catch (error) {
                console.warn('failed to retrieve new access token via refresh', error);
                yield this.clearTokens();
                this.isLoggedIn.value = false;
                return undefined;
            }
        });
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            const refreshToken = this.refreshToken;
            if (refreshToken) {
                const params = new URLSearchParams();
                params.append('client_id', this.clientId);
                params.append('refresh_token', refreshToken);
                yield axios.post(this.getKeycloakOpenIdURL() + '/logout', params);
            }
            else {
                console.warn('missing refresh_token, not able to send logout request to auth service');
            }
            yield this.clearTokens();
            this.isLoggedIn.value = false;
        });
    }
    getKeycloakOpenIdURL() {
        return (this.url + 'realms/' + this.realm + '/protocol/openid-connect');
    }
    addInterceptors() {
        axios.interceptors.request.use((config) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (!((_a = config.url) === null || _a === void 0 ? void 0 : _a.startsWith(this.getKeycloakOpenIdURL()))) {
                // try to ensure an access token is available before the request, if none is available
                // proceed as normal as a request to a public URL may still succeed, if authentication is
                // enforced this will be handled in the response interceptor
                yield this.getAccessToken(5);
            }
            return config;
        }));
        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => __awaiter(this, void 0, void 0, function* () {
            // fallback if the attempt to get a token before the request was not successful
            const requestConfig = error.config;
            if (requestConfig.retryCount === undefined) {
                requestConfig.retryCount = 0;
            }
            console.debug('In error interceptor');
            if (requestConfig.retryCount < 2) {
                if (error.response && error.response.status === 401) {
                    console.debug('Got 401. Retry Nr. ' + requestConfig.retryCount);
                    requestConfig.retryCount += 1;
                    const newToken = yield this.refreshTokens();
                    if (newToken) {
                        return axios.request(error.config);
                    }
                    else {
                        this.isLoggedIn.value = false;
                        return Promise.reject(error);
                    }
                }
            }
            return Promise.reject(error);
        }));
    }
}
LoginService.accessTokenCookieName = 'accessToken';

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BooleanProperty, ObjectProperty, ObjectPropertyN, ObservableArray, StringProperty, StringPropertyN } from '@baeso-ui/baeso-observable';
import { CheckBoxVM, DateInputVM, TextInputVM } from '@baeso-ui/baeso-vm';
import moment from 'moment';
export class AufgabenViewPanelVM {
    constructor(aufgabenService, restrictToSource) {
        this.aufgabenService = aufgabenService;
        this.restrictToSource = restrictToSource;
        this.listVM = new AufgabenListVM(aufgabenService, restrictToSource);
        this.detailVM = new AufgabenDetailVM(aufgabenService, this.listVM.aufgabeSelected);
    }
    createAufgabeCreationVM() {
        const source = this.restrictToSource ?
            { id: '',
                refType: this.restrictToSource.refType,
                refId: this.restrictToSource.refId,
                label: '',
            }
            : undefined;
        return new AufgabeCreationVM(this.aufgabenService, source);
    }
}
export var AufgabenListCategory;
(function (AufgabenListCategory) {
    AufgabenListCategory["DEFAULT"] = "default";
    AufgabenListCategory["ASSIGNED"] = "assigned";
    AufgabenListCategory["ERLEDIGT"] = "erledigt";
})(AufgabenListCategory || (AufgabenListCategory = {}));
export class AufgabenListVM {
    constructor(aufgabenService, restrictToSource) {
        this.aufgabenList = new ObservableArray('aufgaben', []);
        this.category = new ObjectProperty('category', AufgabenListCategory.DEFAULT);
        this.aufgaben = this.aufgabenList;
        this.aufgabeSelected = new ObjectProperty('aufgabeSelected');
        this.loadingData = new BooleanProperty('loadingData', true);
        this.aufgabenService = aufgabenService;
        this.restrictToSource = restrictToSource;
        this.category.onValueChange(() => this.loadAufgaben());
        this.loadAufgaben();
    }
    refresh(selectId) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loadAufgaben(selectId);
        });
    }
    loadAufgaben(selectId) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.loadingData.value = true;
                const previousAufgabeSelected = this.aufgabeSelected.value;
                this.aufgabeSelected.value = undefined;
                const aufgaben = yield this.fetchAufgabenItems();
                this.aufgabenList.setAll(aufgaben.map(a => new AufgabenItemVM(a)));
                if (selectId !== undefined) {
                    this.selectById(selectId);
                }
                else if (previousAufgabeSelected !== undefined) {
                    this.selectById(previousAufgabeSelected.id);
                }
            }
            finally {
                this.loadingData.value = false;
            }
        });
    }
    selectById(id) {
        const index = this.aufgabenList.findIndex(a => a.id === id);
        if (index >= 0) {
            this.aufgabeSelected.value = this.aufgabenList.itemAt(index);
        }
    }
    fetchAufgabenItems() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            switch (this.category.value) {
                case undefined:
                case AufgabenListCategory.DEFAULT:
                    if (this.restrictToSource) {
                        return this.aufgabenService._listBySource(this.restrictToSource.refType, this.restrictToSource.refId, false, false);
                    }
                    else {
                        return this.aufgabenService._listOwn(false, false);
                    }
                case AufgabenListCategory.ASSIGNED:
                    return this.aufgabenService._listAssigned(false, (_a = this.restrictToSource) === null || _a === void 0 ? void 0 : _a.refType, (_b = this.restrictToSource) === null || _b === void 0 ? void 0 : _b.refId, false);
                case AufgabenListCategory.ERLEDIGT:
                    if (this.restrictToSource) {
                        return this.aufgabenService._listBySource(this.restrictToSource.refType, this.restrictToSource.refId, true, false);
                    }
                    else {
                        return this.aufgabenService._listOwn(true, false);
                    }
                default:
                    throw new Error('unimplemented list category');
            }
        });
    }
}
export class AufgabenItemVM {
    constructor(dto) {
        this.id = dto.id;
        this.name = new StringPropertyN('name', dto.name);
        this.beschreibung = new StringProperty('name', dto.beschreibung);
        this.date = new ObjectPropertyN('date', new Date(dto.erinnerungsDatum));
        this.eilig = new BooleanProperty('eilig', dto.eilig);
    }
    updateFromDetails(details) {
        this.name.value = details.name;
        this.beschreibung.value = details.beschreibung;
        this.date.value = new Date(details.erinnerungsDatum);
        this.eilig.value = details.eilig;
    }
}
export class AufgabenDetailVM {
    constructor(aufgabenService, item) {
        this.dataEditor = new AufgabenDataEditorVM();
        this.erledigt = new ObjectProperty('erledigt');
        // 'sources' is readonly in the DTO -> complete list only changes at once when the active item changes,
        // no need to track details changes within the list
        this.sources = new ObjectPropertyN('sources', []);
        // no item selected -> undefined, lockedBy attribute on Person is empty/undefined -> null
        this.lockedBy = new ObjectProperty('lockedBy');
        this.handleItemChanged = (newValue) => __awaiter(this, void 0, void 0, function* () {
            if (newValue) {
                this.itemDetails = yield this.aufgabenService.getDetails(newValue.id);
                this.sources.value = this.itemDetails.source;
                this.updateProperties(this.itemDetails);
            }
            else {
                this.itemDetails = undefined;
                this.sources.value = [];
                this.erledigt.value = undefined;
                this.lockedBy.value = undefined;
            }
            this.dataEditor.markClean();
        });
        this.aufgabenService = aufgabenService;
        this.item = item;
        item.onValueChange(this.handleItemChanged);
    }
    // update properties of the VM based on the provided (updated) details,
    // does not handle readonly attributes of the DTO as these may change only when the active
    // item is changes completely
    updateProperties(details) {
        this.dataEditor.itemPropertiesUpdating = true;
        const editable = (details !== undefined && !details.erledigt && details.lockedBy === undefined);
        this.dataEditor.beschreibung.value.value = details === null || details === void 0 ? void 0 : details.beschreibung;
        //this.dataEditor.beschreibung.disabled.value = !editable;
        this.dataEditor.beschreibung.editable.value = editable;
        this.dataEditor.erinnerungsDatum.value.value = details === null || details === void 0 ? void 0 : details.erinnerungsDatum.split('T', 1)[0];
        //this.dataEditor.erinnerungsDatum.disabled.value = !editable;
        this.dataEditor.erinnerungsDatum.editable.value = editable;
        this.dataEditor.eilig.value.value = details === null || details === void 0 ? void 0 : details.eilig;
        this.dataEditor.eilig.disabled.value = !editable;
        this.erledigt.value = details === null || details === void 0 ? void 0 : details.erledigt;
        this.lockedBy.value = details === undefined ? undefined : (details.lockedBy || null);
        this.dataEditor.itemPropertiesUpdating = false;
    }
    reset() {
        this.updateProperties(this.itemDetails);
        this.dataEditor.markClean();
    }
    save() {
        if (!this.dataEditor.dirty.value || !this.itemDetails) {
            return;
        }
        // build delta
        const delta = {
            id: this.itemDetails.id,
            etag: this.itemDetails.etag
        };
        const updatedDetails = this.collectChanges(this.itemDetails, delta);
        // save
        this.aufgabenService.update(delta).then((result) => {
            var _a;
            if (this.itemDetails) {
                updatedDetails.etag = result.etag;
                this.itemDetails = updatedDetails;
                (_a = this.item.value) === null || _a === void 0 ? void 0 : _a.updateFromDetails(updatedDetails);
            }
            this.dataEditor.markClean();
        }, (error) => {
            console.error('failed to save Aufgabe:', error);
        });
    }
    markErledigt() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.itemDetails) {
                console.error('no item selected, can\'t mark as erledigt');
                return;
            }
            const result = yield this.aufgabenService.markErledigt(this.itemDetails.id, this.itemDetails.etag);
            this.itemDetails.etag = result.etag;
            this.itemDetails.erledigt = true;
            this.updateProperties(this.itemDetails);
            (_a = this.item.value) === null || _a === void 0 ? void 0 : _a.updateFromDetails(this.itemDetails);
        });
    }
    lock() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.itemDetails) {
                console.error('no item selected, can\'t lock Aufgabe');
                return;
            }
            const result = yield this.aufgabenService.lock(this.itemDetails.id, this.itemDetails.etag);
            this.itemDetails.etag = result.etag;
            // fetch the updated Aufgabe to get the complete new "lockedBy" content which was created server-side
            this.itemDetails.lockedBy = (yield this.aufgabenService.getDetails(result.id)).lockedBy;
            this.updateProperties(this.itemDetails);
            (_a = this.item.value) === null || _a === void 0 ? void 0 : _a.updateFromDetails(this.itemDetails);
        });
    }
    unlock() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.itemDetails) {
                console.error('no item selected, can\'t unlock Aufgabe');
                return;
            }
            const result = yield this.aufgabenService.unlock(this.itemDetails.id, this.itemDetails.etag);
            this.itemDetails.etag = result.etag;
            this.itemDetails.lockedBy = undefined;
            this.updateProperties(this.itemDetails);
            (_a = this.item.value) === null || _a === void 0 ? void 0 : _a.updateFromDetails(this.itemDetails);
        });
    }
    collectChanges(details, delta) {
        // create a deep copy
        const updatedDetails = JSON.parse(JSON.stringify(details));
        if (this.dataEditor.beschreibung.value.value != details.beschreibung) {
            delta.beschreibung = { value: this.dataEditor.beschreibung.value.value };
            updatedDetails.beschreibung = this.dataEditor.beschreibung.value.value;
        }
        const detailsErinnerungsDatum = AufgabenDataEditorVM.dtoToEditorDate(details.erinnerungsDatum);
        if (this.dataEditor.erinnerungsDatum.value.value && this.dataEditor.erinnerungsDatum.value.value != detailsErinnerungsDatum) {
            const newErinnerungsDatum = AufgabenDataEditorVM.editorToDtoDate(this.dataEditor.erinnerungsDatum.value.value);
            delta.erinnerungsDatum = { value: newErinnerungsDatum };
            updatedDetails.erinnerungsDatum = newErinnerungsDatum;
        }
        if (this.dataEditor.eilig.value.value !== undefined && this.dataEditor.eilig.value.value != details.eilig) {
            delta.eilig = { value: this.dataEditor.eilig.value.value };
            updatedDetails.eilig = this.dataEditor.eilig.value.value;
        }
        return updatedDetails;
    }
}
export class AufgabenDataEditorVM {
    constructor() {
        this.beschreibung = new TextInputVM();
        this.erinnerungsDatum = new DateInputVM();
        this.eilig = new CheckBoxVM();
        this.dirty = new BooleanProperty('dirty', false);
        this.itemPropertiesUpdating = false;
        this.markDirty = () => {
            if (!this.itemPropertiesUpdating) {
                this.dirty.value = true;
            }
        };
        this.erinnerungsDatum.required.value = true;
        this.beschreibung.value.onValueChange(this.markDirty);
        this.erinnerungsDatum.value.onValueChange(this.markDirty);
        this.eilig.value.onValueChange(this.markDirty);
    }
    markClean() {
        this.dirty.value = false;
    }
    // editor uses a simple date-only input at the moment, service internally supports date+time
    // -> hide the time component when getting data from the API, add a default when submitting data
    // could be changed to use a full date+time editor component in the future, this would make these helpers obsolete
    static dtoToEditorDate(dtoDate) {
        return dtoDate.split('T', 1)[0];
    }
    static editorToDtoDate(editorDate) {
        return editorDate + 'T23:59';
    }
}
export class AufgabeCreationBaseVM {
    constructor() {
        this.title = new TextInputVM();
        this.dataEditor = new AufgabenDataEditorVM();
        this.dataComplete = new BooleanProperty('dataComplete', false);
        this.calculateDataComplete = () => {
            this.dataComplete.value = (this.title.value.value && this.dataEditor.erinnerungsDatum.value.value ? true : false);
        };
        this.title.required.value = true;
        this.title.value.onValueChange(this.calculateDataComplete);
        this.dataEditor.erinnerungsDatum.value.onValueChange(this.calculateDataComplete);
    }
    getAufgabeDetails(source) {
        if (!this.title.value.value || !this.dataEditor.erinnerungsDatum.value.value) {
            throw new Error('required fields are empty');
        }
        return {
            id: '',
            etag: '',
            name: this.title.value.value,
            beschreibung: this.dataEditor.beschreibung.value.value,
            erinnerungsDatum: AufgabenDataEditorVM.editorToDtoDate(this.dataEditor.erinnerungsDatum.value.value),
            eilig: this.dataEditor.eilig.value.value || false,
            erledigt: false,
            source: source !== undefined ? [source] : [],
            empfaenger: [],
        };
    }
}
export class AufgabeCreationVM extends AufgabeCreationBaseVM {
    constructor(aufgabenService, source) {
        super();
        this.aufgabenService = aufgabenService;
        this.source = source;
    }
    create() {
        return __awaiter(this, void 0, void 0, function* () {
            const aufgabe = this.getAufgabeDetails(this.source);
            const result = yield this.aufgabenService.create(aufgabe);
            return result.id;
        });
    }
}
export class AufgabeEditorVM extends AufgabeCreationBaseVM {
    constructor(initialData) {
        super();
        this.title.value.value = initialData.name.value;
        this.dataEditor.itemPropertiesUpdating = true;
        this.dataEditor.beschreibung.value.value = initialData.beschreibung.value;
        this.dataEditor.erinnerungsDatum.value.value = moment(initialData.date.value).format('YYYY-MM-DD');
        this.dataEditor.eilig.value.value = initialData.eilig.value;
        this.dataEditor.itemPropertiesUpdating = false;
    }
    apply(item) {
        item.updateFromDetails(this.getAufgabeDetails());
    }
}
export class AufgabeMultiCreationVM {
    constructor(aufgabenService) {
        this.aufgabenList = new ObservableArray('aufgaben', []);
        this.aufgaben = this.aufgabenList;
        this.aufgabenService = aufgabenService;
        this.nextTmpId = -1;
    }
    newAufgabe() {
        return new AufgabenItemVM({
            id: this.nextTmpId-- + '',
            etag: '',
            name: '',
            beschreibung: undefined,
            erinnerungsDatum: moment().add({ week: 1 }).format('YYYY-MM-DD'),
            eilig: false,
        });
    }
    add(aufgabe) {
        if (aufgabe) {
            this.aufgabenList.push(aufgabe);
        }
    }
    remove(aufgabe) {
        this.aufgabenList.remove(aufgabe);
    }
    create(source) {
        return __awaiter(this, void 0, void 0, function* () {
            const aufgaben = this.aufgabenList.map(item => {
                return {
                    id: '',
                    etag: '',
                    name: item.name.value,
                    beschreibung: item.beschreibung.value,
                    erinnerungsDatum: AufgabenDataEditorVM.editorToDtoDate(moment(item.date.value).format('YYYY-MM-DD')),
                    eilig: item.eilig.value,
                    erledigt: false,
                    source: source !== undefined ? [source] : [],
                    empfaenger: [],
                };
            });
            const asyncResults = aufgaben.map(details => this.aufgabenService.create(details));
            const results = yield Promise.allSettled(asyncResults);
            results.filter((r) => r.status === 'rejected').forEach(r => console.error('failed to create Aufgabe:', r.reason));
            return results.filter((r) => r.status === 'fulfilled')
                .map(r => r.value.id);
        });
    }
}

import { lazy } from 'react';

import { ServiceRegistry } from '@baeso-ui/baeso-core';

import { AufgabenViewService, DetailSourceReferenceType, SourceReference, SourceReferenceType } from '@onacta/onacta-aufgaben-services';
import { AufgabeMultiCreationVM } from '@onacta/onacta-aufgaben-vm';
const AufgabeMultiCreationPanel = lazy(() => import('../wrappers/AufgabeMultiCreationPanel'));

import { ActionComponent, AufgabenCreationComponentFactory } from '@onacta/onacta-eakten-vm';

export class AufgabenCreationComponentFactoryImpl implements AufgabenCreationComponentFactory {
  private readonly aufgabenService: AufgabenViewService;
  private readonly verfahrenId: string;

  constructor(s: ServiceRegistry, verfahrenId: string) {
    this.aufgabenService = s.create('@onacta/onacta-aufgaben-services/AufgabenViewService');
    this.verfahrenId = verfahrenId;
  }

  public createComponent(): ActionComponent {
    // TODO linking aufgaben not to the Verfahren, but directly to the Akte instead
    const vm = new AufgabeMultiCreationVM(this.aufgabenService);
    return {
      getUI: () => {
        return <AufgabeMultiCreationPanel vm={vm} />;
      },
  
      execute: async (dokumentId?: string) => {
        const source: SourceReference = {
          id: '',
          refType: SourceReferenceType.VERFAHREN,
          refId: this.verfahrenId,
          label: '',
        };
        if (dokumentId) {
          source.detailRefType = DetailSourceReferenceType.DOKUMENT;
          source.detailRefId = dokumentId;
        }

        await vm.create(source);
      }
    };
  }
}
import { State } from './base-apis';
import { trim } from './string-utils';
const LOCALE_SEPARATORS = new Map();
export function parseFormattedNumber(value, options) {
    var _a, _b, _c;
    if (!value) {
        return undefined;
    }
    const locale = options === null || options === void 0 ? void 0 : options.locale;
    const signed = options === null || options === void 0 ? void 0 : options.signed;
    let separators = LOCALE_SEPARATORS.get(locale || '__DEFAULT__');
    if (separators === undefined) {
        const parts = new Intl.NumberFormat(locale).formatToParts(-1000.99);
        const decimal = ((_a = parts.find(p => p.type === 'decimal')) === null || _a === void 0 ? void 0 : _a.value) || '.';
        const group = ((_b = parts.find(p => p.type === 'group')) === null || _b === void 0 ? void 0 : _b.value) || ',';
        const minus = ((_c = parts.find(p => p.type === 'minusSign')) === null || _c === void 0 ? void 0 : _c.value) || '-';
        separators = { decimal, minus, group, checkReg: new RegExp('[^' + minus + group + decimal + '\\d]') };
        LOCALE_SEPARATORS.set(locale || '__DEFAULT__', separators);
    }
    if (separators.checkReg.test(value)) {
        return NaN;
    }
    if (signed && value.startsWith(separators.minus)) {
        const parseableNumber = value.substring(0, 1).replace(separators.group, '') // only keep number and decimal sep
            .replace(separators.decimal, '.');
        return parseFloat(parseableNumber) * -1;
    }
    const parseableNumber = value.replace(separators.group, '') // only keep number and decimal sep
        .replace(separators.decimal, '.');
    return parseFloat(parseableNumber);
}
export function tryParseFormattedNumber(value = '', errorMessage) {
    if (trim(value) === '') {
        return { state: State.OK, text: '', value: 0 };
    }
    const result = parseFormattedNumber(trim(value));
    if (result === undefined || isNaN(result)) {
        return { state: State.ERROR, text: errorMessage, value: NaN };
    }
    return { state: State.OK, text: '', value: result };
}
export function formatNumber(value, fractionDigits, locale) {
    return new Intl.NumberFormat(locale, { maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits }).format(value);
}
export function isValidNumber(value) {
    return value !== undefined && !isNaN(value);
}

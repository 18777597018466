import { jsx as _jsx } from "react/jsx-runtime";
import { TextArea } from '@adobe/react-spectrum';
import { MessageDisplay } from '../form/SpectrumForm.types';
import { SpectrumFieldMessage } from '../fieldmessage/SpectrumFieldMessage';
import { useValue } from '../utils/util';
function isVM(props) {
    return props.vm !== undefined;
}
export function SpectrumTextArea(props) {
    const messageOrProperty = isVM(props) ? props.vm.message : props.message;
    const [value, setValue] = useValue(isVM(props) ? props.vm.value : props.value);
    const [disabled,] = useValue(isVM(props) ? props.vm.disabled : props.disabled);
    const [editable,] = useValue(isVM(props) ? props.vm.editable : props.editable);
    const [required,] = useValue(isVM(props) ? props.vm.required : props.required);
    const field = _jsx(TextArea, { id: props.id, label: props.label && _jsx("span", Object.assign({ style: { overflow: 'hidden', textOverflow: 'ellipsis' } }, { children: props.label })), isDisabled: disabled, placeholder: props.placeholder, value: value === undefined ? '' : value, isReadOnly: editable === undefined ? false : !editable, onChange: setValue, isRequired: required ? true : undefined, width: "100%" });
    if (props.messageDisplay !== MessageDisplay.NONE) {
        return _jsx(SpectrumFieldMessage, Object.assign({ message: messageOrProperty }, { children: field }));
    }
    return field;
}

export var VerfahrenStatus;
(function (VerfahrenStatus) {
    VerfahrenStatus[VerfahrenStatus["LAUFEND"] = 0] = "LAUFEND";
    VerfahrenStatus[VerfahrenStatus["ERLEDIGT"] = 1] = "ERLEDIGT";
    VerfahrenStatus[VerfahrenStatus["WEGGELEGT"] = 2] = "WEGGELEGT";
    VerfahrenStatus[VerfahrenStatus["ZURUECK_GESANDT"] = 3] = "ZURUECK_GESANDT";
    VerfahrenStatus[VerfahrenStatus["ABGEGEBEN"] = 4] = "ABGEGEBEN";
    VerfahrenStatus[VerfahrenStatus["UNTERBUNDEN"] = 5] = "UNTERBUNDEN";
})(VerfahrenStatus || (VerfahrenStatus = {}));
export var StrafsachenVerfahrenart;
(function (StrafsachenVerfahrenart) {
    StrafsachenVerfahrenart[StrafsachenVerfahrenart["PRIVATKLAGE"] = 0] = "PRIVATKLAGE";
    StrafsachenVerfahrenart[StrafsachenVerfahrenart["BESCHLEUNIGT"] = 1] = "BESCHLEUNIGT";
    StrafsachenVerfahrenart[StrafsachenVerfahrenart["SONSTIG"] = 2] = "SONSTIG";
})(StrafsachenVerfahrenart || (StrafsachenVerfahrenart = {}));
export var TimelineRefTyp;
(function (TimelineRefTyp) {
    TimelineRefTyp[TimelineRefTyp["AKTENDOKUMENT"] = 0] = "AKTENDOKUMENT";
    TimelineRefTyp[TimelineRefTyp["NACHRICHT"] = 1] = "NACHRICHT";
    TimelineRefTyp[TimelineRefTyp["TERMIN"] = 2] = "TERMIN";
    TimelineRefTyp[TimelineRefTyp["UNBEKANNT"] = 3] = "UNBEKANNT";
})(TimelineRefTyp || (TimelineRefTyp = {}));
export var ActivityType;
(function (ActivityType) {
    ActivityType[ActivityType["ADD_DOKUMENT"] = 0] = "ADD_DOKUMENT";
    ActivityType[ActivityType["HANDLE_MESSAGE"] = 1] = "HANDLE_MESSAGE";
    ActivityType[ActivityType["EDIT_ATTRIBUTE"] = 2] = "EDIT_ATTRIBUTE";
})(ActivityType || (ActivityType = {}));
export var TaskType;
(function (TaskType) {
    TaskType[TaskType["APPOINTMENT"] = 0] = "APPOINTMENT";
    TaskType[TaskType["DEADLINE"] = 1] = "DEADLINE";
})(TaskType || (TaskType = {}));
export var ParticipantType;
(function (ParticipantType) {
    ParticipantType[ParticipantType["PERSON"] = 0] = "PERSON";
    ParticipantType[ParticipantType["ORGANIZATION"] = 1] = "ORGANIZATION";
    ParticipantType[ParticipantType["KANZLEI"] = 2] = "KANZLEI";
})(ParticipantType || (ParticipantType = {}));
export var Erfassungsart;
(function (Erfassungsart) {
    Erfassungsart[Erfassungsart["MIT_GVP"] = 0] = "MIT_GVP";
    Erfassungsart[Erfassungsart["MANUELL"] = 1] = "MANUELL";
    Erfassungsart[Erfassungsart["ALTVERFAHREN_MANUELL"] = 2] = "ALTVERFAHREN_MANUELL";
})(Erfassungsart || (Erfassungsart = {}));

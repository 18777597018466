import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Item, Text, useMessageFormatter, } from '@adobe/react-spectrum';
import Calendar from '@spectrum-icons/workflow/Calendar';
import Clock from '@spectrum-icons/workflow/Clock';
import Email from '@spectrum-icons/workflow/Email';
import EmailOutline from '@spectrum-icons/workflow/EmailOutline';
import Folder from '@spectrum-icons/workflow/Folder';
import Inbox from '@spectrum-icons/workflow/Inbox';
import UserGroup from '@spectrum-icons/workflow/UserGroup';
import { Area, AreaChart, Bar, BarChart, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, } from 'recharts';
import { DateFormats, formatDate } from '@baeso-ui/baeso-core';
import { SpectrumFlex, SpectrumFluidGrid, SpectrumFluidGridCell, SpectrumFluidGridRow, SpectrumGroupPane, SpectrumListBox, SpectrumText, useValue } from '@baeso-ui/baeso-ui-spectrum';
import { openVerfahrenWindow } from '@onacta/onacta-base-ui';
import { ReminderItemType } from '@onacta/onacta-home-services';
import { ActivityType, HomeViewPanelVM, } from '@onacta/onacta-home-vm';
import { messages } from './Messages';
function messageItemToItem(item) {
    return (_jsxs(Item, Object.assign({ textValue: item.betreff }, { children: [_jsx(EmailOutline, {}), _jsxs(Text, Object.assign({ UNSAFE_style: { fontWeight: 'bold' } }, { children: [item.postfach, " - ", item.betreff] })), _jsxs(Text, Object.assign({ slot: "description" }, { children: ["vom ", item.datum, " - ", item.absender, " - ", item.beschreibung] }))] }), item.id));
}
function activityItemToItem(item) {
    let icon;
    if (item.type === ActivityType.BETEILIGTE) {
        icon = _jsx(UserGroup, {});
    }
    else if (item.type === ActivityType.NACHRICHTEN) {
        icon = _jsx(Email, {});
    }
    else if (item.type === ActivityType.TERMIN) {
        icon = _jsx(Calendar, {});
    }
    else {
        icon = _jsx(Folder, {});
    }
    return (_jsxs(Item, Object.assign({ textValue: item.titel }, { children: [icon, _jsxs(Text, Object.assign({ UNSAFE_style: { fontWeight: 'bold' } }, { children: [item.komponente, " - ", item.titel] })), _jsx(Text, Object.assign({ slot: "description" }, { children: item.beschreibung }))] }), item.id));
}
function terminItemToItem(item, m) {
    let icon;
    let type;
    if (item.type === ReminderItemType.AUFGABE) {
        icon = _jsx(Clock, {});
        type = m('home.task');
    }
    else if (item.type === ReminderItemType.TERMIN) {
        icon = _jsx(Calendar, {});
        type = m('home.appointment');
    }
    return (_jsxs(Item, Object.assign({ textValue: item.titel }, { children: [icon, _jsxs(Text, Object.assign({ UNSAFE_style: { fontWeight: 'bold' } }, { children: [type, " - ", item.titel] })), _jsx(SpectrumText, { slot: 'description', value: item.datum, formatter: (d => m('home.eventAt') + ' ' + formatDate(d, DateFormats.DATETIME_DAY_AND_MONTH)) })] }), item.type + '/' + item.id));
}
function lawsuitItemToItem(item) {
    return (_jsxs(Item, Object.assign({ textValue: item.aktenzeichen + ' - ' + item.titel }, { children: [_jsx(Inbox, {}), _jsx(Text, Object.assign({ UNSAFE_style: { fontWeight: 'bold' } }, { children: item.aktenzeichen + ' - ' + item.titel })), _jsx(Text, Object.assign({ slot: "description" }, { children: item.beschreibung }))] }), item.id));
}
export function HomeView(props) {
    const [vm,] = useState(() => new HomeViewPanelVM(props.serviceRegistry.create('@onacta/onacta-home-services/DashboardViewService')));
    return _jsx(HomeViewPanel, { vm: vm });
}
export function HomeViewPanel(props) {
    return _jsx("div", Object.assign({ style: { width: '100%', height: '100%', overflowY: 'auto', overflowX: 'hidden' } }, { children: _jsx("div", Object.assign({ style: { padding: 10 } }, { children: _jsxs(SpectrumFluidGrid, Object.assign({ breakPoints: { 'XS': 304, 'S': 576, 'M': 768, 'L': 992, 'XL': 1280 }, rowGap: 15, colGap: 15 }, { children: [_jsxs(SpectrumFluidGridRow, { children: [_jsx(SpectrumFluidGridCell, Object.assign({ cellConfig: { 'XS': 12, 'L': 6 } }, { children: _jsx(AktuelleAufgabeTerminePanel, { vm: props.vm.upcomingTasksVM }) })), _jsx(SpectrumFluidGridCell, Object.assign({ cellConfig: { 'XS': 12, 'L': 6 } }, { children: _jsx(TermineDerWochePanel, { vm: props.vm.appointmentsVM }) }))] }), _jsxs(SpectrumFluidGridRow, { children: [_jsx(SpectrumFluidGridCell, Object.assign({ cellConfig: { 'XS': 12, 'L': 6 } }, { children: _jsx(UngeleseneNachrichtenPanel, { vm: props.vm.unreadMessagesVM }) })), _jsx(SpectrumFluidGridCell, Object.assign({ cellConfig: { 'XS': 12, 'L': 6 } }, { children: _jsx(NachrichtenStatistikPanel, { vm: props.vm.messageStatsVM }) }))] }), _jsxs(SpectrumFluidGridRow, { children: [_jsx(SpectrumFluidGridCell, Object.assign({ cellConfig: { 'XS': 12, 'L': 6 } }, { children: _jsx(NeueVerfahrenPanel, { vm: props.vm.newLawsuitesVM }) })), _jsx(SpectrumFluidGridCell, Object.assign({ cellConfig: { 'XS': 12, 'L': 6 } }, { children: _jsx(VerfahrenStatistikPanel, { vm: props.vm.lawsuitStatsVM }) }))] })] })) })) }));
}
export function UngeleseneNachrichtenPanel(props) {
    const m = useMessageFormatter(messages);
    return _jsx(SpectrumGroupPane, Object.assign({ title: m('home.unreadMessages'), flexGrow: 1, minWidth: 0, isCollapsible: false }, { children: _jsxs(SpectrumFlex, Object.assign({ direction: "column", maxHeight: 245, minHeight: 245, rowGap: 10 }, { children: [_jsx(SpectrumListBox, { items: props.vm.unreadMessages, optionToItem: messageItemToItem, value: props.vm.selectedMessage, optionToKey: (item) => item.id, listBoxProps: { 'aria-label': m('home.unreadMessages') } }), _jsx(SpectrumFlex, Object.assign({ minHeight: "size-400" }, { children: _jsx(Button, Object.assign({ isDisabled: true, variant: "primary", marginStart: "auto" }, { children: "\u00D6ffnen" })) }))] })) }));
}
export function NachrichtenStatistikPanel(props) {
    const m = useMessageFormatter(messages);
    const [data, setData] = useState(props.vm.dayMessageStats.value.slice());
    useEffect(() => {
        setData(props.vm.dayMessageStats.value.slice());
        return props.vm.dayMessageStats.onValueChange(newData => setData(newData.slice()));
    }, [props.vm.dayMessageStats]);
    return (_jsx(SpectrumGroupPane, Object.assign({ title: m('home.messageStatistics'), flexGrow: 1, minWidth: 0, isCollapsible: false }, { children: _jsx(ResponsiveContainer, Object.assign({ height: 245, width: "100%" }, { children: _jsxs(AreaChart, Object.assign({ data: data, margin: { top: 5, right: 30, left: 20, bottom: 25, } }, { children: [_jsx(Area, { type: "monotone", dataKey: m('home.todo'), stackId: "1", fill: "#8884d8", stroke: "#8884d8", activeDot: { r: 8 }, strokeWidth: 2 }), _jsx(Area, { type: "monotone", dataKey: m('home.done'), stackId: "1", fill: "#82ca9d", stroke: "#82ca9d", activeDot: { r: 8 }, strokeWidth: 2 }), _jsx(XAxis, { dataKey: "tag", tick: _jsx(CustomizedAxisTick, {}) }), _jsx(Tooltip, { contentStyle: { backgroundColor: 'var(--spectrum-alias-background-color-default)' } }), _jsx(Legend, {})] })) })) })));
}
export function LetzteVorgaengePanel(props) {
    const m = useMessageFormatter(messages);
    return _jsx(SpectrumGroupPane, Object.assign({ title: m('home.recentActivities'), flexGrow: 1, minWidth: 0, isCollapsible: false }, { children: _jsxs(SpectrumFlex, Object.assign({ direction: "column", maxHeight: 245, minHeight: 245, rowGap: 10, paddingTop: 10 }, { children: [_jsx(SpectrumListBox, { items: props.vm.recentActivities, optionToItem: activityItemToItem, value: props.vm.selectedActivity, optionToKey: (item) => item.id, listBoxProps: { 'aria-label': m('home.recentActivities') } }), _jsx(SpectrumFlex, Object.assign({ minHeight: "size-400" }, { children: _jsx(Button, Object.assign({ isDisabled: true, variant: "primary", marginStart: "auto" }, { children: "\u00D6ffnen" })) }))] })) }));
}
export function AktuelleAufgabeTerminePanel(props) {
    const m = useMessageFormatter(messages);
    return _jsx(SpectrumGroupPane, Object.assign({ title: m('home.upcomingTasks'), flexGrow: 1, minWidth: 0, isCollapsible: false }, { children: _jsxs(SpectrumFlex, Object.assign({ direction: "column", maxHeight: 245, minHeight: 245, rowGap: 10 }, { children: [_jsx(SpectrumListBox, { items: props.vm.upcomingTasks, optionToItem: i => terminItemToItem(i, m), value: props.vm.selectedTask, optionToKey: (item) => item.type + '/' + item.id, listBoxProps: { 'aria-label': m('home.upcomingTasks') } }), _jsx(SpectrumFlex, Object.assign({ minHeight: "size-400" }, { children: _jsx(Button, Object.assign({ isDisabled: true, variant: "primary", marginStart: "auto" }, { children: "\u00D6ffnen" })) }))] })) }));
}
export function TermineDerWochePanel(props) {
    const m = useMessageFormatter(messages);
    return _jsx(SpectrumGroupPane, Object.assign({ title: m('home.appointments'), flexGrow: 1, minWidth: 0, isCollapsible: false }, { children: _jsx(ResponsiveContainer, Object.assign({ height: 245, width: "99%" }, { children: _jsxs(BarChart, Object.assign({ data: props.vm.termine, margin: { top: 5, right: 30, left: 20, bottom: 25, } }, { children: [_jsx(XAxis, { dataKey: "name", tick: _jsx(CustomizedAxisTick, {}) }), _jsx(Tooltip, { cursor: { fill: 'var(--spectrum-alias-background-color-default)' }, contentStyle: { backgroundColor: 'var(--spectrum-alias-background-color-default)' } }), _jsx(Legend, {}), _jsx(Bar, { dataKey: m('home.trials'), fill: "#8884d8", stroke: "#8884d8", strokeWidth: 2 })] })) })) }));
}
export function NeueVerfahrenPanel(props) {
    const m = useMessageFormatter(messages);
    const [selectedItem] = useValue(props.vm.selectedLawsuite);
    const [openDisabled] = useValue(props.vm.openDisabled);
    const openHandler = () => {
        if (selectedItem) {
            openVerfahrenWindow(selectedItem.aktenzeichen, selectedItem.id);
        }
    };
    return _jsx(SpectrumGroupPane, Object.assign({ title: m('home.newLawsuits'), flexGrow: 1, minWidth: 0, isCollapsible: false }, { children: _jsxs(SpectrumFlex, Object.assign({ direction: "column", maxHeight: 245, minHeight: 245, rowGap: 10 }, { children: [_jsx(SpectrumListBox, { items: props.vm.newLawsuits, optionToItem: lawsuitItemToItem, value: props.vm.selectedLawsuite, optionToKey: (item) => item.id, listBoxProps: { 'aria-label': m('home.newLawsuits') }, flexGrow: 1 }), _jsx(SpectrumFlex, Object.assign({ minHeight: "size-400" }, { children: _jsx(Button, Object.assign({ variant: "primary", isDisabled: openDisabled, onPress: openHandler, marginStart: "auto" }, { children: "\u00D6ffnen" })) }))] })) }));
}
export function VerfahrenStatistikPanel(props) {
    const m = useMessageFormatter(messages);
    return _jsx(SpectrumGroupPane, Object.assign({ title: m('home.lawsuitStatistics'), flexGrow: 1, minWidth: 0, isCollapsible: false }, { children: _jsx(ResponsiveContainer, Object.assign({ height: 245, width: "100%" }, { children: _jsxs(LineChart, Object.assign({ data: props.vm.verfahren, margin: { top: 5, right: 30, left: 20, bottom: 25, } }, { children: [_jsx(Line, { dataKey: "offeneVerfahren", stroke: "#8884d8", activeDot: { r: 8 }, strokeWidth: 2 }), _jsx(XAxis, { dataKey: "name", tick: _jsx(CustomizedAxisTick, {}) }), _jsx(Tooltip, { contentStyle: { backgroundColor: 'var(--spectrum-alias-background-color-default)' } }), _jsx(Legend, {})] })) })) }));
}
function CustomizedAxisTick(props) {
    var _a;
    return _jsx("g", { children: _jsx("text", Object.assign({ x: props.x, y: props.y, dy: 16, textAnchor: "middle", style: { fill: 'var(--spectrum-alias-text-color)' } }, { children: (_a = props.payload) === null || _a === void 0 ? void 0 : _a.value })) });
}

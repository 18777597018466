import { Button, ButtonGroup, Content, Dialog, Divider, Heading, Switch, } from '@adobe/react-spectrum';
import { FormatMessage, useMessageFormatter } from '@react-aria/i18n';

import { DropdownOption, SpectrumDropDown, useValue } from '@baeso-ui/baeso-ui-spectrum';

import { VISION_DEFICIENCIES, preferenceStore } from '@onacta/onacta-base-ui';

import { COLOR_THEMES, SCALES } from './OnActa';
import { messages } from '../Messages';

function colorThemeToOption(item: string, m: FormatMessage): DropdownOption {
  return {
    key: item,
    text: m('onacta-main.preferences.color-theme.' + item)
  };
}

function scaleToOption(item: string, m: FormatMessage): DropdownOption {
  return {
    key: item,
    text: m('onacta-main.preferences.scale.' + item)
  };
}

function visionToOption(item: string, m: FormatMessage): DropdownOption {
  return {
    key: item,
    text: m('onacta-main.preferences.visiondef-emulation.' + item)
  };
}

export function EinstellungsDialog(props: { dismissHandler: () => void }) {
  const m = useMessageFormatter(messages);
  const [ darkModeDocument, setDarkModeDocument ] = useValue(preferenceStore.darkModeDocument);
  return <Dialog>
    <Heading>{m('onacta-main.gobal-preferences')}</Heading>
    <Divider />
    <Content>
      <SpectrumDropDown label={m('onacta-main.preferences.color-theme')} items={COLOR_THEMES.map(t => t.key)} itemToOption={item => colorThemeToOption(item, m)} value={preferenceStore.theme} placeholder={m('onacta-main.preferences.color-theme.placeholder')} />
      <SpectrumDropDown label={m('onacta-main.preferences.scale')} items={SCALES.map(s => s.key)} itemToOption={item => scaleToOption(item, m)} value={preferenceStore.scale} placeholder={m('onacta-main.preferences.scale.placeholder')} />
      <SpectrumDropDown label={m('onacta-main.preferences.visiondef-emulation')} items={VISION_DEFICIENCIES} itemToOption={item => visionToOption(item, m)} value={preferenceStore.visionDeficiencyEmulation} placeholder={m('onacta-main.preferences.visiondef-emulation.placeholder')} />
      <Switch isSelected={darkModeDocument} onChange={setDarkModeDocument}>{m('onacta-main.preferences.darkModeDocument')}</Switch>
    </Content>
    <ButtonGroup>
      <Button variant="cta" onPress={props.dismissHandler}>{m('onacta-main.close')}</Button>
    </ButtonGroup>
  </Dialog>;
}
import { remove } from '@baeso-ui/baeso-core';
export function isObservable(observable) {
    return observable && observable.onValueChange !== undefined;
}
export class AbstractObservable {
    constructor(name) {
        this.changeListeners = [];
        this.name = name;
    }
    onChange(listener) {
        this.changeListeners.push(listener);
        return () => {
            remove(this.changeListeners, listener);
        };
    }
    fireChange() {
        const listeners = [...this.changeListeners];
        const name = this.name;
        listeners.forEach(l => l(name));
    }
}
export class AbstractObservableValue extends AbstractObservable {
    constructor(name, value) {
        super(name);
        this.valueListeners = [];
        this._setValue = (val) => {
            if (this._value !== val) {
                this.fireValueChange(this._value, (this._value = val), this.name);
            }
        };
        this._value = value;
    }
    onValueChange(listener) {
        this.valueListeners.push(listener);
        return () => {
            remove(this.valueListeners, listener);
        };
    }
    fireValueChange(oldVal, newVal, name) {
        this.fireChange();
        const listeners = [...this.valueListeners];
        listeners.forEach((l) => l(newVal, oldVal, name));
    }
}
export class Binding extends AbstractObservableValue {
    constructor(name, func, ...observables) {
        super(name, func());
        this.observables = [];
        this.handleChange = () => {
            this._setValue(this.func());
        };
        this.func = func;
        this.observables = observables;
        this.observables.forEach(o => o.onChange(this.handleChange));
    }
    get value() {
        return this._value;
    }
}

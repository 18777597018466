export var PDFDestinationType;
(function (PDFDestinationType) {
    PDFDestinationType[PDFDestinationType["PAGE"] = 0] = "PAGE";
    PDFDestinationType[PDFDestinationType["PAGE_FIT"] = 1] = "PAGE_FIT";
    PDFDestinationType[PDFDestinationType["PAGE_FIT_RECTANGLE"] = 2] = "PAGE_FIT_RECTANGLE";
    PDFDestinationType[PDFDestinationType["PAGE_FIT_WIDTH"] = 3] = "PAGE_FIT_WIDTH";
    PDFDestinationType[PDFDestinationType["PAGE_FIT_HEIGHT"] = 4] = "PAGE_FIT_HEIGHT";
    PDFDestinationType[PDFDestinationType["PAGE_XYZ"] = 5] = "PAGE_XYZ";
})(PDFDestinationType || (PDFDestinationType = {}));
export var PDFActionType;
(function (PDFActionType) {
    PDFActionType[PDFActionType["GOTO"] = 0] = "GOTO";
    PDFActionType[PDFActionType["HIDE"] = 1] = "HIDE";
    PDFActionType[PDFActionType["IMPORT_DATA"] = 2] = "IMPORT_DATA";
    PDFActionType[PDFActionType["JAVASCRIPT"] = 3] = "JAVASCRIPT";
    PDFActionType[PDFActionType["LAUNCH"] = 4] = "LAUNCH";
    PDFActionType[PDFActionType["MOVIE"] = 5] = "MOVIE";
    PDFActionType[PDFActionType["NAMED"] = 6] = "NAMED";
    PDFActionType[PDFActionType["REMOTE_GOTO"] = 7] = "REMOTE_GOTO";
    PDFActionType[PDFActionType["RESET_FORM"] = 8] = "RESET_FORM";
    PDFActionType[PDFActionType["SOUND"] = 9] = "SOUND";
    PDFActionType[PDFActionType["SUBMIT_FORM"] = 10] = "SUBMIT_FORM";
    PDFActionType[PDFActionType["THREAD"] = 11] = "THREAD";
    PDFActionType[PDFActionType["URI"] = 12] = "URI";
})(PDFActionType || (PDFActionType = {}));
export var ImageFormat;
(function (ImageFormat) {
    ImageFormat[ImageFormat["PNG"] = 0] = "PNG";
    ImageFormat[ImageFormat["WEBP"] = 1] = "WEBP";
})(ImageFormat || (ImageFormat = {}));

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useRef, useState } from 'react';
import { DateFormatter, getLocalTimeZone, isSameDay, isToday, parseTime, startOfMonth, toCalendarDate, toTime } from '@internationalized/date';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useResizeObserver } from '@react-aria/utils';
import { SpectrumFlex } from '../flex/SpectrumFlex';
import { SpectrumText } from '../text/SpectrumText';
import { Duration, intersects } from '@baeso-ui/baeso-core';
import { computeLaneLayout } from './laneLayoutUtil';
import './day-view.css';
import { View } from '@adobe/react-spectrum';
import { createDateRange } from '../utils/date-util';
const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
const WEEK_DAY = new DateFormatter('de', { weekday: 'short' });
const MONTH_AND_DAY = new DateFormatter('de', { month: 'short', day: '2-digit', });
const DAY = new DateFormatter('de', { day: '2-digit', });
const TIME_FORMAT = new DateFormatter('de', { hour: '2-digit', minute: '2-digit' });
function scaleToMinSize(scale) {
    if (scale === 'hour') {
        return 720;
    }
    else if (scale === 'quarter-hour') {
        return 720 * 4;
    }
    else if (scale === '10-min') {
        return 720 * 6;
    }
    else if (scale === '6-min') {
        return 720 * 10;
    }
    else if (scale === '5-min') {
        return 720 * 12;
    }
    return 720 * 2;
}
export function SpectrumDayView(props) {
    const workhours = useMemo(() => props.workhours ? props.workhours : { min: 8, max: 17 }, [props.workhours]);
    return _jsxs(SpectrumFlex, Object.assign({ backgroundColor: 'gray-50', overflow: 'hidden', direction: 'column', flexGrow: 1, UNSAFE_className: 'calendar-view' }, { children: [_jsxs(SpectrumFlex, Object.assign({ borderBottomColor: 'gray-300', borderBottomWidth: 'thicker' }, { children: [_jsx(View, { minWidth: "size-600" }), _jsx(Header, { date: props.date, base: 100 })] })), _jsx("div", Object.assign({ style: { flexGrow: 1, flexDirection: 'column', display: 'flex', overflowY: 'auto' } }, { children: _jsxs("div", Object.assign({ style: { display: 'flex', flexGrow: 1 } }, { children: [_jsxs("div", Object.assign({ style: { minWidth: 48, display: 'flex', flexDirection: 'column' } }, { children: [_jsx("div", { style: { width: 48, minHeight: 10 } }), _jsx("div", Object.assign({ style: { minHeight: scaleToMinSize(props.scale), position: 'relative', flexGrow: 1 } }, { children: hours.map(h => _jsx(HourSegment, { hour: h, scale: props.scale, showText: true, workhours: workhours }, 'hour-line-' + h)) }))] })), _jsx(Content, { base: 100, date: props.date, scale: props.scale, entries: props.entries, workhours: workhours })] })) }))] }));
}
export function SpectrumMultiDayView(props) {
    const workhours = useMemo(() => props.workhours ? props.workhours : { min: 8, max: 17 }, [props.workhours]);
    const scrollDiv = useRef(null);
    const [scrollbarInsets, setScrollbarInsets] = useState(0);
    useResizeObserver({
        onResize: () => {
            if (scrollDiv.current) {
                if (scrollDiv.current.offsetWidth !== scrollDiv.current.clientWidth) {
                    setScrollbarInsets(scrollDiv.current.offsetWidth - scrollDiv.current.clientWidth);
                }
                else {
                    setScrollbarInsets(0);
                }
            }
        },
        ref: scrollDiv
    });
    const startDate = props.dates[0];
    const endDate = props.dates[props.dates.length - 1];
    const fullDayEntries = props.fullDayEntries;
    const dayLayout = useMemo(() => {
        const weekEntries = fullDayEntries === null || fullDayEntries === void 0 ? void 0 : fullDayEntries.filter(e => {
            const r1 = createDateRange(toCalendarDate(e.start), toCalendarDate(e.end));
            const r2 = createDateRange(startDate, endDate);
            return intersects(r1, r2);
        });
        if (startDate && weekEntries && weekEntries.length > 0) {
            return computeDayLayout(startDate, 7, weekEntries);
        }
        return undefined;
    }, [startDate, endDate, fullDayEntries]);
    return _jsxs(SpectrumFlex, Object.assign({ backgroundColor: 'gray-50', direction: 'column', overflow: 'hidden', flexGrow: 1, UNSAFE_className: 'calendar-view' }, { children: [_jsx(SpectrumFlex, Object.assign({ direction: 'column', borderBottomColor: 'gray-300', alignItems: 'stretch', borderBottomWidth: 'thicker' }, { children: _jsxs(SpectrumFlex, Object.assign({ style: { paddingRight: scrollbarInsets } }, { children: [_jsx(View, { minWidth: "size-600" }), _jsxs(SpectrumFlex, Object.assign({ direction: 'column', flexGrow: 1 }, { children: [_jsx(SpectrumFlex, { children: props.dates.map(d => _jsx(Header, { date: d, base: 100 / props.dates.length }, 'day-' + d)) }), dayLayout && dayLayout.entries.length > 0 &&
                                    _jsxs(_Fragment, { children: [_jsx(FullDayEvents, { dates: props.dates, layout: dayLayout }), _jsx(SpectrumFlex, { children: props.dates.map(d => _jsx(View, { minHeight: 'size-50', flexBasis: 100 / props.dates.length + '%', borderStartColor: 'gray-200', borderStartWidth: 'thin' }, 'day-full-' + d)) })] })] }))] })) })), _jsx("div", Object.assign({ ref: scrollDiv, style: { flexGrow: 1, flexDirection: 'column', display: 'flex', overflowY: 'auto' } }, { children: _jsxs("div", Object.assign({ style: { display: 'flex', alignItems: 'stretch', flexGrow: 1 } }, { children: [_jsxs("div", Object.assign({ style: { display: 'flex', flexDirection: 'column' } }, { children: [_jsx(View, { minWidth: 'size-600', minHeight: 'size-125' }), _jsx("div", Object.assign({ style: { minHeight: scaleToMinSize(props.scale), position: 'relative', flexGrow: 1 } }, { children: hours.map(h => _jsx(HourSegment, { hour: h, scale: props.scale, showText: true, workhours: workhours }, 'hour-line-' + h)) }))] })), props.dates.map(d => _jsx(Content, { date: d, scale: props.scale, entries: props.entries, base: 100 / props.dates.length, workhours: workhours }, 'content-' + d))] })) }))] }));
}
function FullDayEvents(props) {
    const height = 'calc( ' + props.layout.maxLanes + ' * var(--spectrum-global-dimension-size-300, var(--spectrum-alias-size-300)))';
    return _jsxs(SpectrumFlex, Object.assign({ position: 'relative' }, { children: [props.dates.map(d => _jsx(View, Object.assign({ flexBasis: 100 / props.dates.length + '%', borderStartColor: 'gray-200', borderStartWidth: 'thin' }, { children: _jsx("div", { style: { minHeight: height } }) }), 'day-full-' + d)), props.layout.entries.map(e => _jsx(FullDayEntry, { maxLanes: props.layout.maxLanes, startDate: props.dates[0], endDate: props.dates[props.dates.length - 1], layoutEntry: e }, e.data.key))] }));
}
function Header(props) {
    const today = isToday(props.date, getLocalTimeZone());
    const isFirsDayOfMonth = isSameDay(startOfMonth(props.date), props.date);
    const date = props.date.toDate(getLocalTimeZone());
    return _jsx(SpectrumFlex, Object.assign({ flexBasis: props.base + '%' }, { children: _jsx(SpectrumFlex, Object.assign({ flexGrow: 1, minHeight: 'size-600', borderStartColor: 'gray-200', borderStartWidth: 'thin', alignItems: 'end', UNSAFE_className: today ? 'header-today' : undefined }, { children: _jsxs(SpectrumFlex, Object.assign({ alignItems: 'baseline', columnGap: 'size-100', paddingStart: 'size-100', marginBottom: 'size-100' }, { children: [_jsx(SpectrumText, { value: today || isFirsDayOfMonth ? MONTH_AND_DAY.format(date).replace(/\./g, '') : DAY.format(date), fontWeight: 600, color: today ? 'blue-600' : undefined, fontSize: "1.4em" }), _jsx(SpectrumText, { value: WEEK_DAY.format(date), fontWeight: 400, color: 'gray-700' })] })) })) }));
}
function Content(props) {
    const compute = () => {
        if (props.entries === undefined) {
            return [];
        }
        return props.entries.filter(e => {
            return isSameDay(toCalendarDate(e.start), props.date) || isSameDay(toCalendarDate(e.end), props.date);
        });
    };
    const entries = useMemo(compute, [props.date, props.entries]);
    const layout = useMemo(() => computeTimeLayout(props.date, entries), [props.date, entries]);
    const styles = { minHeight: scaleToMinSize(props.scale), position: 'relative', flexGrow: 1 };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    styles['--work-day-start'] = props.workhours.min;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    styles['--work-day-end'] = props.workhours.max;
    return _jsx(SpectrumFlex, Object.assign({ flexBasis: props.base + '%', position: 'relative' }, { children: _jsxs(SpectrumFlex, Object.assign({ backgroundColor: 'gray-100', flexGrow: 1, direction: 'column', borderStartColor: 'gray-400', borderStartWidth: 'thin' }, { children: [_jsx(View, { minHeight: 'size-125' }), _jsxs("div", Object.assign({ style: styles, className: 'hours-grid' }, { children: [hours.map(h => _jsx(HourSegment, { hour: h, scale: props.scale, showText: false, workhours: props.workhours }, 'line-' + h)), layout.entries.map(e => _jsx(DayEntry, { layoutEntry: e, maxLanes: layout.maxLanes }, e.data.key))] }))] })) }));
}
function computeBottoms(hour, segment, scale) {
    if (scale === 'quarter-hour') {
        const subsegment = segment / 4;
        return [
            100 - (segment * hour + subsegment),
            100 - (segment * hour + 2 * subsegment),
            100 - (segment * hour + 3 * subsegment),
        ];
    }
    else if (scale === '10-min') {
        const subsegment = segment / 6;
        return [
            100 - (segment * hour + subsegment),
            100 - (segment * hour + 2 * subsegment),
            100 - (segment * hour + 3 * subsegment),
            100 - (segment * hour + 4 * subsegment),
            100 - (segment * hour + 5 * subsegment),
        ];
    }
    else if (scale === '6-min') {
        const subsegment = segment / 10;
        return [
            100 - (segment * hour + subsegment),
            100 - (segment * hour + 2 * subsegment),
            100 - (segment * hour + 3 * subsegment),
            100 - (segment * hour + 4 * subsegment),
            100 - (segment * hour + 5 * subsegment),
            100 - (segment * hour + 6 * subsegment),
            100 - (segment * hour + 7 * subsegment),
            100 - (segment * hour + 8 * subsegment),
            100 - (segment * hour + 9 * subsegment),
        ];
    }
    const subsegment = segment / 12;
    return [
        100 - (segment * hour + subsegment),
        100 - (segment * hour + 2 * subsegment),
        100 - (segment * hour + 3 * subsegment),
        100 - (segment * hour + 4 * subsegment),
        100 - (segment * hour + 5 * subsegment),
        100 - (segment * hour + 6 * subsegment),
        100 - (segment * hour + 7 * subsegment),
        100 - (segment * hour + 8 * subsegment),
        100 - (segment * hour + 9 * subsegment),
        100 - (segment * hour + 10 * subsegment),
        100 - (segment * hour + 11 * subsegment),
    ];
}
function HourSegment(props) {
    const segment = 100 / 24;
    const top = segment * props.hour;
    const borderTopColor = props.hour < props.workhours.min + 1 || props.hour > props.workhours.max - 1 ? 'gray-400' : 'gray-300';
    const borderBottomColor = props.hour < props.workhours.min || props.hour > props.workhours.max - 1 ? 'gray-400' : 'gray-300';
    const hourText = props.showText ? _jsx(View, Object.assign({ width: 'size-600' }, { children: _jsx("div", Object.assign({ style: { translate: '0px -1.7ex', textAlign: 'center' } }, { children: _jsx(SpectrumText, { value: props.hour < 10 ? '0' + props.hour + ':00' : props.hour + ':00', fontWeight: 400, color: 'gray-700', UNSAFE_style: { backgroundColor: 'var(--spectrum-alias-background-color-gray-50, var(--spectrum-global-color-gray-50, var(--spectrum-semantic-gray-50-color-background)))', paddingLeft: 2, paddingRight: 2 } }) })) })) : undefined;
    if (props.scale === 'hour') {
        const bottom = 100 - (segment * props.hour + segment);
        return _jsx(SpectrumFlex, Object.assign({ borderTopWidth: 'thin', borderTopColor: borderTopColor, style: { position: 'absolute', top: top + '%', bottom: bottom + '%', width: '100%' } }, { children: hourText }));
    }
    else if (props.scale === 'quarter-hour' || props.scale === '10-min' || props.scale === '6-min' || props.scale === '5-min') {
        const bottoms = computeBottoms(props.hour, segment, props.scale);
        return _jsxs(_Fragment, { children: [_jsx(SpectrumFlex, Object.assign({ borderBottomColor: borderBottomColor, borderBottomWidth: 'thin', borderTopWidth: 'thin', borderTopColor: borderTopColor, style: { borderBottomStyle: 'dashed', position: 'absolute', top: top + '%', bottom: bottoms[0] + '%', width: '100%' } }, { children: hourText })), bottoms.filter((_, idx) => idx !== 0).map((v, idx) => {
                    return _jsx(SpectrumFlex, { borderBottomColor: borderBottomColor, borderBottomWidth: 'thin', style: { borderBottomStyle: 'dashed', position: 'absolute', top: top + '%', bottom: v + '%', width: '100%' } }, 'line-' + idx);
                })] });
    }
    const bottom = 100 - (segment * props.hour + segment / 2);
    return _jsx(SpectrumFlex, Object.assign({ borderBottomColor: borderBottomColor, borderBottomWidth: 'thin', borderTopWidth: 'thin', borderTopColor: borderTopColor, style: { borderBottomStyle: 'dashed', position: 'absolute', top: top + '%', bottom: bottom + '%', width: '100%' } }, { children: hourText }));
}
function FullDayEntry(props) {
    const top = (props.layoutEntry.lane.startLane / props.maxLanes) * 100 + '%';
    const bottom = 'calc(' + (100 - ((props.layoutEntry.lane.startLane + 1) / props.maxLanes) * 100) + '% + 2px)';
    const left = 'calc(' + props.layoutEntry.dimension.min + '% + 4px)';
    const right = 'calc(' + (100 - props.layoutEntry.dimension.max) + '% + 3px)';
    const entry = props.layoutEntry.data;
    const className = 'day-event type-' + entry.color;
    const leadText = props.startDate.compare(entry.start) > 0 ? MONTH_AND_DAY.format(entry.start.toDate(getLocalTimeZone())).replace(/\./g, '') : undefined;
    const endText = props.endDate.compare(entry.end) < 0 ? MONTH_AND_DAY.format(entry.end.toDate(getLocalTimeZone())).replace(/\./g, '') : undefined;
    return _jsx("div", Object.assign({ className: className, tabIndex: 0, role: 'button', style: { position: 'absolute', display: 'flex', top, bottom, left, right, whiteSpace: 'nowrap', overflow: 'hidden' } }, { children: _jsxs("div", Object.assign({ style: { position: 'absolute', display: 'flex', left: 10, right: 10, top: 0, bottom: 0, alignItems: 'baseline' } }, { children: [leadText && _jsxs("span", Object.assign({ style: { paddingRight: 5, fontWeight: 300, fontSize: '0.75em' } }, { children: [_jsx(FontAwesomeIcon, { icon: faChevronLeft }), " ", leadText] })), _jsx("span", Object.assign({ style: { fontWeight: 600 } }, { children: props.layoutEntry.data.title })), endText && _jsxs("span", Object.assign({ style: { marginLeft: 'auto', fontWeight: 300, fontSize: '0.75em' } }, { children: [endText, " ", _jsx(FontAwesomeIcon, { icon: faChevronRight })] }))] })) }));
}
function DayEntry(props) {
    const eventDiv = useRef(null);
    const [fontSize, setFontSize] = useState('1.0em');
    const entry = props.layoutEntry.data;
    const className = 'event type-' + entry.color;
    useResizeObserver({
        ref: eventDiv,
        onResize: () => {
            const div = eventDiv.current;
            if (div) {
                if (div.clientHeight < 20) {
                    setFontSize('12px');
                }
                else {
                    setFontSize('1em');
                }
            }
        }
    });
    const top = 'calc(' + props.layoutEntry.dimension.min + '% + 2px)';
    const bottom = (100 - props.layoutEntry.dimension.max) + '%';
    const left = (props.layoutEntry.lane.startLane / props.layoutEntry.lane.maxLane) * 100 + '%';
    const right = 'calc(max(' + (100 - (props.layoutEntry.lane.endLane / props.layoutEntry.lane.maxLane) * 100) + '% + 2px, 10px))';
    return _jsxs("div", Object.assign({ ref: eventDiv, className: className, tabIndex: 0, role: 'button', style: {
            position: 'absolute',
            left,
            right,
            top,
            bottom,
            display: 'flex',
        } }, { children: [_jsx("div", { style: {
                    borderLeftStyle: 'solid',
                    borderLeftWidth: 4,
                    borderLeftColor: 'var(--event-color-fg)',
                    borderTopLeftRadius: 2,
                    borderBottomLeftRadius: 2,
                } }), _jsx("div", Object.assign({ className: 'event-text-positioner', style: { position: 'sticky', top: 0, display: 'flex', alignSelf: 'flex-start', fontSize: fontSize, lineHeight: fontSize, flexGrow: 1 } }, { children: _jsxs("div", Object.assign({ className: 'event-text', style: { paddingLeft: 10, paddingRight: 5, borderRadius: 2, flexGrow: 1, display: 'flex', columnGap: 10, flexWrap: 'wrap', alignItems: 'baseline' } }, { children: [_jsx("span", Object.assign({ style: { fontWeight: 600 } }, { children: props.layoutEntry.data.title })), _jsxs("div", Object.assign({ style: { display: 'inline-block', fontSize: '0.8em' } }, { children: [TIME_FORMAT.format(entry.start.toDate(getLocalTimeZone())), " - ", TIME_FORMAT.format(entry.end.toDate(getLocalTimeZone()))] }))] })) }))] }));
}
function computeDayLayout(startDate, days, entries) {
    return computeLaneLayout(entries.map(event => toDayLayoutEntry(startDate, days, event)));
}
function computeTimeLayout(date, entries) {
    return computeLaneLayout(entries.map(event => toTimeLayoutEntry(date, event)));
}
function toTimeFraction(time) {
    const hour = 100 / 24;
    const minutes = hour / 60;
    return hour * time.hour + minutes * time.minute;
}
function toTimeLayoutEntry(date, e) {
    const from = isSameDay(date, e.start) ? toTime(e.start) : parseTime('00:00');
    const to = isSameDay(date, e.end) ? toTime(e.end) : parseTime('24:00');
    return {
        data: e,
        dimension: { min: toTimeFraction(from), max: toTimeFraction(to) },
        lane: { startLane: 0, endLane: 1, maxLane: 1 }
    };
}
function toDayFraction(totalDays, day) {
    return (100 / totalDays) * day;
}
function toDayLayoutEntry(start, totalDays, e) {
    const from = Math.max(0, Duration.between(start, toCalendarDate(e.start)).toDays());
    const to = Math.min(totalDays, Duration.between(start, toCalendarDate(e.end)).toDays());
    return {
        data: e,
        dimension: { min: toDayFraction(totalDays, from), max: toDayFraction(totalDays, to) },
        lane: { startLane: 0, endLane: 1, maxLane: 1 },
    };
}

import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useStyleProps, useUnwrapDOMRef, viewStyleProps } from '@react-spectrum/utils';
import { Grid } from '@adobe/react-spectrum';
import { useResizeObserver } from '@react-aria/utils';
import { compare } from '@baeso-ui/baeso-core';
import { SpectrumFlex } from '../flex/SpectrumFlex';
const FluidGridContext = createContext(undefined);
const FluidGridCellContext = createContext(undefined);
export function SpectrumFluidGrid(props) {
    const [activeConfig, setActiveConfig] = useState();
    const rowGap = props.rowGap;
    const colGap = props.colGap;
    const div = useRef(null);
    const unwrappedDiv = useUnwrapDOMRef(div);
    const allConfigs = useMemo(() => {
        const items = [];
        for (const key in props.breakPoints) {
            items.push({ key, value: props.breakPoints[key] });
        }
        return items.sort((a, b) => compare(a.value, b.value));
    }, [props.breakPoints]);
    const contextData = useMemo(() => {
        return {
            activeConfig,
            allConfigs,
            rowGap,
            colGap
        };
    }, [activeConfig, allConfigs, rowGap, colGap]);
    const onResize = useCallback(() => {
        var _a;
        if (unwrappedDiv.current === null) {
            return;
        }
        const w = (_a = unwrappedDiv.current) === null || _a === void 0 ? void 0 : _a.clientWidth;
        if (w) {
            // FIXME we could optimize by computing the range for configs and check if the current
            // active config is in the range
            const newCfg = allConfigs.find(b => w < b.value) || allConfigs[allConfigs.length - 1];
            if (activeConfig === undefined || activeConfig.key !== newCfg.key) {
                setActiveConfig(newCfg);
            }
        }
    }, [unwrappedDiv, unwrappedDiv.current, allConfigs, activeConfig]);
    useResizeObserver({
        ref: unwrappedDiv,
        onResize
    });
    return _jsx(FluidGridContext.Provider, Object.assign({ value: contextData }, { children: _jsx(Grid, Object.assign({ ref: div }, props, { children: props.children })) }));
}
export function SpectrumFluidGridRow(props) {
    var _a, _b, _c;
    const config = useContext(FluidGridContext);
    const { styleProps } = useStyleProps({ minWidth: config === null || config === void 0 ? void 0 : config.colGap }, viewStyleProps);
    const children = [];
    if (config && config.activeConfig) {
        if (Array.isArray(props.children)) {
            const c = props.children;
            let colIndex = 1;
            let rowIndex = 1;
            for (let i = 0; i < c.length; i++) {
                const hidden = c[i].props.isHidden !== undefined && typeof c[i].props.isHidden === 'function' ? c[i].props.isHidden(config.activeConfig.key) : c[i].props.isHidden;
                if (hidden) {
                    continue;
                }
                const cfg = getConfig(config.activeConfig.key, c[i].props.cellConfig, config.allConfigs);
                const span = colIndex + cfg - 1 > 12 ? 12 - colIndex : cfg;
                children.push(_jsx(FluidGridCellContext.Provider, Object.assign({ value: { colIndex: colIndex, colSpan: span, rowIndex: rowIndex, activeConfig: config.activeConfig } }, { children: c[i] }), 'row-cell-' + colIndex + '-' + rowIndex));
                colIndex += cfg;
                if (colIndex > 12) {
                    rowIndex += 1;
                    colIndex = 1;
                }
            }
        }
        else {
            const cfg = getConfig(config.activeConfig.key, props.children.props.cellConfig, config.allConfigs);
            children.push(_jsx(FluidGridCellContext.Provider, Object.assign({ value: { colIndex: 1, colSpan: cfg, rowIndex: 1, activeConfig: config.activeConfig } }, { children: props.children }), 'row-cell-1-1'));
        }
    }
    else {
        console.log('No config no fun');
    }
    let columnGap = undefined;
    if ((_a = styleProps.style) === null || _a === void 0 ? void 0 : _a.minWidth) {
        columnGap = 'min(100% / 12, ' + ((_b = styleProps.style) === null || _b === void 0 ? void 0 : _b.minWidth) + ')';
    }
    const hidden = props.isHidden !== undefined && typeof props.isHidden === 'function' ? props.isHidden((_c = config === null || config === void 0 ? void 0 : config.activeConfig) === null || _c === void 0 ? void 0 : _c.key) : props.isHidden;
    return _jsx("div", Object.assign({ style: { gridTemplateColumns: 'repeat(12, 1fr)', columnGap, rowGap: config === null || config === void 0 ? void 0 : config.rowGap, display: hidden ? 'none' : 'grid' } }, { children: children }), "row-div");
}
function getConfig(cur, cellConfigs, sortedBreakPoints) {
    if (cellConfigs[cur]) {
        return cellConfigs[cur];
    }
    const idx = sortedBreakPoints.map(b => b.key).indexOf(cur);
    for (let i = idx; i >= 0; i--) {
        if (cellConfigs[sortedBreakPoints[i].key]) {
            return cellConfigs[sortedBreakPoints[i].key];
        }
    }
    return 2;
}
export function SpectrumFluidGridCell(props) {
    const { alignItems = 'center', overflow } = props;
    const config = useContext(FluidGridCellContext);
    if (config) {
        return _jsx(SpectrumFlex, Object.assign({ alignItems: alignItems, overflow: overflow, gridColumnStart: config.colIndex + '', gridColumnEnd: (config.colIndex + config.colSpan) + '', minWidth: 0 }, { children: props.children }));
    }
    return _jsx("div", {});
}

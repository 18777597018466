import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { baseStyleProps, useStyleProps } from '@react-spectrum/utils';
import { Text } from '@adobe/react-spectrum';
import { isObservable } from '@baeso-ui/baeso-observable';
import { findIcon } from '../utils/util';
// reuse React baseStyleProps, but remove attributes which are supported and forwarded directly
// to avoid adding duplicates in the UNSAFE_style
const textStyleProps = Object.keys(baseStyleProps).reduce((object, key) => {
    if (!['flex', 'flexBasis', 'flexGrow', 'flexShrink'].includes(key)) {
        object[key] = baseStyleProps[key];
    }
    return object;
}, {});
export function SpectrumText(props) {
    const { styleProps } = useStyleProps(props, textStyleProps);
    const [value, setValue] = useState(isObservable(props.value) ? props.value.value : props.value);
    const textColor = { color: props.color ? spectrumColorToCssValue(props.color) : undefined };
    const overflowStyle = { textOverflow: props.overflow, overflow: props.overflow ? 'hidden' : undefined };
    const finalStyles = Object.assign(Object.assign(Object.assign({ whiteSpace: props.whiteSpace, fontWeight: props.fontWeight, fontSize: props.fontSize, textAlign: props.align }, textColor), overflowStyle), styleProps.style);
    useEffect(() => {
        if (isObservable(props.value)) {
            setValue(props.value.value);
            return props.value.onValueChange(setValue);
        }
        else {
            setValue(props.value);
        }
        return undefined;
    }, [props.value]);
    const icon = typeof props.icon === 'string' ? findIcon(props.icon, 'S') : props.icon;
    return _jsxs(Text, Object.assign({ flex: props.flex, flexBasis: props.flexBasis, flexGrow: props.flexGrow, flexShrink: props.flexShrink, slot: props.slot, UNSAFE_style: finalStyles }, { children: [icon && _jsxs(_Fragment, { children: [icon, " "] }), props.formatter ? props.formatter(value) : (value !== undefined ? String(value) : _jsx(_Fragment, {}))] }));
}
// setting a custom text color in react-spectrum is not yet supported
// -> use workaround to be able to use 'official' spectrum colors, based on react-spectrum's internal implementation
function spectrumColorToCssValue(color) {
    // see https://github.com/adobe/react-spectrum/blob/61de930401cac9868ac18a9a475ea1cda9453356/packages/@react-spectrum/utils/src/styleProps.ts#L134-L136
    return `var(--spectrum-alias-text-color-${color}, var(--spectrum-global-color-${color}, var(--spectrum-semantic-${color}-color-default)))`;
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useMemo, useRef, useState } from 'react';
import { useResizeObserver } from '@react-aria/utils';
import { isSpectrumGenericTableProperties, useValue } from '../utils/util';
import { isKeyedValue } from './SpectrumTable.types';
import { SpectrumFlex } from '../flex/SpectrumFlex';
import { SpectrumText } from '../text/SpectrumText';
import { SpectrumView } from '../view/SpectrumView';
export function SpectrumTable(props) {
    const sectionComputer = props.sectionComputer;
    const div = useRef(null);
    const [scrollbarInsets, setScrollbarInsets] = useState(0);
    const [items] = useValue(props.items);
    const [size, setSize] = useState(-1);
    const groups = useMemo(() => {
        if (sectionComputer !== undefined) {
            const m = new Map();
            const g = [];
            items.forEach(e => {
                var _a;
                const sectionKey = sectionComputer(e);
                if (m.has(sectionKey)) {
                    (_a = m.get(sectionKey)) === null || _a === void 0 ? void 0 : _a.rows.push(e);
                }
                else {
                    const rowGroup = {
                        key: sectionKey,
                        rows: [e]
                    };
                    g.push(rowGroup);
                    m.set(sectionKey, rowGroup);
                }
            });
            return g;
        }
        else {
            return undefined;
        }
    }, [items, sectionComputer]);
    useResizeObserver({
        onResize: () => {
            if (div.current) {
                setSize(div.current.clientWidth);
                if (div.current.offsetWidth !== div.current.clientWidth) {
                    setScrollbarInsets(div.current.offsetWidth - div.current.clientWidth);
                }
                else {
                    setScrollbarInsets(0);
                }
            }
        },
        ref: div
    });
    const bgColor = 'var(--spectrum-alias-background-color-gray-50, var(--spectrum-global-color-gray-50, var(--spectrum-semantic-gray-50-color-background)))';
    const columns = typeof props.columns === 'function' ? props.columns(size) : props.columns;
    const rowKeyComputer = isSpectrumGenericTableProperties(props) ? props.rowKeyComputer : undefined;
    return _jsxs(SpectrumFlex, Object.assign({ direction: 'column', position: 'relative', overflow: 'hidden', minHeight: props.minHeight, maxHeight: props.maxHeight }, { children: [_jsx("div", { style: {
                    pointerEvents: 'none', position: 'absolute', left: 0, right: scrollbarInsets, top: 23, bottom: 0,
                    backgroundColor: bgColor,
                    borderWidth: 'var(--spectrum-table-border-size,var(--spectrum-alias-border-size-thin))',
                    borderColor: 'var(--spectrum-table-border-color,var(--spectrum-alias-border-color-mid))',
                    borderRadius: 'var(--spectrum-table-border-radius,var(--spectrum-alias-border-radius-regular))',
                    borderStyle: 'solid'
                } }), _jsxs("table", Object.assign({ ref: div, style: { position: 'relative', borderCollapse: 'collapse', display: 'block', overflow: 'auto' } }, { children: [_jsx("thead", { children: _jsx("tr", { children: columns.map(c => mapColumn(c, props.background)) }) }), _jsxs("tbody", { children: [groups === undefined && items.map((row, index) => mapRow(row, index, columns, rowKeyComputer, props.alternatingRows)), groups !== undefined && groups.map((g, index) => mapRowGroup(g, index, columns, rowKeyComputer, props.alternatingRows))] })] })), _jsx("div", { style: {
                    pointerEvents: 'none', position: 'absolute', left: 0, right: scrollbarInsets, top: 23, bottom: 0,
                    borderWidth: 'var(--spectrum-table-border-size,var(--spectrum-alias-border-size-thin))',
                    borderColor: 'var(--spectrum-table-border-color,var(--spectrum-alias-border-color-mid))',
                    borderRadius: 'var(--spectrum-table-border-radius,var(--spectrum-alias-border-radius-regular))',
                    borderStyle: 'solid',
                    zIndex: 10
                } })] }));
}
function mapRowGroup(group, _index, columns, rowKeyComputer, alternatingRows) {
    const bgcolor = 'var(--spectrum-alias-background-color-gray-100, var(--spectrum-global-color-gray-100, var(--spectrum-semantic-gray-100-color-background)))';
    const styles = {
        position: 'sticky',
        top: 23,
        zIndex: 9,
        height: 34
    };
    return _jsxs(Fragment, { children: [_jsx("tr", Object.assign({ style: styles }, { children: _jsx("td", Object.assign({ colSpan: columns.length, style: {
                        backgroundColor: bgcolor
                    } }, { children: _jsx(SpectrumView, Object.assign({ paddingY: 5, paddingX: 10, borderBottomColor: 'gray-300', borderBottomWidth: 'thin' }, { children: _jsx(SpectrumText, { fontWeight: 600, value: group.key }) })) })) }), group.key), group.rows.map((row, index) => mapRow(row, index, columns, rowKeyComputer, alternatingRows))] }, group.key + '-fragment');
}
function mapColumn(column, backgroundColor) {
    const { minWidth, width } = column;
    const bgColor = backgroundColor ? `var(--spectrum-alias-background-color-${backgroundColor}, var(--spectrum-global-color-${backgroundColor}, var(--spectrum-semantic-${backgroundColor}-color-background)))` : 'var(--spectrum-alias-background-color-gray-75, var(--spectrum-global-color-gray-75, var(--spectrum-semantic-gray-75-color-background)))';
    const style = {
        minWidth,
        width,
        position: 'sticky',
        top: 0,
        textAlign: 'left',
        paddingLeft: 10,
        paddingRight: 10,
        zIndex: 10,
        backgroundColor: bgColor
    };
    return _jsx("th", Object.assign({ style: style }, { children: _jsx(SpectrumText, { color: 'gray-700', fontSize: '11px', value: column.label, UNSAFE_style: { textTransform: 'uppercase', letterSpacing: 0.66 } }) }), column.key);
}
function mapRow(row, index, columns, rowKeyComputer, alternatingRows) {
    const style = {
        borderBottomStyle: 'solid',
        borderBottomColor: 'var(--spectrum-table-border-color,var(--spectrum-alias-border-color-mid))',
        borderBottomWidth: 'var(--spectrum-table-border-size,var(--spectrum-alias-border-size-thin))',
        backgroundColor: alternatingRows ? index % 2 ? 'var(--spectrum-alias-background-color-gray-100, var(--spectrum-global-color-gray-100, var(--spectrum-semantic-gray-100-color-background)))' : 'transparent' : undefined
    };
    return _jsx("tr", Object.assign({ style: style }, { children: columns.map(column => mapCell(row, column, rowKeyComputer)) }), getRowKey(row, rowKeyComputer));
}
function getRowKey(row, rowKeyComputer) {
    if (rowKeyComputer) {
        return rowKeyComputer(row);
    }
    else if (isKeyedValue(row)) {
        return row.key;
    }
    throw new Error('Unable to compute row key');
}
function mapCell(row, column, rowKeyComputer) {
    const key = getRowKey(row, rowKeyComputer) + '-' + column.key;
    const borderStyles = column.showDivider ? {
        borderRightStyle: 'solid',
        borderRightColor: 'var(--spectrum-table-border-color,var(--spectrum-alias-border-color-mid))',
        borderRightWidth: 'var(--spectrum-table-border-size,var(--spectrum-alias-border-size-thin))'
    } : {};
    return _jsx("td", Object.assign({ style: Object.assign({ paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5 }, borderStyles) }, { children: column.cellrender(row) }), key);
}

const german = {
    'SpectrumDropDown.clearSelectionItem.text': 'Keine Auswahl',
    'SpectrumDropDown.clearSelectionItem.description': 'Kein Element ausgewählt',
    'SpectrumGroupPane.expand.ariaLabel': 'Gruppe ausklappen',
    'SpectrumGroupPane.collapse.ariaLabel': 'Gruppe einklappen',
    'SpectrumWizard.next.label': 'Weiter',
    'SpectrumWizard.prev.label': 'Zurück',
    'SpectrumWizard.finish.label': 'Fertig',
    'SpectrumWizard.cancel.label': 'Abbrechen',
    'SpectrumWizard.actionprogress.label': 'Speichern ...',
    'DATE_DAY_AND_MONTH': 'D. MMM',
    'DATE_NORMAL': 'DD.MM.YYYY',
    'DATE_SHORT': 'DD.MM.YY',
    'DATETIME_DAY_AND_MONTH': 'D. MMM, HH:mm',
    'DATE_FULLMONTH_YEAR': 'MMMM YYYY'
};
const en = {
    'SpectrumDropDown.clearSelectionItem.text': 'Empty',
    'SpectrumDropDown.clearSelectionItem.description': 'No element selected',
    'SpectrumGroupPane.expand.ariaLabel': 'expand group',
    'SpectrumGroupPane.collapse.ariaLabel': 'collapse group',
    'SpectrumWizard.next.label': 'Next',
    'SpectrumWizard.prev.label': 'Previous',
    'SpectrumWizard.finish.label': 'Finish',
    'SpectrumWizard.cancel.label': 'Cancel',
    'SpectrumWizard.actionprogress.label': 'Saving ...',
    'DATE_DAY_AND_MONTH': 'MMM, Do',
    'DATE_NORMAL': 'MM/DD/YYYY',
    'DATE_SHORT': 'MM/DD/YY',
    'DATETIME_DAY_AND_MONTH': 'MMM, Do HH:mm',
    'DATE_FULLMONTH_YEAR': 'MMMM YYYY'
};
export const messages = {
    'en-US': en,
    'de-AT': german,
    'de-DE': german,
    'de-CH': german
};

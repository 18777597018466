export var AdressTyp;
(function (AdressTyp) {
    AdressTyp[AdressTyp["PRIVAT"] = 0] = "PRIVAT";
    AdressTyp[AdressTyp["GESCHAEFTLICH"] = 1] = "GESCHAEFTLICH";
})(AdressTyp || (AdressTyp = {}));
export var KommunikationsArt;
(function (KommunikationsArt) {
    KommunikationsArt[KommunikationsArt["EMAIL"] = 0] = "EMAIL";
    KommunikationsArt[KommunikationsArt["MOBILTELEFON"] = 1] = "MOBILTELEFON";
    KommunikationsArt[KommunikationsArt["FESTNETZ"] = 2] = "FESTNETZ";
    KommunikationsArt[KommunikationsArt["SONSTIGE"] = 3] = "SONSTIGE";
})(KommunikationsArt || (KommunikationsArt = {}));
export var Geschlecht;
(function (Geschlecht) {
    Geschlecht[Geschlecht["UNBEKANNT"] = 0] = "UNBEKANNT";
    Geschlecht[Geschlecht["MAENNLICH"] = 1] = "MAENNLICH";
    Geschlecht[Geschlecht["WEIBLICH"] = 2] = "WEIBLICH";
    Geschlecht[Geschlecht["DIVERS"] = 3] = "DIVERS";
    Geschlecht[Geschlecht["SAECHLICH"] = 4] = "SAECHLICH";
})(Geschlecht || (Geschlecht = {}));
export var Kommunikationszusatz;
(function (Kommunikationszusatz) {
    Kommunikationszusatz[Kommunikationszusatz["DIENSTLICH"] = 0] = "DIENSTLICH";
    Kommunikationszusatz[Kommunikationszusatz["PRIVAT"] = 1] = "PRIVAT";
    Kommunikationszusatz[Kommunikationszusatz["SONTIGES"] = 2] = "SONTIGES";
})(Kommunikationszusatz || (Kommunikationszusatz = {}));
export var BeteiligterType;
(function (BeteiligterType) {
    BeteiligterType[BeteiligterType["NATUERLICHEPERSON"] = 0] = "NATUERLICHEPERSON";
    BeteiligterType[BeteiligterType["KANZLEI"] = 1] = "KANZLEI";
    BeteiligterType[BeteiligterType["ORGANISATION"] = 2] = "ORGANISATION";
    BeteiligterType[BeteiligterType["UNDEFINED"] = 3] = "UNDEFINED";
})(BeteiligterType || (BeteiligterType = {}));
export function extractGerichtsPerson(obj) {
    if (obj.richterGruppe) {
        return obj.richterGruppe;
    }
    if (obj.richter) {
        return obj.richter;
    }
    throw new Error('Invalid state');
}
export function extractBeteiligtePerson(obj) {
    if (obj.natuerlichePerson) {
        return obj.natuerlichePerson;
    }
    if (obj.organisation) {
        return obj.organisation;
    }
    if (obj.kanzlei) {
        return obj.kanzlei;
    }
    throw new Error('Invalid state');
}
export function isRichterGruppe(obj) {
    return obj['@type'] === 'RichterGruppe';
}
export function isRichter(obj) {
    return obj['@type'] === 'Richter';
}
export function isNatuerlichePerson(obj) {
    return obj['@type'] === 'NatuerlichePerson';
}
export function isOrganisation(obj) {
    return obj['@type'] === 'Organisation';
}
export function isKanzlei(obj) {
    return obj['@type'] === 'Kanzlei';
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { INTERCEPTED_SERVICE_SUPPORT } from '@beso-rsd/beso-rsd-core';
export class AufgabenViewServiceREST {
    constructor(baseUrl, client) {
        this.baseUrl = baseUrl + '/aufgabenviewservice';
        this.client = client;
    }
    create(aufgabe) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('create', this.client, this.baseUrl, { aufgabe }, {});
            return result;
        });
    }
    update(aufgabe) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.patch('update', this.client, this.baseUrl + '/update', { aufgabe }, {});
            return result;
        });
    }
    _listOwn(erledigt, eiligOnly = false, von = undefined, bis = undefined) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('_listOwn', this.client, this.baseUrl + '/_listOwn', { erledigt, eiligOnly, von, bis }, {});
            return result;
        });
    }
    _listAssigned(erledigt, refType = undefined, refId = undefined, eiligOnly = false, von = undefined, bis = undefined) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('_listAssigned', this.client, this.baseUrl + '/_listAssigned', { erledigt, refType, refId, eiligOnly, von, bis }, {});
            return result;
        });
    }
    _listBySource(refType, refId, erledigt = false, eiligOnly = false, von = undefined, bis = undefined) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('_listBySource', this.client, this.baseUrl + '/_listBySource', { refType, refId, erledigt, eiligOnly, von, bis }, {});
            return result;
        });
    }
    getDetails(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getDetails', this.client, this.baseUrl + '/' + id + '/getDetails', {}, {});
            return result;
        });
    }
    markErledigt(id, etag) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('markErledigt', this.client, this.baseUrl + '/' + id + '/markErledigt', { etag }, {});
            return result;
        });
    }
    lock(id, etag) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('lock', this.client, this.baseUrl + '/' + id + '/lock', { etag }, {});
            return result;
        });
    }
    unlock(id, etag) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('unlock', this.client, this.baseUrl + '/' + id + '/unlock', { etag }, {});
            return result;
        });
    }
}

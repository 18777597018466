import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ActionButton, Divider, Item, Menu, MenuTrigger, ProgressCircle, SearchField, Section, Switch, Text, Tooltip, TooltipTrigger } from '@adobe/react-spectrum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { ELEVATION_LEVELS, findIcon, isActionItem, isToggleItem } from '../utils/util';
import { SpectrumFlex, SpectrumResponsiveItem } from '../flex/SpectrumFlex';
import ShowMenu from '@spectrum-icons/workflow/ShowMenu';
import './main-view.css';
function findSection(sections, actionKey) {
    return sections.filter(s => s.children.filter(s => s.key == actionKey).length > 0)[0];
}
export function SpectrumMainView(props) {
    const [dimensions, setDimensions] = useState({ width: window.innerHeight, height: window.innerHeight });
    useEffect(() => {
        const resizeHandler = () => {
            setDimensions({ width: window.innerHeight, height: window.innerHeight });
        };
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, []);
    const hasItems = (props.toolItems !== undefined && props.toolItems.length > 0) || props.toolBarActions !== undefined;
    if (!hasItems || dimensions.height < 500) {
        return _jsxs(SpectrumFlex, Object.assign({ position: "relative", direction: "column", alignContent: "stretch", flexGrow: 1, boxShadow: ELEVATION_LEVELS.ELEVATION_5, minWidth: 0 }, { children: [_jsx(HeaderSmall, Object.assign({}, props)), _jsx(SpectrumFlex, Object.assign({ backgroundColor: "gray-75", flexGrow: 1, alignContent: "stretch", alignItems: "stretch", overflow: "hidden" }, { children: _jsx(_Fragment, { children: props.children }) }), "content-view"), props.actions !== undefined && _jsx(SpectrumFlex, Object.assign({ backgroundColor: "gray-100", borderTopColor: "gray-400", minHeight: "size-700", borderTopWidth: "thin", paddingStart: "size-125", paddingEnd: "size-125", alignItems: "center", zIndex: 2, columnGap: "size-125", justifyContent: "end" }, { children: _jsx(_Fragment, { children: props.actions }) }), "bottom-bar"), _jsx("div", Object.assign({ className: 'loading-overlay' + (props.loading ? ' loading-inprogress' : ' loading-done'), style: { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: 1000, justifyContent: 'center', alignItems: 'center' } }, { children: props.loading && _jsx(ProgressCircle, { isIndeterminate: true, size: 'L' }) }))] }));
    }
    return _jsxs(SpectrumFlex, Object.assign({ direction: "column", alignContent: "stretch", flexGrow: 1, boxShadow: ELEVATION_LEVELS.ELEVATION_5, minWidth: 0, isResponsive: true }, { children: [_jsx(SpectrumResponsiveItem, Object.assign({ widthRange: [700,] }, { children: _jsx(HeaderLarge, Object.assign({}, props)) })), _jsx(SpectrumResponsiveItem, Object.assign({ widthRange: [400, 699] }, { children: _jsx(HeaderMedium, Object.assign({}, props)) })), _jsx(SpectrumResponsiveItem, Object.assign({ widthRange: [0, 399] }, { children: _jsx(HeaderSmall, Object.assign({}, props)) })), _jsx(SpectrumFlex, Object.assign({ backgroundColor: "gray-75", flexGrow: 1, alignContent: "stretch", alignItems: "stretch", overflow: "hidden" }, { children: _jsx(_Fragment, { children: props.children }) }), "content-view"), props.actions !== undefined && _jsx(SpectrumFlex, Object.assign({ backgroundColor: "gray-100", borderTopColor: "gray-400", minHeight: "size-700", borderTopWidth: "thin", paddingStart: "size-125", paddingEnd: "size-125", alignItems: "center", zIndex: 2, columnGap: "size-125", justifyContent: "end" }, { children: _jsx(_Fragment, { children: props.actions }) }), "bottom-bar")] }));
}
function HeaderSmall(props) {
    var _a, _b, _c;
    const toolSelectedKeys = new Set();
    const disabledKeys = new Set();
    (_a = props.toolItems) === null || _a === void 0 ? void 0 : _a.filter(i => {
        if (isToggleItem(i)) {
            return i.selected;
        }
        return false;
    }).forEach(i => toolSelectedKeys.add(i.key));
    (_b = props.toolItems) === null || _b === void 0 ? void 0 : _b.filter(i => {
        if (isActionItem(i) || isToggleItem(i)) {
            return i.disabled === true;
        }
        return false;
    }).forEach(i => disabledKeys.add(i.key));
    const onAction = (key) => {
        var _a;
        const item = (_a = props.toolItems) === null || _a === void 0 ? void 0 : _a.find(i => i.key == key);
        if (item) {
            if (isToggleItem(item)) {
                item.onChange(!toolSelectedKeys.has(item.key));
            }
            else if (isActionItem(item)) {
                item.onAction();
            }
        }
    };
    const localIconFind = (key, size) => {
        if (props.iconLookup) {
            const rv = props.iconLookup(key, size);
            if (rv) {
                return rv;
            }
        }
        return findIcon(key, size);
    };
    const sectionMap = new Map();
    const sections = [];
    const items = ((_c = props.toolItems) === null || _c === void 0 ? void 0 : _c.filter((i) => {
        return isActionItem(i) || isToggleItem(i);
    })) || [];
    items.forEach(i => {
        let sectionItem = sectionMap.get(i.groupLabel);
        if (sectionItem === undefined) {
            sectionItem = { label: i.groupLabel, items: [] };
            sectionMap.set(i.groupLabel, sectionItem);
            sections.push(sectionItem);
        }
        sectionItem.items.push(i);
    });
    let menuTrigger;
    if (sections.length > 1) {
        menuTrigger = _jsxs(MenuTrigger, { children: [_jsx(ActionButton, Object.assign({ "aria-label": "Komponenten" }, { children: _jsx(ShowMenu, {}) })), _jsx(Menu, Object.assign({ onAction: onAction, selectionMode: "multiple", selectedKeys: toolSelectedKeys, disabledKeys: disabledKeys, items: sections }, { children: (item) => (_jsx(Section, Object.assign({ items: item.items, title: item.label }, { children: (item) => (_jsxs(Item, { children: [localIconFind(item.icon, 'S'), _jsx(Text, { children: item.label })] }, item.key)) }), item.label)) }))] });
    }
    else if (items.length > 0) {
        menuTrigger = _jsxs(MenuTrigger, { children: [_jsx(ActionButton, Object.assign({ "aria-label": "Komponenten" }, { children: _jsx(ShowMenu, {}) })), _jsx(Menu, Object.assign({ onAction: onAction, selectionMode: "multiple", selectedKeys: toolSelectedKeys, disabledKeys: disabledKeys, items: items }, { children: (item) => (_jsxs(Item, { children: [localIconFind(item.icon, 'S'), _jsx(Text, { children: item.label })] }, item.key)) }))] });
    }
    return _jsxs(SpectrumFlex, Object.assign({ alignItems: "center", rowGap: "size-100", columnGap: "size-100", backgroundColor: "gray-100", zIndex: 10, borderBottomColor: "gray-400", borderBottomWidth: "thin", padding: "size-100" }, { children: [menuTrigger, _jsx("div", Object.assign({ style: { fontSize: '1.25em', fontWeight: 'bold' } }, { children: props.title }), "div1_key"), _jsx("div", { style: { flexGrow: 1 } }, "div2_key"), props.showFilter && _jsx(FilterElement, Object.assign({}, props))] }), "header-bar-small");
}
function HeaderMedium(props) {
    const localIconFind = (key, size) => {
        if (props.iconLookup) {
            const rv = props.iconLookup(key, size);
            if (rv) {
                return rv;
            }
        }
        return findIcon(key, size);
    };
    return _jsxs(SpectrumFlex, Object.assign({ direction: "column", rowGap: "size-100", backgroundColor: "gray-100", zIndex: 10, borderBottomColor: "gray-400", borderBottomWidth: "thin", padding: "size-100" }, { children: [_jsx("div", Object.assign({ style: { fontSize: '1.25em', fontWeight: 'bold' } }, { children: props.title }), "div1_key"), _jsx(SpectrumFlex, Object.assign({ gap: "size-40" }, { children: _jsxs(_Fragment, { children: [props.toolItems === undefined && props.toolBarActions, props.toolItems !== undefined && props.toolItems.map(i => itemToControl(localIconFind, i, true)), _jsx("div", { style: { flexGrow: 1 } }, "div2_key"), props.showFilter && _jsx(FilterElement, Object.assign({}, props))] }) }))] }), "header-bar-large");
}
function HeaderLarge(props) {
    const localIconFind = (key, size) => {
        if (props.iconLookup) {
            const rv = props.iconLookup(key, size);
            if (rv) {
                return rv;
            }
        }
        return findIcon(key, size);
    };
    return _jsxs(SpectrumFlex, Object.assign({ direction: "column", rowGap: "size-100", backgroundColor: "gray-100", zIndex: 10, borderBottomColor: "gray-400", borderBottomWidth: "thin", padding: "size-100" }, { children: [_jsx("div", Object.assign({ style: { fontSize: '1.25em', fontWeight: 'bold' } }, { children: props.title }), "div1_key"), _jsx(SpectrumFlex, Object.assign({ gap: "size-40" }, { children: _jsxs(_Fragment, { children: [props.toolItems === undefined && props.toolBarActions, props.toolItems !== undefined && props.toolItems.map(i => itemToControl(localIconFind, i, false)), _jsx("div", { style: { flexGrow: 1 } }, "div2_key"), props.showFilter && _jsx(FilterElement, Object.assign({}, props))] }) }))] }), "header-bar-large");
}
function FilterElement(props) {
    const [selectedKeys, setSelectedKeys] = useState(new Set());
    const onAction = (key) => {
        var _a;
        if (props.filterSearchItems) {
            if (selectedKeys.has(key)) {
                selectedKeys.delete(key);
            }
            else {
                const section = findSection(props.filterSearchItems, key);
                const selection = new Set(selectedKeys);
                if (section) {
                    section.children.forEach(s => selection.delete(s.key + ''));
                }
                selection.add(key);
                setSelectedKeys(selection);
            }
        }
        (_a = props.filterSearchItems) === null || _a === void 0 ? void 0 : _a.flatMap(item => item.children).filter(item => item.key == key).forEach(item => item.onPress());
    };
    return _jsxs(SpectrumFlex, Object.assign({ columnGap: "size-100" }, { children: [_jsx(_Fragment, { children: props.filterSearchItems &&
                    _jsxs(MenuTrigger, { children: [_jsx(ActionButton, { children: _jsx(FontAwesomeIcon, { icon: faFilter }, "icon_key") }, "filter_key"), _jsx(Menu, Object.assign({ onAction: onAction, defaultSelectedKeys: selectedKeys, selectionMode: "multiple", items: props.filterSearchItems }, { children: (item) => {
                                    return _jsx(Section, Object.assign({ items: item.children, title: item.text }, { children: (item) => _jsx(Item, { children: item.text }, item.key) }), 'section_Key' + item.text);
                                } }), "menu_key")] }, "trigger_key") }), _jsx(SearchField, { "aria-label": "Filter" }, "search_key")] }));
}
function itemToControl(iconLookup, item, iconOnly = false) {
    if (isActionItem(item)) {
        if (iconOnly) {
            return _jsxs(TooltipTrigger, { children: [_jsx(ActionButton, Object.assign({ id: item.key, onPress: item.onAction, isQuiet: true, isDisabled: item.disabled, marginEnd: "size-50" }, { children: iconLookup(item.icon, 'S') }), item.key), _jsx(Tooltip, { children: item.label })] });
        }
        return _jsxs(ActionButton, Object.assign({ id: item.key, onPress: item.onAction, isQuiet: true, isDisabled: item.disabled, marginEnd: "size-50" }, { children: [iconLookup(item.icon, 'S'), " ", _jsx(Text, { children: item.label })] }), item.key);
    }
    else if (isToggleItem(item)) {
        if (iconOnly) {
            return _jsxs(TooltipTrigger, { children: [_jsx(Switch, { isSelected: item.selected, onChange: item.onChange, isDisabled: item.disabled, marginEnd: "size-50" }, item.key), _jsx(Tooltip, { children: item.label })] });
        }
        return _jsx(Switch, Object.assign({ isSelected: item.selected, onChange: item.onChange, isDisabled: item.disabled, marginEnd: 0 }, { children: item.label }), item.key);
    }
    else if (item.type === 'separator') {
        return _jsx(Divider, { orientation: "vertical", size: 'M', marginEnd: "size-50" });
    }
    return undefined;
}

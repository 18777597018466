import { INTERCEPTED_SERVICE_SUPPORT } from '@beso-rsd/beso-rsd-core';
import { ConsoleSpanExporter, SimpleSpanProcessor } from '@opentelemetry/tracing';
import { WebTracerProvider } from '@opentelemetry/web';
import { ZipkinExporter } from '@opentelemetry/exporter-zipkin';
export class TracingInterceptor {
    constructor(config) {
        const r = new WebTracerProvider();
        const zip = new ZipkinExporter({
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            },
            serviceName: config.serviceName,
            url: config.url,
        });
        r.addSpanProcessor(new SimpleSpanProcessor(zip));
        if (config.debug) {
            r.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
        }
        this.tracer = r.getTracer('rsd-tracer');
    }
    preinvokation(ctx) {
        const span = this.tracer.startSpan(ctx.methodName);
        ctx.setState('__trace_span', span);
        const traceId = span.context().traceId.padStart(32, '0');
        // const traceIdHigh = traceId.slice(0, 16);
        // const traceIdLow = traceId.slice(16);
        const spanId = span.context().spanId;
        const flags = span.context().traceFlags || 0x1;
        const traceContext = '{ "uber-trace-id":"' + traceId + ':' + spanId + ':0:' + flags + '"}';
        ctx.setProperty('__rsd_trace-span-context', traceContext);
    }
    postinvokation(ctx) {
        // console.log("End", ctx.methodName)
        const span = ctx.getState('__trace_span');
        span.end();
    }
    enable() {
        if (!this.unregister) {
            this.unregister = INTERCEPTED_SERVICE_SUPPORT.registerCallInterceptor(this);
        }
    }
    disable() {
        if (this.unregister) {
            this.unregister();
        }
    }
}

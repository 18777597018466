import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useValue } from '@baeso-ui/baeso-ui-spectrum';
import { preferenceStore } from './utils';
export const VISION_DEFICIENCIES = ['off', 'mono', 'blurred-vision', 'protanopia', 'protanomaly', 'deuteranopia', 'deuteranomaly', 'tritanopia', 'tritanomaly', 'achromatopsia', 'achromatomaly'];
function toVisionFilter(visionDeficiencyEmulation) {
    if (visionDeficiencyEmulation === undefined || visionDeficiencyEmulation == 'off') {
        return undefined;
    }
    else if (visionDeficiencyEmulation === 'blurred-vision') {
        return 'blur(2px)';
    }
    else if (visionDeficiencyEmulation === 'mono') {
        return 'grayscale(100%)';
    }
    else {
        return 'url(#' + visionDeficiencyEmulation + ')';
    }
}
export function VisionDeficiencyEmulation(props) {
    const [visionDeficiencyEmulation] = useValue(preferenceStore.visionDeficiencyEmulation);
    useEffect(() => {
        const visionFilter = toVisionFilter(visionDeficiencyEmulation);
        window.document.body.style.filter = visionFilter !== null && visionFilter !== void 0 ? visionFilter : '';
        return () => { window.document.body.style.filter = ''; };
    }, [visionDeficiencyEmulation]);
    return _jsxs(_Fragment, { children: [props.children, _jsx(ColorBlindnessSimulation, {})] });
}
function ColorBlindnessSimulation() {
    return _jsx("div", Object.assign({ style: { position: 'absolute', zIndex: -1000, width: 1, height: 1 } }, { children: _jsx("svg", { children: _jsxs("defs", { children: [_jsx("filter", Object.assign({ id: "protanopia" }, { children: _jsx("feColorMatrix", { in: "SourceGraphic", type: "matrix", values: "0.567, 0.433, 0, 0, 0 0.558, 0.442, 0, 0, 0 0, 0.242, 0.758, 0, 0 0, 0, 0, 1, 0" }) })), _jsx("filter", Object.assign({ id: "protanomaly" }, { children: _jsx("feColorMatrix", { in: "SourceGraphic", type: "matrix", values: "0.817, 0.183, 0, 0, 0 0.333, 0.667, 0, 0, 0 0, 0.125, 0.875, 0, 0 0, 0, 0, 1, 0" }) })), _jsx("filter", Object.assign({ id: "deuteranopia" }, { children: _jsx("feColorMatrix", { in: "SourceGraphic", type: "matrix", values: "0.625, 0.375, 0, 0, 0 0.7, 0.3, 0, 0, 0 0, 0.3, 0.7, 0, 0 0, 0, 0, 1, 0" }) })), _jsx("filter", Object.assign({ id: "deuteranomaly" }, { children: _jsx("feColorMatrix", { in: "SourceGraphic", type: "matrix", values: "0.8, 0.2, 0, 0, 0 0.258, 0.742, 0, 0, 0 0, 0.142, 0.858, 0, 0 0, 0, 0, 1, 0" }) })), _jsx("filter", Object.assign({ id: "tritanopia" }, { children: _jsx("feColorMatrix", { in: "SourceGraphic", type: "matrix", values: "0.95, 0.05,  0, 0, 0 0,  0.433, 0.567, 0, 0 0,  0.475, 0.525, 0, 0 0,  0, 0, 1, 0" }) })), _jsx("filter", Object.assign({ id: "tritanomaly" }, { children: _jsx("feColorMatrix", { in: "SourceGraphic", type: "matrix", values: "0.967, 0.033, 0, 0, 0 0, 0.733, 0.267, 0, 0 0, 0.183, 0.817, 0, 0 0, 0, 0, 1, 0" }) })), _jsx("filter", Object.assign({ id: "achromatopsia" }, { children: _jsx("feColorMatrix", { in: "SourceGraphic", type: "matrix", values: "0.299, 0.587, 0.114, 0, 0 0.299, 0.587, 0.114, 0, 0 0.299, 0.587, 0.114, 0, 0 0, 0, 0, 1, 0" }) })), _jsx("filter", Object.assign({ id: "achromatomaly" }, { children: _jsx("feColorMatrix", { in: "SourceGraphic", type: "matrix", values: "0.618, 0.320, 0.062, 0, 0 0.163, 0.775, 0.062, 0, 0 0.163, 0.320, 0.516, 0, 0 0, 0, 0, 1, 0" }) }))] }) }) }));
}
